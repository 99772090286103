import { useQuery } from '@apollo/client';
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Divider,
  Grid,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import { Maybe } from 'graphql/jsutils/Maybe';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Trip } from '../../generated/public_graphql';
import { errorsSelector } from '../../store/ErrorSlice';
import { LoaderFullScreen } from '../../theme-components/Loader';
import { Header } from '../AppWrapper';
import CreateTripModal from '../create-trip/manage-trip-modals/CreateTripModal';
import ErrorModal from '../Error';
import { QUERY_GET_USER_SHARED_TRIPS } from '../gql-user/userSharedTripsQuery';
import { QUERY_GET_USER_TRIPS } from '../gql-user/userTripsQuery';
import { HorizontalFlex } from '../helpers/flex';

import ShareTripModal from './ShareTripModal';

interface PropsTripsList {
  readonly trips: Maybe<Trip>[];
}

export function UserTrips() {
  const { loading, error, data } = useQuery(QUERY_GET_USER_TRIPS);
  const {
    loading: loadingShared,
    error: errorShared,
    data: dataShared,
  } = useQuery(QUERY_GET_USER_SHARED_TRIPS);
  const { errorModalOpen } = useSelector(errorsSelector);

  if (loading || loadingShared) {
    return <LoaderFullScreen />;
  }

  return (
    <>
      <Box sx={{ paddingTop: 2 }}>
        {errorModalOpen && <ErrorModal />}
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="flex-end"
          pb={1}
        >
          <Header>Your trips</Header>
          {data.userTripsList.length > 0 && <CreateTripModal />}
        </Box>

        <Divider />

        {error || !data ? (
          <div>Error loading your trips </div>
        ) : (
          <CreatedTrips trips={data.userTripsList} />
        )}

        {errorShared || !dataShared ? (
          <div>Error loading shared trips </div>
        ) : (
          <>
            {dataShared.userSharedTripsList.length > 0 && (
              <>
                <Header>Shared with you</Header>
                <Divider />
                <SharedTrips trips={dataShared.userSharedTripsList} />
              </>
            )}
          </>
        )}
      </Box>
    </>
  );
}

function CreatedTrips(props: PropsTripsList) {
  const navigate = useNavigate();
  return (
    <Box mb={2}>
      {props.trips && props.trips.length > 0 ? (
        <>
          <Grid container>
            {props.trips.map((trip, index) => (
              <React.Fragment key={index}>
                {trip && (
                  <Card
                    key={index + trip.id}
                    sx={theme => ({
                      maxWidth: 345,
                      position: 'relative',
                      borderRadius: 3,
                      marginTop: 3,
                      marginRight: 1,
                      width: '31%',
                      [theme.breakpoints.down(1100)]: {
                        width: '48%',
                      },
                      [theme.breakpoints.down('sm')]: {
                        marginRight: 0,
                        width: '100%',
                      },
                    })}
                  >
                    <Box
                      onClick={() => {
                        navigate('/create-route/' + trip.id);
                        gtag('event', 'user-trip-edit-card');
                      }}
                    >
                      {trip.images && trip.images.length > 0 ? (
                        <CardMedia
                          component="img"
                          height="150"
                          sx={{ borderRadius: '8px 8px 0 0' }}
                          image={trip.images[0]!.path!}
                        />
                      ) : (
                        <CardMedia
                          component="img"
                          height="150"
                          sx={{ borderRadius: '8px 8px 0 0' }}
                          image={'/assets/image/placeholder-svg.svg'}
                        />
                      )}
                      <CardContent
                        sx={{
                          '&:last-child': {
                            paddingBottom: '16px', // Overwrite the padding-bottom for last-child
                          },
                        }}
                      >
                        <TripTitle>{trip.name}</TripTitle>
                        {trip.length && (
                          <TripLength>{trip.length + ' days'}</TripLength>
                        )}

                        {trip.itineraryCountries &&
                          trip.itineraryCountries.length > 0 && (
                            <TripCountries>
                              {trip.itineraryCountries.map((country, index) => (
                                <React.Fragment key={index}>
                                  {country && (
                                    <>
                                      {country.countryName}
                                      {trip.itineraryCountries &&
                                        index !==
                                          trip.itineraryCountries.length -
                                            1 && <>{','}&nbsp;</>}
                                    </>
                                  )}
                                </React.Fragment>
                              ))}
                            </TripCountries>
                          )}
                      </CardContent>
                    </Box>
                    <Divider />
                    <Stack
                      width="100%"
                      direction="row"
                      sx={{
                        paddingTop: 1,
                        paddingLeft: 1,
                        paddingBottom: 1,
                      }}
                      display="flex"
                      alignItems="center"
                      spacing={1}
                    >
                      <Chip
                        icon={
                          <VisibilityTwoToneIcon
                            sx={theme => ({
                              width: 15,
                              height: 15,
                              color: theme.palette.icon.main,
                            })}
                          />
                        }
                        label="View"
                        onClick={() => {
                          navigate('/preview/' + trip.id);
                          gtag('event', 'user-trip-preview-icon');
                        }}
                        variant="outlined"
                        sx={{
                          border: '1px solid #b0b0b0',
                          paddingRight: 1,
                          paddingLeft: 1,
                        }}
                      />
                      <Chip
                        icon={
                          <VisibilityTwoToneIcon
                            sx={theme => ({
                              width: 15,
                              height: 15,
                              color: theme.palette.icon.main,
                            })}
                          />
                        } // Set the icon
                        label="Edit" // Set the text label
                        onClick={() => {
                          navigate('/create-route/' + trip.id);
                          gtag('event', 'user-trip-edit-icon');
                        }}
                        variant="outlined"
                        sx={theme => ({
                          border: '1px solid #b0b0b0',
                          paddingRight: 1,
                          paddingLeft: 1,
                        })}
                      />
                      <ShareTripModal tripId={trip.id} />
                    </Stack>
                  </Card>
                )}
              </React.Fragment>
            ))}
          </Grid>
          <CreateTripModal card={true} />
        </>
      ) : (
        <CreateTripModal card={true} />
      )}
    </Box>
  );
}

function SharedTrips(props: PropsTripsList) {
  const navigate = useNavigate();

  return (
    <Box mb={2}>
      {props.trips && props.trips.length > 0 ? (
        <>
          <Grid container>
            {props.trips.map((trip, index) => (
              <React.Fragment key={index}>
                {trip && (
                  <Card
                    key={index + trip.id}
                    sx={theme => ({
                      maxWidth: 345,
                      position: 'relative',
                      borderRadius: 4,
                      marginTop: 3,
                      marginRight: 1,
                      width: '31%',
                      [theme.breakpoints.down(1100)]: {
                        width: '48%',
                      },
                      [theme.breakpoints.down('sm')]: {
                        marginRight: 0,
                        width: '100%',
                      },
                    })}
                  >
                    <Box
                      onClick={() => {
                        navigate('/preview/' + trip.id);
                        gtag('event', 'preview-shared-trip');
                      }}
                    >
                      {trip.images && trip.images.length > 0 ? (
                        <CardMedia
                          component="img"
                          height="150"
                          sx={{ borderRadius: '8px 8px 0 0' }}
                          image={trip.images[0]!.path!}
                        />
                      ) : (
                        <CardMedia
                          component="img"
                          height="150"
                          sx={{ borderRadius: '8px 8px 0 0' }}
                          image={'/assets/image/placeholder-svg.svg'}
                        />
                      )}
                      <CardContent
                        sx={{
                          '&:last-child': {
                            paddingBottom: '16px', // Overwrite the padding-bottom for last-child
                          },
                        }}
                      >
                        <TripTitle>{trip.name}</TripTitle>
                        {trip.length && (
                          <TripLength>{trip.length + ' days'}</TripLength>
                        )}

                        {trip.itineraryCountries &&
                          trip.itineraryCountries.length > 0 && (
                            <>
                              {/* <Divider className={classes.divider} /> */}
                              <HorizontalFlex>
                                {trip.itineraryCountries.map(
                                  (country, index) => (
                                    <React.Fragment key={index}>
                                      {country && (
                                        <TripLength>
                                          {country.countryName}
                                        </TripLength>
                                      )}
                                    </React.Fragment>
                                  ),
                                )}
                              </HorizontalFlex>
                            </>
                          )}
                      </CardContent>
                    </Box>
                  </Card>
                )}
              </React.Fragment>
            ))}
          </Grid>
        </>
      ) : null}
    </Box>
  );
}

const TripTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: 18,
}));

const TripLength = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: 18,
  color: theme.palette.secondary.main,
}));

const TripCountries = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: 18,
  color: theme.palette.secondary.main,
  whiteSpace: 'nowrap', // Ensures no wrapping of text
}));

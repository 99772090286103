import { useMutation } from '@apollo/client';
import { Box, Typography, Stack, useTheme, Divider } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

import { MUTATION_TRANSPORT_STEP_DETAILS } from '../gql-public/getTransportStepDetailsMutation';
import { formatSecondsToTime } from '../helpers/helpers';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import { LoadingDots } from '../../theme-components/Loader';

export const TransportStepDetails = ({ currentStep, nextStep }) => {
  const theme = useTheme();
  const [isVisible, setIsVisible] = useState(false);
  const componentRef = useRef(null);

  const [getTransportStep, { data, loading: loadingTransport, error }] =
    useMutation(MUTATION_TRANSPORT_STEP_DETAILS);

  // Set up the Intersection Observer to trigger mutation when component is in view
  useEffect(() => {
    if (!currentStep || !nextStep) return;

    const observerOptions = {
      root: null,
      rootMargin: '150px',
      threshold: 0,
    };

    const observerCallback = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      });
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions,
    );

    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    return () => {
      if (componentRef.current) {
        observer.unobserve(componentRef.current);
      }
    };
  }, [currentStep, nextStep]);

  // Trigger the mutation when the component becomes visible
  useEffect(() => {
    if (isVisible && currentStep && nextStep) {
      getTransportStep({
        variables: {
          start_step_id: currentStep.id,
          end_step_id: nextStep.id,
        },
      });
    }
  }, [isVisible, getTransportStep, currentStep, nextStep]);

  if (!currentStep || !nextStep) {
    return null;
  }

  if ((error && !loadingTransport) || (data && data.getDirections === null)) {
    return (
      <Box
        ref={componentRef}
        sx={{
          width: '100%',
          paddingLeft: 3,
        }}
      >
        <Divider
          sx={{
            width: '100%',
            borderColor: theme.palette.divider,
            marginBottom: 2,
          }}
        />
      </Box>
    );
  }

  let drivingTime = 0;
  let walkingTime = 0;
  if (
    data &&
    data.getDirections &&
    data.getDirections[0] &&
    data.getDirections[0].durationMinutes
  ) {
    if (data.getDirections[0].transportType === 'driving') {
      drivingTime = data.getDirections[0].durationMinutes;
    }
    if (data.getDirections[0].transportType === 'walking') {
      walkingTime = data.getDirections[0].durationMinutes;
    }
  }

  if (
    data &&
    data.getDirections &&
    data.getDirections[1] &&
    data.getDirections[1].durationMinutes
  ) {
    if (data.getDirections[1].transportType === 'driving') {
      drivingTime = data.getDirections[1].durationMinutes;
    }
    if (data.getDirections[1].transportType === 'walking') {
      walkingTime = data.getDirections[1].durationMinutes;
    }
  }

  return (
    <Box
      ref={componentRef}
      sx={{
        width: '100%',
        paddingLeft: 3,
      }}
    >
      <Stack direction="row" spacing={0.5} sx={{ mt: 1, mb: 1 }}>
        {walkingTime > 0 || drivingTime > 0 ? (
          <Divider
            sx={{
              width: '100%',
              borderColor: theme.palette.divider,
            }}
            textAlign="center"
          >
            {loadingTransport && <LoadingDots />}
            <>
              {!loadingTransport && (
                <Box
                  sx={{
                    fontWeight: 500,
                    fontSize: 14,
                    color: '#6E7191',
                    padding: theme.spacing(0.5, 1),
                    backgroundColor: theme.palette.background.paper,
                    borderRadius: theme.shape.borderRadius,
                    border: `1px solid ${theme.palette.divider}`,
                    display: 'inline-block', // To ensure the text has a background
                  }}
                >
                  {walkingTime > 0 && walkingTime < 1800 && (
                    <Stack direction="row" alignItems="center" spacing={0.5}>
                      <DirectionsWalkIcon sx={{ width: 22, height: 22 }} />
                      <Typography>
                        {formatSecondsToTime(walkingTime)}
                      </Typography>
                    </Stack>
                  )}
                  {drivingTime > 0 &&
                    (walkingTime > 1800 || walkingTime === 0) && (
                      <Stack direction="row" alignItems="center" spacing={0.5}>
                        <DirectionsCarIcon sx={{ width: 22, height: 22 }} />
                        <Typography>
                          {formatSecondsToTime(drivingTime)}
                        </Typography>
                      </Stack>
                    )}
                </Box>
              )}
            </>
          </Divider>
        ) : (
          <Divider
            sx={{
              width: '100%',
              borderColor: theme.palette.divider,
            }}
          />
        )}
      </Stack>
    </Box>
  );
};

import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import LanguageIcon from '@mui/icons-material/Language';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import { Button, colors } from '@mui/material';
import Box from '@mui/material/Box';
import { createStyles, makeStyles } from '@mui/styles';
import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import { Country, Trip } from '../../generated/user_graphql';
import Passport from '../icons/Passport';

import { TripSidebar } from './TripSidebar';

const useStyles = makeStyles(theme =>
  createStyles({
    linkButton: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    linkButtonText: {
      textTransform: 'none',
      marginLeft: 5,
      fontSize: 18,
      fontWeight: 600,
      [theme.breakpoints.down('sm')]: {
        fontSize: 16,
      },
    },
    largeIcon: {
      width: 35,
      height: 35,
      color: '#4D4B66',
      [theme.breakpoints.down('sm')]: {
        width: 30,
        height: 30,
      },
    },
    moreInfoBox: {
      padding: 10,
      // minWidth: 500,
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        minWidth: 230,
      },
    },
    moreInfoBox2: {
      marginRight: '20%',
      [theme.breakpoints.down(1100)]: {
        marginRight: '10%',
      },
    },
  }),
);

export function TripCountryBox(props: { trip?: Trip; country?: Country }) {
  const navigate = useNavigate();
  const classes = useStyles();

  function onShowSeasons() {
    gtag('event', 'public-country-seasons');
    navigate('?modal=seasons');
  }

  function onShowTips() {
    gtag('event', 'public-country-tips');
    navigate('?modal=tips');
  }

  function onShowVaccinations() {
    gtag('event', 'public-country-health');
    navigate('?modal=vaccinations');
  }

  function onShowCountriesDetails() {
    gtag('event', 'public-country-details');
    navigate('?modal=countries_details');
  }
  function onShowVisas() {
    gtag('event', 'public-country-visas');
    navigate('?modal=visas');
  }

  let showAboutCountry = false;
  let showCountryVaccination = false;
  let showCountryVisa = false;
  let showCountryTips = false;
  let showCountrySeasons = false;
  let country: Country | null = null;

  if (props.trip && props.trip.countries && props.trip.countries[0]) {
    country = props.trip.countries[0];

    if (country.name && country.description) {
      showAboutCountry = true;
    }

    if (country.seasons && country.seasons.length > 0) {
      showCountrySeasons = true;
    }

    if (country.recommendations && country.recommendations.length > 0) {
      country.recommendations.forEach(recommendation => {
        if (
          recommendation!.type === 'RECOMMENDATION_VACCINATION' ||
          recommendation!.type === 'RECOMMENDATION_HEALTH'
        ) {
          showCountryVaccination = true;
        }

        if (recommendation!.type === 'RECOMMENDATION_VISAS') {
          showCountryVisa = true;
        }

        if (
          recommendation!.type !== 'RECOMMENDATION_VISAS' &&
          recommendation!.type !== 'RECOMMENDATION_VACCINATION' &&
          recommendation!.type !== 'RECOMMENDATION_HEALTH'
        ) {
          showCountryTips = true;
        }
      });
    }
  }

  if (props.country) {
    country = props.country;

    if (country.name && country.description) {
      showAboutCountry = true;
    }

    if (country.seasons && country.seasons.length > 0) {
      showCountrySeasons = true;
    }

    if (country.recommendations && country.recommendations.length > 0) {
      country.recommendations.forEach(recommendation => {
        if (
          recommendation!.type === 'RECOMMENDATION_VACCINATION' ||
          recommendation!.type === 'RECOMMENDATION_HEALTH'
        ) {
          showCountryVaccination = true;
        }

        if (recommendation!.type === 'RECOMMENDATION_VISAS') {
          showCountryVisa = true;
        }

        if (
          recommendation!.type !== 'RECOMMENDATION_VISAS' &&
          recommendation!.type !== 'RECOMMENDATION_VACCINATION' &&
          recommendation!.type !== 'RECOMMENDATION_HEALTH'
        ) {
          showCountryTips = true;
        }
      });
    }
  }

  return (
    <>
      <Box bgcolor="#ffd993" style={{ borderRadius: 10 }}>
        <Box
          border={1}
          borderColor={colors.grey[300]}
          bgcolor="#FFFFFF"
          ml={1}
          pl={2}
          style={{
            borderLeft: 0,
            borderBottomLeftRadius: 0,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 10,
            borderBottomRightRadius: 10,
          }}
        >
          <Box className={classes.moreInfoBox}>
            <Box>
              {showAboutCountry && (
                <LinkButton onClick={onShowCountriesDetails}>
                  <LanguageIcon className={classes.largeIcon} />
                  <LinkButtonText>{'About ' + country!.name}</LinkButtonText>
                </LinkButton>
              )}
              {showCountryTips && (
                <LinkButton onClick={onShowTips}>
                  <AssignmentTurnedInIcon className={classes.largeIcon} />
                  <LinkButtonText>Tips before you go</LinkButtonText>
                </LinkButton>
              )}
              {showCountryVisa && (
                <LinkButton onClick={onShowVisas}>
                  <Passport fill="#4D4B66" className={classes.largeIcon} />
                  <LinkButtonText>Visas</LinkButtonText>
                </LinkButton>
              )}
            </Box>
            <Box className={classes.moreInfoBox2}>
              {showCountryVaccination && (
                <LinkButton onClick={onShowVaccinations}>
                  <LocalHospitalIcon className={classes.largeIcon} />
                  <LinkButtonText>Health</LinkButtonText>
                </LinkButton>
              )}
              {showCountrySeasons && (
                <LinkButton onClick={onShowSeasons}>
                  <WbSunnyIcon className={classes.largeIcon} />
                  <LinkButtonText>When to go</LinkButtonText>
                </LinkButton>
              )}
            </Box>
          </Box>
        </Box>
      </Box>

      {country && <TripSidebar countries={[country]} />}
    </>
  );
}

function LinkButton(props: {
  readonly onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  readonly children?: ReactNode;
}) {
  const classes = useStyles();
  return (
    <Box className={classes.linkButton} onClick={props.onClick}>
      {props.children}
    </Box>
  );
}

function LinkButtonText(props: { children: ReactNode }) {
  const classes = useStyles();
  return <Button className={classes.linkButtonText}>{props.children}</Button>;
}

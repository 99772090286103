import gql from 'graphql-tag';

export const MUTATION_SHARE_TRIP = gql`
  mutation shareUserTrip(
    $trip_id: String!
    $shareWithEmail: String!
    $canEdit: Boolean!
    $canView: Boolean!
  ) {
    shareUserTrip(
      trip_id: $trip_id
      shareWithEmail: $shareWithEmail
      canEdit: $canEdit
      canView: $canView
    ) {
      id
      sharedWithEmail
      canEdit
      canView
    }
  }
`;

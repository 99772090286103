import { useLazyQuery, useMutation } from '@apollo/client';
import Backdrop from '@material-ui/core/Backdrop';
import ShareTwoToneIcon from '@mui/icons-material/ShareTwoTone';
import {
  Box,
  Chip,
  Divider,
  FormControlLabel,
  Paper,
  Switch,
  Tab,
  Tabs,
  TextField,
} from '@mui/material';
import Fade from '@mui/material/Fade';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { setErrorModalOpen, setErrorTitle } from '../../store/ErrorSlice';
import {
  LinkButton,
  PrimaryButton,
  SecondaryButton,
} from '../../theme-components/Buttons';
import { Loader } from '../../theme-components/Loader';
import { validateEmail } from '../../utils/helpers';
import { StyledFormControl } from '../create-trip/manage-trip-modals/Styles';
import { MUTATION_UPDATE_SHARED_TRIP_INFO } from '../gql-user/editSharedTripInfoMutation';
import { MUTATION_SHARE_TRIP } from '../gql-user/shareTrip';
import { QUERY_GET_TRIP_SHARED_INFO } from '../gql-user/tripSharedInfoQuery';
import { VerticalFlex } from '../helpers/flex';
import {
  CenteredModal,
  LargeModalPaper,
  ModalDescription,
  ModalTitle,
} from '../styling/modal';

export default function ShareTripModal(props: { tripId: string }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [updateEmail, setUpdateEmail] = useState('');
  const [updateCanView, setUpdateCanView] = useState(false);
  const [canView, setCanView] = useState(false);
  const [editId, setEditId] = useState('');

  const [shareTrip, { loading }] = useMutation(MUTATION_SHARE_TRIP, {
    variables: {
      trip_id: props.tripId,
      shareWithEmail: email,
      canEdit: false,
      canView: canView,
    },
  });

  const [updateSharedTripInfo, { loading: updateLoading }] = useMutation(
    MUTATION_UPDATE_SHARED_TRIP_INFO,
    {
      variables: {
        shared_id: editId,
        trip_id: props.tripId,
        shareWithEmail: updateEmail,
        canEdit: false,
        canView: updateCanView,
      },
    },
  );

  const [getSharedInfo, { data: sharedInfoData }] = useLazyQuery(
    QUERY_GET_TRIP_SHARED_INFO,
    {
      fetchPolicy: 'no-cache',
      variables: {
        trip_id: props.tripId,
      },
    },
  );

  const handleOpen = async () => {
    setOpen(true);
    getSharedInfo();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [value, setValue] = useState(0);

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    const disableScroll = e => e.preventDefault();
    if (open) {
      // Lock body scroll
      document.body.style.overflow = 'hidden';
      document.addEventListener('touchmove', disableScroll, {
        passive: false,
      });
    } else {
      // Unlock body scroll
      document.body.style.overflow = '';
      document.removeEventListener('touchmove', disableScroll);
    }

    // Cleanup function to restore scroll when component unmounts or modal closes
    return () => {
      document.body.style.overflow = '';
      document.removeEventListener('touchmove', disableScroll);
    };
  }, [open]);

  return (
    <>
      <Chip
        icon={
          <ShareTwoToneIcon
            sx={theme => ({
              width: 15,
              height: 15,
              color: theme.palette.icon.main,
            })}
          />
        } // Set the icon
        label="Share" // Set the text label
        onClick={() => {
          handleOpen();
          gtag('event', 'user-trip-share-chip');
        }} // Handle click event
        variant="outlined" // Use outlined variant to have a border
        sx={{ border: '1px solid #b0b0b0', paddingRight: 1, paddingLeft: 1 }} // Set border color
      />
      <CenteredModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <LargeModalPaper>
            <ModalTitle>Share with your friends</ModalTitle>
            <Box mb={2}>
              <Paper>
                <Tabs
                  value={value}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={handleChange}
                >
                  <Tab
                    sx={{ width: '50%', fontWeight: 600, fontSize: 18 }}
                    label="Add friend"
                  />
                  <Tab
                    sx={{ width: '50%', fontWeight: 600, fontSize: 18 }}
                    label="Manage access"
                  />
                </Tabs>
              </Paper>
            </Box>
            {value === 0 && (
              <>
                <ModalDescription>
                  Share this trip with your your fellow travelers. Just add
                  their email and once they login they will be able to see this
                  trip details.
                </ModalDescription>
                <StyledFormControl>
                  <Box mb={1}>
                    <TextField
                      label="Friend email"
                      name="title"
                      fullWidth={true}
                      value={email}
                      variant="outlined"
                      onChange={e => {
                        setEmail(e.target.value);
                      }}
                    />
                  </Box>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={canView}
                        onChange={() => setCanView(!canView)}
                        color="primary"
                        name="checkedB"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    }
                    label="Allow to view"
                  />
                </StyledFormControl>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                  mt={2}
                >
                  <VerticalFlex>
                    <PrimaryButton
                      disabled={!validateEmail(email) || loading}
                      onClick={async () => {
                        gtag('event', 'share-trip-share-with-friend');
                        try {
                          await shareTrip();
                          await getSharedInfo();
                          setValue(1);
                        } catch (e) {
                          dispatch(setErrorTitle('Error sharing trip'));
                          dispatch(setErrorModalOpen(true));
                        }
                      }}
                    >
                      {loading ? <Loader /> : 'Share trip'}
                    </PrimaryButton>
                  </VerticalFlex>
                </Box>
              </>
            )}
            {value === 1 && (
              <>
                {sharedInfoData &&
                sharedInfoData.userTripSharedWithUsers &&
                sharedInfoData.userTripSharedWithUsers.length > 0 ? (
                  <>
                    <ModalDescription>
                      When people with view access login, they will be able to
                      see this trip details.
                    </ModalDescription>
                    <Divider />
                    {sharedInfoData.userTripSharedWithUsers.map(
                      (user, index) => (
                        <>
                          {user && !editId && (
                            <Fragment key={index}>
                              <Box
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                                alignItems="flex-end"
                              >
                                <Box display="flex" flexDirection="column">
                                  <Box
                                    sx={theme => ({
                                      color: theme.palette.secondary.dark,
                                      fontSize: 18,
                                      fontWeight: 500,
                                      marginBottom: theme.spacing(0.5),
                                      marginTop: theme.spacing(0.5),
                                    })}
                                  >
                                    {user.sharedWithEmail}
                                  </Box>
                                  {user.canView ? (
                                    <Box
                                      sx={theme => ({
                                        color: theme.palette.secondary.light,
                                        fontSize: 16,
                                        fontWeight: 400,
                                        marginBottom: theme.spacing(1),
                                      })}
                                    >
                                      Access to view
                                    </Box>
                                  ) : (
                                    <Box
                                      sx={theme => ({
                                        color: theme.palette.secondary.light,
                                        fontSize: 16,
                                        fontWeight: 400,
                                        marginBottom: theme.spacing(1),
                                      })}
                                    >
                                      No access
                                    </Box>
                                  )}
                                </Box>

                                <LinkButton
                                  onClick={() => {
                                    setEditId(user.id);
                                    setUpdateEmail(user.sharedWithEmail);
                                    setUpdateCanView(user.canView);
                                    gtag(
                                      'event',
                                      'share-trip-edit-permissions',
                                    );
                                  }}
                                >
                                  Edit
                                </LinkButton>
                              </Box>
                              <Divider />
                            </Fragment>
                          )}
                          {user.id === editId && (
                            <>
                              <StyledFormControl>
                                <Box mb={1}>
                                  <TextField
                                    label="Friend email"
                                    name="title"
                                    fullWidth={true}
                                    value={updateEmail}
                                    variant="outlined"
                                    onChange={e => {
                                      setUpdateEmail(e.target.value);
                                    }}
                                  />
                                </Box>
                                {/* <FormControlLabel
                                  control={
                                    <Switch
                                      checked={user.canEdit}
                                      onChange={() => setCanEdit(!canEdit)}
                                      color="primary"
                                      name="checkedB"
                                      inputProps={{
                                        'aria-label': 'primary checkbox',
                                      }}
                                    />
                                  }
                                  label="Allow to edit"
                                /> */}
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={updateCanView}
                                      onChange={() =>
                                        setUpdateCanView(!updateCanView)
                                      }
                                      color="primary"
                                      name="checkedB"
                                      inputProps={{
                                        'aria-label': 'primary checkbox',
                                      }}
                                    />
                                  }
                                  label="Allow to view"
                                />
                              </StyledFormControl>
                              <Box
                                sx={{
                                  width: '100%',
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                }}
                                mt={2}
                              >
                                <VerticalFlex>
                                  <Box display="flex">
                                    <Box mr={2}>
                                      <SecondaryButton
                                        onClick={async () => {
                                          setEditId('');
                                          gtag(
                                            'event',
                                            'share-trip-edit-permissions-go-back',
                                          );
                                        }}
                                      >
                                        Go back
                                      </SecondaryButton>
                                    </Box>

                                    <PrimaryButton
                                      disabled={
                                        !validateEmail(updateEmail) ||
                                        updateLoading
                                      }
                                      onClick={async () => {
                                        gtag(
                                          'event',
                                          'share-trip-edit-permissions-save',
                                        );
                                        try {
                                          await updateSharedTripInfo();
                                          setEditId('');
                                        } catch (e) {
                                          dispatch(
                                            setErrorTitle(
                                              'Error saving changes',
                                            ),
                                          );
                                          dispatch(setErrorModalOpen(true));
                                        }
                                      }}
                                    >
                                      {updateLoading ? <Loader /> : 'Save'}
                                    </PrimaryButton>
                                  </Box>
                                </VerticalFlex>
                              </Box>
                            </>
                          )}
                        </>
                      ),
                    )}
                  </>
                ) : (
                  <ModalDescription>
                    This trip is not shared with anyone yet. <br /> <br />
                    <br /> <br /> <br />
                    <br /> <br /> <br />
                    <br />
                  </ModalDescription>
                )}
              </>
            )}
          </LargeModalPaper>
        </Fade>
      </CenteredModal>
    </>
  );
}

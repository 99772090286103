import * as React from 'react';

function VaccineIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      height="1em"
      viewBox="0 0 64 64"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect fill="#87cee9" height={36} rx={4} width={24} x={33} y={25} />
      <path d="M33 29h24v18H33z" fill="#f4f4e6" />
      <path d="M11 47v-2H5v2l-2 2v12h10V49z" fill="#87cee9" />
      <path d="M37 21h16v4H37z" fill="#50b3c9" />
      <path d="M35 15h20v6H35z" fill="#f9bb4b" />
      <path
        d="M38 15h2v3h-2zM42 15h2v3h-2zM46 15h2v3h-2zM50 15h2v3h-2z"
        fill="#ffd782"
      />
      <path d="M50 36h-3v-3h-4v3h-3v4h3v3h4v-3h3z" fill="#dd3e46" />
      <path d="M27 46h-8V20h8z" fill="#87cee9" />
      <path d="M22 2h2v11h-2z" fill="#2d75bb" />
      <path d="M25 16h-4v-2a2 2 0 012-2 2 2 0 012 2v2z" fill="#87cee9" />
      <path d="M21 16h4l2 4h-8zM29 50H17v-4h12z" fill="#50b3c9" />
      <rect
        fill="#50b3c9"
        height={4}
        rx={2}
        transform="rotate(180 23 59)"
        width={10}
        x={18}
        y={57}
      />
      <path d="M25 57h-4v-7h4z" fill="#87cee9" />
      <g fill="#195c85">
        <path d="M19 32h3v2h-3zM19 28h3v2h-3zM19 40h3v2h-3zM19 36h3v2h-3zM19 24h3v2h-3zM3 41h10v4H3z" />
      </g>
      <path d="M3 51h10v6H3z" fill="#f9bb4b" />
      <path d="M61 51v10h-4V51z" fill="#195c85" />
      <path d="M54 59h3v-6h-3l-2-2H40v10h12z" fill="#cdeef6" />
      <path d="M50 51v10h-6V51z" fill="#f9bb4b" />
      <path d="M36 56h2v2h-2zM36 52h2v2h-2z" fill="#f4f4e6" />
    </svg>
  );
}

export default VaccineIcon;

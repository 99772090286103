import * as React from 'react';

function VietnamFlagIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 511.902 511.902"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        d="M503.6 373.851H35.4c-4.7 0-8.4-3.8-8.4-8.4v-300.9c0-4.7 3.8-8.4 8.4-8.4h468.1c4.7 0 8.4 3.8 8.4 8.4v300.9c.1 4.6-3.7 8.4-8.3 8.4z"
        fill="#ff4b55"
      />
      <path
        d="M273.6 120.651l22.3 67 70.6.5c3.9 0 5.6 5.1 2.4 7.4l-56.8 42 21.3 67.3c1.2 3.8-3.1 6.9-6.3 4.6l-57.5-41.1-57.5 41.1c-3.2 2.3-7.5-.8-6.3-4.6l21.3-67.3-56.8-42c-3.2-2.3-1.5-7.4 2.4-7.4l70.6-.5 22.3-67c1.5-3.7 6.8-3.7 8 0z"
        fill="#ffe15a"
      />
      <path
        d="M19.1 488.051c-10.5 0-19.1-8.6-19.1-19.1v-426.1c0-10.5 8.5-19 19-19h.2c10.6 0 19.1 8.6 19.1 19.1v426c-.1 10.5-8.6 19.1-19.2 19.1z"
        fill="#2e3033"
      />
    </svg>
  );
}

export default VietnamFlagIcon;

import DOMPurify from 'dompurify';

export function RenderHtmlFromResponse(props: { content: string }) {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(props.content),
      }}
    />
  );
}

export function RenderHtmlFromResponseNoMargin(props: { content: string }) {
  // Sanitize the content and wrap it with a div if it doesn't already start with one.
  const sanitizedContent = DOMPurify.sanitize(props.content, {
    ADD_TAGS: ['style'],
  });

  return (
    <div
      style={{
        marginBlockStart: '-0.5em',
        marginBlockEnd: '-0.5em',
      }}
      dangerouslySetInnerHTML={{
        __html: sanitizedContent,
      }}
    />
  );
}

// export function RenderHtmlFromResponseNoMargin(props: { content: string }) {
//   return (
//     <div
//       style={{
//         marginBlockStart: '-0.5em',
//         marginBlockEnd: '-0.5em',
//       }}
//       dangerouslySetInnerHTML={{
//         __html: DOMPurify.sanitize(props.content),
//       }}
//     />
//   );
// }

import { useMutation } from '@apollo/client';
import { Box, TextField } from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import { useSelector, useDispatch } from 'react-redux';

import {
  accommodationsSelector,
  setAccommodationDescription,
  setAccommodationDescriptionAdded,
  setAccommodationLatitude,
  setAccommodationLongitude,
  setAccommodationUrl,
} from '../../../../store/AccommodationSlice';
import { setDays } from '../../../../store/DaySlice';
import { setErrorModalOpen, setErrorTitle } from '../../../../store/ErrorSlice';
import { setAddStepModalOpen } from '../../../../store/StepSlice';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../../theme-components/Buttons';
import { Loader } from '../../../../theme-components/Loader';
import { MUTATION_UPDATE_ACCOMMODATION } from '../../../gql-user/updateAccommodation';
import { VerticalFlex } from '../../../helpers/flex';
import { ModalDescription } from '../../../styling/modal';
import { StyledFormControl } from '../Styles';

export default function AddAccommodationInfo() {
  const dispatch = useDispatch();

  const {
    accommodationId,
    accommodationDescription,
    accommodationUrl,
    accommodationLatitude,
    accommodationLongitude,
  } = useSelector(accommodationsSelector);

  const [updateAccommodation, { loading }] = useMutation(
    MUTATION_UPDATE_ACCOMMODATION,
    {
      variables: {
        accommodation_id: accommodationId,
        description: accommodationDescription,
        url: accommodationUrl,
        latitude: accommodationLatitude,
        longitude: accommodationLongitude,
      },
    },
  );

  const handleEditorChange = (content: string) => {
    dispatch(setAccommodationDescription(content));
  };

  return (
    <>
      <VerticalFlex>
        <StyledFormControl>
          {/* <TextField
            id="standard-basic"
            label="Description"
            name="description"
            multiline={true}
            rows={6}
            fullWidth={true}
            variant="outlined"
            onChange={e => {
              dispatch(setAccommodationDescription(e.target.value));
            }}
          /> */}
          <ModalDescription>Description</ModalDescription>
          <Box height="200px">
            <Editor
              apiKey={process.env.REACT_APP_TINY_EDITOR_KEY}
              value={accommodationDescription}
              init={{
                height: 200,
                menubar: false,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount',
                ],
                toolbar:
                  'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
              }}
              onEditorChange={handleEditorChange}
            />
          </Box>
        </StyledFormControl>
        <StyledFormControl>
          <TextField
            id="standard-basic"
            label="Booking URL"
            name="step-location"
            multiline={true}
            fullWidth={true}
            variant="outlined"
            onChange={e => {
              dispatch(setAccommodationUrl(e.target.value));
            }}
          />
        </StyledFormControl>
        <StyledFormControl>
          <TextField
            id="standard-basic"
            label="Place location (paste coordinates)"
            name="step-location"
            multiline={true}
            fullWidth={true}
            variant="outlined"
            onChange={e => {
              const res = e.target.value.split(',', 2);
              const latitude = parseFloat(res[0]);
              const longitude = parseFloat(res[1]);
              if (latitude <= 90 && latitude >= -90) {
                dispatch(setAccommodationLatitude(latitude));
              } else {
                dispatch(setAccommodationLatitude(0));
              }
              if (longitude <= 180 && latitude >= -180) {
                dispatch(setAccommodationLongitude(longitude));
              } else {
                dispatch(setAccommodationLongitude(0));
              }
            }}
          />
        </StyledFormControl>
        <Box
          sx={{
            marginTop: 2,
            display: 'flex',
            justifyContent: 'flex-end', // Aligns items to the right
          }}
        >
          <VerticalFlex>
            <Box display="flex">
              <Box mr={2}>
                <SecondaryButton
                  onClick={() => {
                    gtag('event', 'add-accommodation-details-add-later');
                    dispatch(setAddStepModalOpen(false));
                  }}
                >
                  Add more info later
                </SecondaryButton>
              </Box>

              <PrimaryButton
                disabled={loading}
                onClick={async () => {
                  gtag('event', 'add-accommodation-details-next');
                  try {
                    const res = await updateAccommodation();
                    if (
                      res &&
                      res.data &&
                      res.data.updateUserAccommodation &&
                      res.data.updateUserAccommodation.days
                    ) {
                      dispatch(setDays(res.data.updateUserAccommodation.days));
                      dispatch(setAccommodationDescriptionAdded(true));
                    }
                  } catch (e) {
                    gtag('event', 'error-add-accommodation-details');
                    dispatch(setErrorTitle('Error adding details'));
                    dispatch(setErrorModalOpen(true));
                  }
                }}
              >
                {loading ? <Loader /> : 'Add to itinerary'}
              </PrimaryButton>
            </Box>
          </VerticalFlex>
        </Box>
      </VerticalFlex>
    </>
  );
}

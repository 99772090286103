import React, { useEffect, useState } from 'react';

import { CountryTrips } from '../components/country/country-trips';
import { CountryHeader } from '../components/country/header';
import { RouteContent, RoutePage } from '../components/route';

export default function CountryRoute(props: {
  countryId: string;
  countryName: string;
  countryImagePath: string;
}) {
  const [count, setCount] = useState(0);

  gtag('event', 'public-country-page');

  useEffect(() => {
    if (count === 0) {
      window.scrollTo(0, 0);
      setCount(1);
    }
  }, [count]);

  return (
    <RoutePage>
      <CountryHeader
        countryId={props.countryId}
        countryName={props.countryName}
        countryImagePath={props.countryImagePath}
      />
      <CountryTrips
        countryId={props.countryId}
        countryName={props.countryName}
      />
    </RoutePage>
  );
}

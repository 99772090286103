import { Box } from '@mui/material';
import { lazy, Suspense } from 'react';
import { CookieConsent } from 'react-cookie-consent';
import { Route, Routes } from 'react-router-dom';

import AuthorizedApolloProvider from '../AuthorizedApolloProvider';
import ComponentsPreviewRoute from '../routes/ComponentsRoute';
import CountryRoute from '../routes/CountryRoute';
import CreateTripRoute from '../routes/CreateTripRoute';
import MyAccountRoute from '../routes/MyAccountRoute';
import TripPreviewRoute from '../routes/TripPreviewRoute';
import { LoaderFullScreen } from '../theme-components/Loader';

import AppBar from './AppBar';
import AppFooter from './AppFooter';

const HomeRoute = lazy(() => import('../routes/HomeRoute'));
const NotFoundRoute = lazy(() => import('../routes/NotFoundRoute'));
const TripRoute = lazy(() => import('../routes/TripRoute'));

export function App() {
  return (
    <Box>
      <Box sx={{ position: 'relative', minHeight: '100vh' }}>
        <AppBar />
        <Suspense fallback={<LoaderFullScreen />}>
          <Routes>
            <Route path="/" element={<HomeRoute />} />
            <Route path="/trip/:id" element={<TripRoute />} />
            <Route
              path="/preview/:id"
              element={
                <AuthorizedApolloProvider>
                  <TripPreviewRoute />
                </AuthorizedApolloProvider>
              }
            />
            <Route
              path="/components-preview/"
              element={<ComponentsPreviewRoute />}
            />
            <Route path="/my-account" element={<MyAccountRoute />} />
            <Route
              path="/create-route/:userTripId"
              element={<CreateTripRoute />}
            />
            <Route
              path="/country/vietnam"
              element={
                <CountryRoute
                  countryId="country_1nn4ljempvY9CHTuSBtEoOeXiwI"
                  countryName="Vietnam"
                  countryImagePath="/assets/countries-images/vietnam.jpg"
                />
              }
            />
            <Route
              path="/country/united-kingdom"
              element={
                <CountryRoute
                  countryId="country_1pDgf43GOAJ87QNRK9sh5l9RQhk"
                  countryName="United Kingdom"
                  countryImagePath="/assets/countries-images/united-kingdom.jpg"
                />
              }
            />
            <Route path="*" element={<NotFoundRoute />} />
          </Routes>
        </Suspense>
      </Box>
      <CookieConsent
        location="bottom" // Can be 'top', 'bottom', 'none', etc.
        buttonText="I understand" // Text for the consent button
        cookieName="ga-cookie" // Unique name for the cookie
        style={{ background: '#2B373B' }} // Custom styling for the consent bar
        buttonStyle={{ color: '#4e503b', fontSize: '13px' }} // Custom styling for the consent button
        expires={150} // Expiration of the cookie in days
        onAccept={() => {
          window['ga-disable-G-1BZRDDCMGF'] = false;
          gtag('config', 'G-1BZRDDCMGF');
        }}
      >
        Rooutie uses cookies only to enhance the user experience.
      </CookieConsent>
      <AppFooter />
    </Box>
  );
}

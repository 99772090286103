import { useMutation } from '@apollo/client';
import Backdrop from '@material-ui/core/Backdrop';
import { Box, Stack, Typography } from '@mui/material';
import Fade from '@mui/material/Fade';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { setDays } from '../../../store/DaySlice';
import { setErrorModalOpen, setErrorTitle } from '../../../store/ErrorSlice';
import { setTrip } from '../../../store/TripSlice';
import { PrimaryButton, TextButton } from '../../../theme-components/Buttons';
import { Loader } from '../../../theme-components/Loader';
import { returnFormatedDate } from '../../../utils/helpers';
import { MUTATION_UPDATE_USER_TRIP } from '../../gql-user/updateUserTrip';
import { VerticalFlex } from '../../helpers/flex';
import { CenteredModal, ModalTitle } from '../../styling/modal';

export default function AddStartDateModal(props: {
  tripId: string;
  date: Date;
  add: boolean;
}) {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());

  const [addStartDay, { loading }] = useMutation(MUTATION_UPDATE_USER_TRIP, {
    variables: {
      startDate: selectedDate,
      trip_id: props.tripId,
    },
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const disableScroll = e => e.preventDefault();
    if (open) {
      // Lock body scroll
      document.body.style.overflow = 'hidden';
      document.addEventListener('touchmove', disableScroll, {
        passive: false,
      });
    } else {
      // Unlock body scroll
      document.body.style.overflow = '';
      document.removeEventListener('touchmove', disableScroll);
    }

    // Cleanup function to restore scroll when component unmounts or modal closes
    return () => {
      document.body.style.overflow = '';
      document.removeEventListener('touchmove', disableScroll);
    };
  }, [open]);

  return (
    <>
      {props.add ? (
        <>
          <TextButton
            onClick={() => {
              gtag('event', 'add-trip-start-date-button');
              handleOpen();
            }}
          >
            When are you leaving?
          </TextButton>
        </>
      ) : (
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <Typography
            sx={theme => ({
              alignSelf: 'center',
              fontWeight: 600,
              fontSize: 16,
              color: theme.palette.secondary.dark,
              [theme.breakpoints.down('sm')]: {
                fontSize: 14,
              },
            })}
          >
            Leaving:
          </Typography>
          <Typography
            sx={theme => ({
              alignSelf: 'center',
              fontWeight: 500,
              fontSize: 16,
              color: theme.palette.secondary.main,
              [theme.breakpoints.down('sm')]: {
                fontSize: 14,
              },
            })}
          >
            {props.date && returnFormatedDate(props.date, 0)}
          </Typography>
          <Typography
            sx={theme => ({
              alignSelf: 'center',
              fontWeight: 500,
              fontSize: 16,
              color: theme.palette.primary.light,
              [theme.breakpoints.down('sm')]: {
                fontSize: 14,
              },
            })}
            onClick={() => {
              gtag('event', 'edit-trip-start-date-button');
              handleOpen();
            }}
          >
            Change
          </Typography>
        </Stack>
      )}
      <CenteredModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box
            sx={theme => ({
              backgroundColor: theme.palette.background.paper,
              outline: 'none',
              borderRadius: 5,
              maxHeight: '95%',
              boxShadow: theme.shadows[5],
              padding: theme.spacing(2, 4, 3),
              [theme.breakpoints.down('sm')]: {
                width: '95%',
                padding: theme.spacing(2, 2, 2),
              },
            })}
          >
            <ModalTitle>When are you leaving?</ModalTitle>
            <Box>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <StaticDatePicker
                  displayStaticWrapperAs="desktop"
                  openTo="day"
                  value={selectedDate}
                  onChange={(newValue: Date | null) =>
                    setSelectedDate(newValue)
                  }
                />
              </LocalizationProvider>
              <VerticalFlex>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <PrimaryButton
                    disabled={loading}
                    onClick={async () => {
                      gtag('event', 'edit-start-date-save');
                      try {
                        const res = await addStartDay();
                        if (
                          res &&
                          res.data &&
                          res.data.updateUserTrip &&
                          res.data.updateUserTrip.itinerary &&
                          res.data.updateUserTrip.itinerary.days
                        ) {
                          dispatch(setTrip(res.data.updateUserTrip));
                          dispatch(
                            setDays(res.data.updateUserTrip.itinerary.days),
                          );
                        }
                        handleClose();
                      } catch (e) {
                        gtag('event', 'error-edit-start-date');
                        dispatch(setErrorTitle('Error saving start date'));
                        dispatch(setErrorModalOpen(true));
                      }
                    }}
                  >
                    {loading ? <Loader /> : 'Save start date'}
                  </PrimaryButton>
                </Box>
              </VerticalFlex>
            </Box>
          </Box>
        </Fade>
      </CenteredModal>
    </>
  );
}

import { Box, Stack } from '@mui/material';
import { styled } from '@mui/styles';
import React from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';

import { Day } from '../../../generated/user_graphql';
import { daysSelector } from '../../../store/DaySlice';
import { Loader } from '../../../theme-components/Loader';
import { DayStepDetails } from '../edit-trip/DayStepDetails';
import { TransportStepDetails } from '../edit-trip/TransportStepDetails';

import { Container, CreateTripStepTitle, Handle, Item } from './Styles';

export function DraggableDroppableSteps(props: {
  tripId: string;
  day: Day;
  dayIndex: number;
  collapsedSteps: boolean;
  showEmptyDay: boolean;
}) {
  const { dayLoadingIndex, stepLoadingIndex } = useSelector(daysSelector);

  return (
    <Droppable droppableId={props.day.id} type="dayStep">
      {(provided, snapshot) => (
        <Container
          ref={provided.innerRef}
          $isDraggingOver={snapshot.isDraggingOver}
        >
          {props.day.steps &&
            props.day.steps.map((step, stepIndex) => (
              <React.Fragment key={stepIndex}>
                {step && (
                  <Draggable
                    key={step.id}
                    draggableId={step.id}
                    index={stepIndex}
                  >
                    {(provided, snapshot) => (
                      <>
                        <Item
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          $isDragging={snapshot.isDragging}
                          style={provided.draggableProps.style}
                        >
                          <Box
                            display="flex"
                            width="100%"
                            flexDirection="column"
                          >
                            {stepLoadingIndex === stepIndex &&
                            dayLoadingIndex === props.dayIndex ? (
                              <Handle {...provided.dragHandleProps}>
                                <Loader size={20} />
                                <CreateTripStepTitle
                                  step={step}
                                  showEdit={!props.showEmptyDay}
                                />
                              </Handle>
                            ) : (
                              <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                sx={{
                                  width: '100%',
                                  position: 'relative',
                                  overflow: 'hidden',
                                }}
                              >
                                {/* <HorizontalFlex> */}
                                <Handle {...provided.dragHandleProps}>
                                  <Box width="100%">
                                    <CreateTripStepTitle
                                      step={step}
                                      tripId={props.tripId}
                                      showEdit={!props.showEmptyDay}
                                    />
                                  </Box>
                                </Handle>
                              </Stack>
                            )}
                            {!props.collapsedSteps && (
                              <Box>
                                <DayStepDetails step={step} />
                              </Box>
                            )}
                          </Box>
                        </Item>
                      </>
                    )}
                  </Draggable>
                )}
                {props.day.steps && props.day.steps[stepIndex + 1] && (
                  <TransportStepDetails
                    currentStep={step}
                    nextStep={props.day.steps[stepIndex + 1]}
                  />
                )}
              </React.Fragment>
            ))}
          {props.showEmptyDay && (
            <>
              {props.day.steps?.length === 0 && (
                <StyledBox>Empty day drop here</StyledBox>
              )}
            </>
          )}

          {provided.placeholder}
        </Container>
      )}
    </Droppable>
  );
}

const StyledBox = styled(Box)(({ theme }) => ({
  height: '30px', // Set height to 30px
  marginLeft: 30,
  marginRight: 30,
  marginBottom: 10,
  width: '50%', // Set width to 10% of parent
  color: theme.palette.grey[500], // Set text color to light grey (can customize the specific shade)
  display: 'flex', // Flexbox for centering text (optional)
  alignItems: 'center', // Center text vertically
  justifyContent: 'left', // Center text horizontally
  border: `2px dotted ${theme.palette.grey[300]}`,
  padding: 20,
  whiteSpace: 'nowrap', // Prevent text from wrapping
  overflow: 'hidden', // Hide overflow if text is too long
  textOverflow: 'ellipsis', // Add ellipsis if text overflowss
}));

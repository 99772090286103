import * as React from 'react';

function GlobeIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      height="1em"
      viewBox="0 0 512 512"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M255.996 512c-75.777 0-143.957-33.11-190.867-85.602l102.113-53.753zm0 0"
        fill="#9ee2f8"
      />
      <path
        d="M144.297 235.984l2.93 156.676c-30.66 8.406-58.434 20.04-82.098 33.738C24.649 381.105 0 321.383 0 256zm0 0"
        fill="#78b9eb"
      />
      <path
        d="M124.281 256H0c0-65.383 24.648-125.105 65.129-170.398L177.25 99.324zm0 0"
        fill="#9ee2f8"
      />
      <path
        d="M147.227 119.34c-30.66-8.41-58.434-20.04-82.098-33.738C112.043 33.105 180.223 0 256 0zm0 0"
        fill="#78b9eb"
      />
      <path
        d="M256.004 512c75.777 0 143.957-33.11 190.867-85.602l-102.113-53.753zm0 0"
        fill="#005fe1"
      />
      <path
        d="M367.703 235.984l-2.93 156.676c30.66 8.406 58.434 20.04 82.098 33.738C487.351 381.105 512 321.383 512 256zm0 0"
        fill="#212cbb"
      />
      <path
        d="M387.719 256H512c0-65.383-24.648-125.105-65.129-170.398L334.75 99.324zm0 0"
        fill="#005fe1"
      />
      <path
        d="M364.773 119.34c30.66-8.41 58.434-20.04 82.098-33.738C399.957 33.105 331.777 0 256 0zm0 0"
        fill="#212cbb"
      />
      <path
        d="M364.773 392.66l-128.785-34.512L256 512h.004c40.058-.004 83.168-47.68 108.77-119.34zm0 0"
        fill="#218adc"
      />
      <path
        d="M256 378.164h.004c38.625 0 75.402 5.344 108.77 14.496 14.14-39.57 22.945-86.445 22.945-136.66l-151.735-20.016zm0 0"
        fill="#005fe1"
      />
      <path
        d="M235.984 113.816L256 256h131.719c0-50.215-8.809-97.09-22.946-136.66zm0 0"
        fill="#218adc"
      />
      <path
        d="M364.773 119.34C339.168 47.676 296.06 0 256 0l-20.016 66.918L256 133.836c38.629 0 75.402-5.344 108.773-14.496zm0 0"
        fill="#005fe1"
      />
      <path
        d="M147.227 392.66c25.605 71.66 68.71 119.336 108.77 119.34H256V378.164c-38.629.004-75.402 5.344-108.773 14.496zm0 0"
        fill="#78b9eb"
      />
      <path
        d="M124.281 256c0 50.215 8.809 97.094 22.946 136.66 33.37-9.152 70.144-14.492 108.773-14.496V256zm0 0M147.227 119.34c33.37 9.152 70.144 14.496 108.773 14.496V0c-40.059 0-83.168 47.676-108.773 119.34zm0 0"
        fill="#218adc"
      />
      <path
        d="M256 133.836c-38.629 0-75.402-5.344-108.773-14.496C133.09 158.91 124.28 205.785 124.28 256H256zm0 0"
        fill="#78b9eb"
      />
    </svg>
  );
}

export default GlobeIcon;

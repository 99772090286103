import PersonIcon from '@mui/icons-material/Person';
import { Box, Button, Container, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';

import Logo1, { ModernLogo } from './logo/Logo1';

// Styled components using MUI's styled API
const MainBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  paddingTop: 10,
  paddingBottom: 10,
  position: 'relative',
}));

const RightBox = styled(Box)(({ theme }) => ({
  paddingLeft: 10,
  paddingRight: 10,
  borderColor: '#EBEBEB',
  borderRadius: 40,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  '&:hover': {
    backgroundColor: '#f7f7f7',
  },
}));

const CreateTrip = styled(Box)(({ theme }) => ({
  marginLeft: 10,
  fontWeight: 600,
  fontSize: 13,
  padding: 10,
  color: '#222323',
  [theme.breakpoints.down('sm')]: {
    fontSize: 11,
    marginLeft: 5,
  },
}));

const Logo = styled(Box)(({ theme }) => ({
  width: 40,
  height: 40,
  display: 'flex',
  alignSelf: 'center',
  [theme.breakpoints.down('sm')]: {
    width: 30,
    height: 30,
  },
}));

const UserIconButton = styled(IconButton)(({ theme }) => ({
  width: 42,
  height: 42,
  display: 'flex',
  alignSelf: 'center',
  [theme.breakpoints.down('sm')]: {
    width: 35,
    height: 35,
    marginRight: -5,
  },
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

const BoxIconName = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
}));

const NewLabel = styled(Box)(({ theme }) => ({
  marginLeft: 5,
  marginRight: 10,
  backgroundColor: '#f9c86b',
  borderRadius: 20,
  fontSize: 8,
  fontWeight: 600,
  color: '#2A2A2A',
  paddingRight: 5,
  paddingLeft: 5,
  paddingBottom: 1,
  paddingTop: 2,
  justifyContent: 'flex-end',
  alignSelf: 'center',
  [theme.breakpoints.down('sm')]: {
    marginRight: -5,
  },
}));

const BoxName = styled(Box)(({ theme }) => ({
  fontFamily: `"Prosto One", cursive`,
  marginLeft: 5,
  fontSize: 40,
  fontWeight: 600,
  color: '#3F3D56',
  alignSelf: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: 30,
    marginLeft: 10,
  },
}));

const CreateItinerary = styled(Button)(({ theme }) => ({
  color: '#3F3D56',
  opacity: 1,
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

const CustomIconButton = styled(Button)(({ theme }) => ({
  color: '#3F3D56',
  opacity: 1,
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

export default function AppBar() {
  const navigate = useNavigate();

  return (
    <MainBox boxShadow={1}>
      <Container>
        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            flexGrow={1}
          >
            <BoxIconName
              onClick={() => {
                gtag('event', 'app-bar-rooutie-logo');
                navigate('/');
              }}
            >
              <Logo>
                <ModernLogo />
              </Logo>
              <BoxName>rooutie</BoxName>
            </BoxIconName>
          </Box>

          <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
            <CreateItinerary
              disableRipple
              disableFocusRipple
              href="#"
              onClick={() => {
                gtag('event', 'app-bar-create-trip');
                navigate('/my-account');
              }}
            >
              <RightBox border={1} borderColor="#484848">
                <CreateTrip>Create itinerary</CreateTrip>
                <NewLabel>NEW</NewLabel>
              </RightBox>
            </CreateItinerary>
          </Box>
          <CustomIconButton
            disableRipple
            disableFocusRipple
            href="#"
            onClick={() => {
              gtag('event', 'app-bar-create-trip');
              navigate('/my-account');
            }}
          >
            <RightBox border={1} borderColor="#484848">
              <UserIconButton
                aria-label="account of current user"
                aria-controls="primary-search-account-menu"
                aria-haspopup="true"
                color="inherit"
              >
                <PersonIcon />
              </UserIconButton>
            </RightBox>
          </CustomIconButton>
        </Box>
      </Container>
    </MainBox>
  );
}

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { QUERY_GET_USER_TRIPS_WITH_DAYS_ONLY } from '../../components/gql-user/userTripsQuery';
import { setUserTrips } from '../../store/TripSlice';
import { MUTATION_COPY_TRIP } from '../gql-user/copyPublicTripMutation';
import { Button } from '@mui/material';
import { QUERY_GET_USER } from '../gql-user/user';
import { setUser } from '../../store/UserSlice';
import { User } from '../../generated/user_graphql';

export default function AuthorisedUserTrips() {
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth0();

  const [getUserTrips, { data: userTripsData }] = useLazyQuery(
    QUERY_GET_USER_TRIPS_WITH_DAYS_ONLY,
  );

  const [getUser, { data: userData }] = useLazyQuery<{ getUser: User }>(
    QUERY_GET_USER,
  );

  useEffect(() => {
    const fetchUserTrips = async () => {
      if (isAuthenticated) {
        await getUserTrips();
        await getUser();
      }
    };

    fetchUserTrips();
  }, [isAuthenticated, getUserTrips, getUser]);

  useEffect(() => {
    if (isAuthenticated) {
      if (
        userTripsData &&
        userTripsData.userTripsWithDaysOnly &&
        userTripsData.userTripsWithDaysOnly.length > 0
      ) {
        dispatch(setUserTrips(userTripsData.userTripsWithDaysOnly));
      }
      if (userData && userData.getUser) {
        dispatch(setUser(userData.getUser));
      }
    }
  }, [userTripsData, dispatch, userData]); // dependencies for useEffect

  return <></>;
}

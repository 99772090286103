import { useMutation } from '@apollo/client';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import EditLocationAltTwoToneIcon from '@mui/icons-material/EditLocationAltTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import MapTwoToneIcon from '@mui/icons-material/MapTwoTone';
import PhotoSizeSelectActualTwoToneIcon from '@mui/icons-material/PhotoSizeSelectActualTwoTone';
import { Button, Tooltip, Box } from '@mui/material';
import Fade from '@mui/material/Fade';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { setDays } from '../../../store/DaySlice';
import { setErrorModalOpen, setErrorTitle } from '../../../store/ErrorSlice';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../theme-components/Buttons';
import { Loader } from '../../../theme-components/Loader';
import { MUTATION_DELETE_STEP } from '../../gql-user/deleteStepMutation';
import { VerticalFlex } from '../../helpers/flex';
import {
  SmallModalPaper,
  ModalDescription,
  ModalTitle,
  CenteredModal,
  MediumModalPaper,
} from '../../styling/modal';
import { isAdmin, isPublisher, useUserRoles } from '../../../utils/helpers';
import { MUTATION_PUBLISH_TRIP } from '../../gql-user/publishTrip';
import { setTrip } from '../../../store/TripSlice';
import { Trip } from '../../../generated/user_graphql';
import EditTripNameModal from '../manage-trip-modals/EditTripName';
import PublishedWithChangesTwoToneIcon from '@mui/icons-material/PublishedWithChangesTwoTone';
import UnpublishedTwoToneIcon from '@mui/icons-material/UnpublishedTwoTone';
import LuggageTwoToneIcon from '@mui/icons-material/LuggageTwoTone';
import EditTripDescriptionModal from '../manage-trip-modals/AddEditDescription';
import { useSelector } from 'react-redux';
import { userSelector } from '../../../store/UserSlice';

interface TripActionsProps {
  trip: Trip;
}

export const ActionsTrip: React.FC<TripActionsProps> = ({ trip }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseModal = () => {
    handleClose();
  };

  const openMenu = Boolean(anchorEl);
  useEffect(() => {
    if (openMenu) {
      const handleTouchMove = () => {
        handleClose();
      };

      // Listen to touchmove events
      window.addEventListener('touchmove', handleTouchMove);

      // Cleanup the event listener when the menu is closed
      return () => {
        window.removeEventListener('touchmove', handleTouchMove);
      };
    }
  }, [openMenu]);

  return (
    <Box
      sx={theme => ({
        flexShrink: 0, // Prevent the button from shrinking
      })}
    >
      <Button
        variant="contained"
        // startIcon={
        //   <LuggageTwoToneIcon
        //     sx={theme => ({
        //       height: 17,
        //       width: 17,
        //       pointerEvents: 'none',
        //     })}
        //   />
        // }
        endIcon={
          <ArrowDropDownIcon
            sx={theme => ({
              height: 17,
              width: 17,
              pointerEvents: 'none',
            })}
          />
        }
        onClick={handleClick}
        sx={theme => ({
          fontSize: 12,
          paddingTop: 0.5,
          paddingBottom: 0.5,
          paddingLeft: 1.75,
          paddingRight: 1.75,
          backgroundColor: '#36454F',
          borderRadius: 20,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          boxShadow: 'none', // Disable shadow
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: '#4A5D6F',
          },
        })}
      >
        Trip actions
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom', // Adjust vertical anchor
          horizontal: 'left', // Adjust horizontal anchor
        }}
        transformOrigin={{
          vertical: 'top', // Adjust vertical transform
          horizontal: 'left', // Adjust horizontal transform
        }}
      >
        <MenuItem>
          <ListItemIcon>
            <EditTwoToneIcon />
          </ListItemIcon>
          <EditTripNameModal
            name={trip.name}
            editTripId={trip.id}
            handleCloseModal={handleCloseModal}
          />
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <PhotoSizeSelectActualTwoToneIcon />
          </ListItemIcon>
          <EditTripDescriptionModal
            description={trip.description ? trip.description : ''}
            editTripId={trip.id}
            tripActions={true}
            handleCloseModal={handleCloseModal}
          />
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            {trip.published || trip.publish_pending ? (
              <UnpublishedTwoToneIcon />
            ) : (
              <PublishedWithChangesTwoToneIcon />
            )}
          </ListItemIcon>
          <PublishTrip
            tripId={trip.id}
            publish={trip.published || trip.publish_pending ? false : true}
            handleCloseModal={handleCloseModal}
          />
        </MenuItem>
        <Tooltip title="Coming soon!">
          <span>
            {/* Wrapper required for disabled MenuItem to allow Tooltip */}
            <MenuItem disabled>
              <ListItemIcon>
                <DeleteTwoToneIcon />
              </ListItemIcon>
              Delete trip
            </MenuItem>
          </span>
        </Tooltip>
      </Menu>
    </Box>
  );
};

const PublishTrip: React.FC<{
  tripId: any;
  publish: boolean;
  handleCloseModal: () => void;
}> = ({ tripId, publish, handleCloseModal }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const roles = useUserRoles();

  const [publishTrip, { loading }] = useMutation(MUTATION_PUBLISH_TRIP, {
    variables: {
      trip_id: tripId,
      publish,
    },
  });

  const closeModal = () => {
    setOpen(false);
    handleCloseModal();
  };

  const { myUser } = useSelector(userSelector);

  useEffect(() => {
    const disableScroll = e => e.preventDefault();
    if (open) {
      // Lock body scroll
      document.body.style.overflow = 'hidden';
      document.addEventListener('touchmove', disableScroll, {
        passive: false,
      });
    } else {
      // Unlock body scroll
      document.body.style.overflow = '';
      document.removeEventListener('touchmove', disableScroll);
    }

    // Cleanup function to restore scroll when component unmounts or modal closes
    return () => {
      document.body.style.overflow = '';
      document.removeEventListener('touchmove', disableScroll);
    };
  }, [open]);

  return (
    <>
      <Tooltip
        title={
          !myUser || !myUser.nickName
            ? 'You need a nickname to perform this action'
            : ''
        }
        arrow
        placement="top"
        disableHoverListener={false}
      >
        <Box
          onClick={() => {
            if (!myUser || !myUser.nickName) {
            } else {
              gtag('event', 'publish-trip-button');
              setOpen(true);
            }
          }}
          sx={{ width: '100%' }}
        >
          {publish ? 'Publish trip' : 'Unpublish trip'}
        </Box>
      </Tooltip>

      <CenteredModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={closeModal}
        closeAfterTransition
      >
        <Fade in={open}>
          <MediumModalPaper>
            {publish ? (
              <ModalTitle>Exciting!</ModalTitle>
            ) : (
              <ModalTitle>Oh no!</ModalTitle>
            )}
            {publish ? (
              <>
                {isPublisher(roles) || isAdmin(roles) ? (
                  <>
                    <ModalDescription sx={{ marginBottom: 1 }}>
                      Thank you for publishing this trip.
                    </ModalDescription>
                    <ModalDescription>
                      Your trip will be visible to everyone once you publish it.
                    </ModalDescription>
                  </>
                ) : (
                  <>
                    <ModalDescription sx={{ marginBottom: 1 }}>
                      Thank you for publishing this trip.
                    </ModalDescription>
                    <ModalDescription>
                      Your trip will be visible to everyone once we review it
                      and approve.
                    </ModalDescription>
                  </>
                )}
              </>
            ) : (
              <>
                <ModalDescription sx={{ marginBottom: 1 }}>
                  We are sad for this trip to go hidden.
                </ModalDescription>
                <ModalDescription>
                  Hope you publish another one soon.
                </ModalDescription>
              </>
            )}

            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
              mt={2}
            >
              <Box>
                <SecondaryButton
                  disabled={loading}
                  onClick={async () => {
                    gtag('event', 'publish-trip-close');
                    setOpen(false);
                    closeModal();
                  }}
                  sx={{ marginRight: 1 }}
                >
                  Close
                </SecondaryButton>
                <PrimaryButton
                  disabled={loading}
                  onClick={async () => {
                    gtag('event', 'publish-trip-confirm');
                    try {
                      const res = await publishTrip();
                      if (res && res.data && res.data.publishUserTrip) {
                        dispatch(setTrip(res.data.publishUserTrip));
                        closeModal();
                      }
                    } catch (e) {
                      gtag('event', 'error-publishing-trip');
                      dispatch(setErrorTitle('Error publishing trip'));
                      dispatch(setErrorModalOpen(true));
                    }
                  }}
                >
                  {loading ? (
                    <Loader />
                  ) : (
                    <>{publish ? 'Publish' : 'Unpublish'}</>
                  )}
                </PrimaryButton>
              </Box>
            </Box>
          </MediumModalPaper>
        </Fade>
      </CenteredModal>
    </>
  );
};

import CloseIcon from '@mui/icons-material/Close';
import { Drawer, IconButton, Box, Divider, Link } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { ReactNode, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { Country } from '../../generated/public_graphql';
import { getQueryParam } from '../../utils/queryParams';
import GlobeIcon from '../icons/GlobeIcon';
import TipsIcon from '../icons/TipsIcon';
import VaccineIcon from '../icons/VaccineIcon';
import VietnamFlagIcon from '../icons/VietnamFlagIcon';
import VisasIcon from '../icons/VisasIcon';
import WeatherIcon from '../icons/WeatherIcon';

import { TripCountries } from './TripCountries';
import { TripHealth } from './TripHealth';
import { TripSeasons } from './TripSeasons';
import { TripTipsBeforeYouGo } from './TripTipsBeforeYouGo';
import { TripVisas } from './TripVisas';

interface IconeTypeProps {
  readonly $iconType: string;
}

const useStyles = makeStyles(theme =>
  createStyles({
    detailsDrawer: {
      padding: 25,
      maxWidth: 600,
      [theme.breakpoints.down('sm')]: {},
    },
    closeIconButton: {
      marginLeft: -20,
      marginTop: -10,
      display: 'flex',
    },
    closeIcon: {
      width: 30,
      height: 30,
      color: theme.palette.secondary.main,
    },
    sideBarHeader: {
      fontWeight: 600,
      fontSize: 45,
      alignSelf: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: 35,
      },
    },
    sideBarSubHeader: {
      fontWeight: 600,
      fontSize: 22,
      marginBottom: 10,
      [theme.breakpoints.down('sm')]: {
        fontSize: 22,
      },
    },
    sideBarSubSubHeader: {
      fontWeight: 600,
      fontSize: 20,
      marginBottom: 5,
      [theme.breakpoints.down('sm')]: {
        fontSize: 20,
      },
    },
    sideBarDescription: {
      fontWeight: 400,
      fontSize: 18,
      color: theme.palette.secondary.main,
      [theme.breakpoints.down('sm')]: {
        fontSize: 18,
      },
    },
    sideBarLink: {
      fontWeight: 600,
      fontSize: 16,
      color: theme.palette.primary.main,
      [theme.breakpoints.down('sm')]: {
        fontSize: 16,
      },
    },
    divider: {
      marginBottom: 20,
      marginTop: 10,
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: 10,
    },
    icon: {
      marginRight: 20,
      width: 60,
      height: 60,
      alignSelf: 'center',
      [theme.breakpoints.down('sm')]: {
        marginRight: 10,
        width: 40,
        height: 40,
      },
    },
  }),
);

export function TripSidebar(props: { countries: [Country] }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [modalId, setModalId] = useState('');
  const open = [
    'seasons',
    'tips',
    'vaccinations',
    'countries_details',
    'visas',
    'tips',
  ].includes(modalId);

  useEffect(() => {
    const modal = getQueryParam(location.search, 'modal');
    const modalValue = typeof modal === 'string' ? modal : '';
    setModalId(modalValue || '');
  }, [location]);

  function onClose() {
    navigate(-1);
  }

  return (
    <Drawer
      classes={{
        paper: classes.detailsDrawer,
      }}
      open={open}
      anchor="right"
      variant="temporary"
      onClose={onClose}
    >
      <Box flex="column">
        <IconButton
          className={classes.closeIconButton}
          aria-label="closeDrawer"
          color="secondary"
          onClick={() => {
            onClose();
            gtag('event', 'close-side-bar');
          }}
        >
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
        {modalId === 'seasons' && <TripSeasons countries={props.countries} />}
        {modalId === 'vaccinations' && (
          <TripHealth countries={props.countries} />
        )}
        {modalId === 'countries_details' && (
          <TripCountries countries={props.countries} />
        )}
        {modalId === 'visas' && <TripVisas countries={props.countries} />}
        {modalId === 'tips' && (
          <TripTipsBeforeYouGo countries={props.countries} />
        )}
      </Box>
    </Drawer>
  );
}

export function SideBarHeader(props: { children: ReactNode }) {
  const classes = useStyles();
  return <Box className={classes.sideBarHeader}>{props.children}</Box>;
}

export function SideBarSubHeader(props: { children: ReactNode }) {
  const classes = useStyles();
  return <Box className={classes.sideBarSubHeader}>{props.children}</Box>;
}

export function SideBarSubSubHeader(props: { children: ReactNode }) {
  const classes = useStyles();
  return <Box className={classes.sideBarSubSubHeader}>{props.children}</Box>;
}
export function SideBarDescription(props: { children: ReactNode }) {
  const classes = useStyles();
  return <Box className={classes.sideBarDescription}>{props.children}</Box>;
}

export function SideBarTitleDivider() {
  const classes = useStyles();
  return <Divider className={classes.divider} />;
}

export function SideBarLink(props: { url: string; copy: string }) {
  const classes = useStyles();
  return (
    <Link
      target="_blank"
      rel="noopener noreferrer"
      href={props.url}
      className={classes.sideBarLink}
      onClick={() => {
        gtag('event', 'click-link-on-side-bar');
      }}
    >
      {props.copy}
    </Link>
  );
}

export function SideBarIcon(props: IconeTypeProps) {
  const classes = useStyles();
  switch (true) {
    case props.$iconType.includes('Vietnam'):
      return <VietnamFlagIcon className={classes.icon} />;
    case props.$iconType.includes('WEATHER'):
      return <WeatherIcon className={classes.icon} />;
    case props.$iconType.includes('VISAS'):
      return <VisasIcon className={classes.icon} />;
    case props.$iconType.includes('VACCINE'):
      return <VaccineIcon className={classes.icon} />;
    case props.$iconType.includes('TIPS'):
      return <TipsIcon className={classes.icon} />;
    default:
      return <GlobeIcon className={classes.icon} />;
  }
}

import gql from 'graphql-tag';

export const QUERY_GET_PUBLISHED_TRIPS_FOR_COUNTRY = gql`
  query searchTripsByCountry($country_id: String!) {
    searchTripsByCountry(country_id: $country_id) {
      id
      name
      length
      published
      publish_pending
      countries {
        id
        name
      }
      itineraryCountries {
        countryName
        itineraryId
      }
    }
  }
`;

import { useAuth0 } from '@auth0/auth0-react';

function LoginButton() {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  const handleLogin = () => {
    const currentUrl = window.location.pathname; // Get the current URL path
    loginWithRedirect({
      appState: { targetUrl: currentUrl }, // Capture the current path to return to
    });
  };

  return !isAuthenticated ? (
    <button onClick={handleLogin}>Log in</button>
  ) : null;
}

export default LoginButton;

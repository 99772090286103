import AccessTimeTwoToneIcon from '@mui/icons-material/AccessTimeTwoTone';
import LanguageIcon from '@mui/icons-material/Language';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import { Box, Typography, Link, Stack, useTheme, styled } from '@mui/material';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import { useDispatch, useSelector } from 'react-redux';

import { PlaceHours } from '../../../generated/user_graphql';
import {
  setOpenHoursModal,
  setPlaceIdForOpenHours,
  stepsSelector,
} from '../../../store/StepSlice';
import { ModalTitle } from '../../styling/modal';

export const PlaceMoreInfoModal = ({ place }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { openHoursModal, placeIdForOpenHours } = useSelector(stepsSelector);

  if (!place) {
    return <></>;
  }

  if (
    (!place.openingHours || place.openingHours.length === 0) &&
    !place.website &&
    !place.address &&
    !place.phoneNumber
  ) {
    return <></>;
  }

  const weekDayOrder = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];

  let orderedHours: PlaceHours[] = [];
  if (place.openingHours && place.openingHours.length > 0) {
    orderedHours = [...place.openingHours];
    orderedHours = orderedHours.sort(
      (a, b) =>
        weekDayOrder.indexOf(a.weekDay) - weekDayOrder.indexOf(b.weekDay),
    );
  }

  let websiteUrl = '';
  if (
    place &&
    place.website &&
    (place.website.startsWith('http://') ||
      place.website.startsWith('https://'))
  ) {
    websiteUrl = place.website;
  }

  return (
    <Modal
      open={openHoursModal && placeIdForOpenHours === place.id}
      onClose={() => dispatch(setOpenHoursModal(false))}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'scroll',
      }}
    >
      <Fade in={openHoursModal}>
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            outline: 'none',
            maxWidth: 450,
            borderRadius: '20px',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 3, 2),
            [theme.breakpoints.down('sm')]: {
              padding: theme.spacing(2, 2, 2),
              maxWidth: '95%',
            },
          }}
        >
          <ModalTitle>More info</ModalTitle>
          <Stack direction="row" spacing={0.5} sx={{ mt: 1 }}>
            {orderedHours && orderedHours.length > 0 && (
              <Box
                sx={{
                  width: '100%',
                  [theme.breakpoints.down('sm')]: {},
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: 16,
                    alignSelf: 'center',
                    color: '#6E7191',
                    marginBottom: 1,
                  }}
                >
                  Opening Hours
                </Typography>
                {orderedHours &&
                  orderedHours.map((hours, index) => (
                    <Typography
                      key={index}
                      sx={{
                        fontWeight: 500,
                        fontSize: 14,
                        alignSelf: 'center',
                        color: '#6E7191',
                        marginBottom: 0.3,
                      }}
                    >
                      {hours.weekDay.substring(0, 3)}: {hours.hours}
                    </Typography>
                  ))}
              </Box>
            )}

            {place && (place.website || place.address || place.phoneNumber) && (
              <Box
                sx={{
                  width: '100%',
                  [theme.breakpoints.down('sm')]: {},
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: 16,
                    alignSelf: 'center',
                    color: '#6E7191',
                    marginBottom: 1,
                  }}
                >
                  Other Info
                </Typography>
                {/* Website Link */}
                {websiteUrl !== '' && (
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    sx={{ mt: 1 }}
                  >
                    <LanguageIcon
                      sx={theme => ({
                        width: 25,
                        height: 25,
                        marginRight: 10,
                        color: '#AB88F3',
                        [theme.breakpoints.down('sm')]: {
                          width: 20,
                          height: 20,
                        },
                      })}
                    />
                    <Link
                      href={websiteUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      variant="body1"
                    >
                      {truncateWithEllipsis(cleanUrl(websiteUrl))}
                    </Link>
                  </Stack>
                )}
                {/* Address */}
                {place && place.address && (
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    sx={{ mt: 1 }}
                  >
                    <LocationOnIcon
                      sx={theme => ({
                        width: 25,
                        height: 25,
                        marginRight: 10,
                        color: '#AB88F3',
                        [theme.breakpoints.down('sm')]: {
                          width: 20,
                          height: 20,
                        },
                      })}
                    />
                    <Typography variant="body2">{place.address}</Typography>
                  </Stack>
                )}
                {/* Contact Details */}
                {place && place.phoneNumber && (
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    sx={{ mt: 1 }}
                  >
                    <PhoneIcon
                      sx={theme => ({
                        width: 25,
                        height: 25,
                        marginRight: 10,
                        color: '#AB88F3',
                        [theme.breakpoints.down('sm')]: {
                          width: 20,
                          height: 20,
                        },
                      })}
                    />
                    <Link href={`tel:${place.phoneNumber}`} variant="body1">
                      {place.phoneNumber}
                    </Link>
                  </Stack>
                )}
              </Box>
            )}
          </Stack>
        </Box>
      </Fade>
    </Modal>
  );
};

export const TodaysHours = ({ place }) => {
  const dispatch = useDispatch();

  if (!place || !place.openingHours || place.openingHours.length === 0) {
    return null;
  }

  // Get the current day of the week
  const today = new Date().toLocaleString('en-us', { weekday: 'long' });
  // Find today's opening hours
  const todayHours = place.openingHours.find(hours => hours.weekDay === today);

  return (
    <Stack direction="row" alignItems="center" spacing={1} sx={{ mb: 0.5 }}>
      <AccessTimeTwoToneIcon
        sx={theme => ({
          width: 25,
          height: 25,
          color: theme.palette.icon.main,
          [theme.breakpoints.down('sm')]: {
            width: 20,
            height: 20,
          },
        })}
      />
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: 16,
          alignSelf: 'center',
          color: '#6E7191',
        }}
      >
        {today}:
      </Typography>
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: 16,
          alignSelf: 'center',
          color: '#6E7191',
        }}
      >
        {todayHours && todayHours.hours}
      </Typography>
      <TextButton
        onClick={() => {
          gtag('event', 'add-trip-start-date-button');
          dispatch(setOpenHoursModal(true));
          dispatch(setPlaceIdForOpenHours(place.id));
        }}
      >
        More times
      </TextButton>
    </Stack>
  );
};

function truncateWithEllipsis(str, maxLength = 18) {
  if (str.length > maxLength) {
    return str.substring(0, maxLength) + '...';
  }
  return str;
}

function cleanUrl(url) {
  const prefix = 'www.';
  if (url.startsWith('https://')) {
    url = url.slice(8); // Length of "https://"
  } else if (url.startsWith('http://')) {
    url = url.slice(7); // Length of "http://"
  }

  if (url.includes('/')) {
    const index = url.indexOf('/');
    if (index === -1) {
      if (!url.startsWith(prefix)) {
        url = prefix + url;
      }
      return url;
    }
    url = url.substring(0, index);
    if (!url.startsWith(prefix)) {
      url = prefix + url;
    }

    return url;
  }
  if (!url.startsWith(prefix)) {
    url = prefix + url;
  }
  return url;
}

const TextButton = styled(Box)(({ theme }) => ({
  padding: 0,
  margin: 0,
  fontWeight: 600,
  fontSize: 14,
  display: 'flex', // Enable flexbox layout
  alignItems: 'center', // Vertically center content
  justifyContent: 'center', // Horizontally center content
  color: theme.palette.primary.main, // Use custom color if provided
  [theme.breakpoints.down('sm')]: {
    fontSize: 12,
  },
}));

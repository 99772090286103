import MapsHomeWorkTwoToneIcon from '@mui/icons-material/MapsHomeWorkTwoTone';
import TodayTwoToneIcon from '@mui/icons-material/TodayTwoTone';
import { Box, Link, Typography, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/styles';
import React, { ReactNode } from 'react';

import { Day } from '../../generated/public_graphql';
import { TitleH3 } from '../../theme-components/Typography';

// eslint-disable-next-line import/no-named-as-default
import PublicPlaceCard from './PublicPlaceCard';
import { TransportStepDetails } from './TransportStepDetails';

export function TripDay(props: { day: Day; date?: string }) {
  return (
    <Box width="100%" sx={theme => ({})}>
      <Stack
        direction="row"
        alignItems="left"
        sx={theme => ({
          width: '100%',
        })}
      >
        <Box
          sx={theme => ({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            alignSelf: 'center',
            marginRight: 1,
            [theme.breakpoints.down('sm')]: {
              marginRight: 0.5,
            },
          })}
        >
          <TodayTwoToneIcon
            sx={theme => ({
              width: 25,
              height: 25,
              color: theme.palette.icon.main,
              [theme.breakpoints.down('sm')]: {
                width: 20,
                height: 20,
              },
            })}
          />
        </Box>
        <TitleH3>Day {props.day.dayNumber}</TitleH3>
      </Stack>
      {props.day && props.day.cities && props.day.cities.length > 0 && (
        <Stack
          direction="row"
          alignItems="left"
          spacing={0.5}
          sx={theme => ({
            marginTop: 1,
            marginBottom: 1,
            width: '100%',
            [theme.breakpoints.down('sm')]: {
              fontSize: 16,
            },
          })}
        >
          <Box
            sx={theme => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              alignSelf: 'center',
              paddingLeft: 4,
              [theme.breakpoints.down('sm')]: {
                paddingLeft: 3,
              },
            })}
          >
            <MapsHomeWorkTwoToneIcon
              sx={theme => ({
                width: 25,
                height: 25,
                color: theme.palette.icon.main,
                [theme.breakpoints.down('sm')]: {
                  width: 20,
                  height: 20,
                },
              })}
            />
          </Box>
          <Typography
            sx={theme => ({
              alignSelf: 'center',
              justifyContent: 'center',
              fontWeight: 500,
              fontSize: 18,
              color: '#4E4B66',
              [theme.breakpoints.down('sm')]: {
                fontSize: 16,
              },
            })}
          >
            {props.day.cities.map((city, i) => (
              <React.Fragment key={i}>
                {city && (
                  <>
                    {city.cityName}
                    {i !== props.day.cities!.length - 1 && <>{', '}</>}
                  </>
                )}
              </React.Fragment>
            ))}
          </Typography>
        </Stack>
      )}

      <Box width="100%">
        {props.day &&
          props.day.steps &&
          props.day.steps.length > 0 &&
          props.day.steps.map((step, i) => {
            return (
              <React.Fragment key={i}>
                {step && <PublicPlaceCard step={step} dayId={props.day.id} />}
                {props.day.steps && props.day.steps[i + 1] && (
                  <TransportStepDetails
                    currentStep={step}
                    nextStep={props.day.steps[i + 1]}
                  />
                )}
              </React.Fragment>
            );
          })}
      </Box>
    </Box>
  );
}

export function StepDescription(props: { children: ReactNode }) {
  return <StepDescriptionBox>{props.children}</StepDescriptionBox>;
}

const StepDescriptionBox = styled(Box)(({ theme }) => ({
  paddingLeft: 0,
  width: '100%',
  fontWeight: 500,
  fontSize: 18,
  color: '#14142B',
  marginBottom: 15,
  [theme.breakpoints.down('sm')]: {
    fontSize: 16,
    marginBottom: 5,
    paddingLeft: 30,
  },
}));

const TransportStepBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  whiteSpace: 'nowrap',
  flexDirection: 'row',
  alignSelf: 'center',
  fontSize: 18,
  color: '#A1A0A0',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    whiteSpace: 'normal',
  },
}));

export function ExternalLinkRegular(props: { url: string; copy: string }) {
  const theme = useTheme();
  return (
    <Link
      target="_blank"
      rel="noopener noreferrer"
      href={props.url}
      sx={{
        fontWeight: 500, // Make font weight less bold
        fontSize: 14, // Smaller default font size
        alignSelf: 'center',
        color: '#8A8D9C', // Slightly lighter color for less prominence
        textDecoration: 'none', // No underline by default
        '&:hover': {
          textDecoration: 'none', // Underline on hover for subtle emphasis
          color: '#6E7191', // Darker on hover for better visibility
        },
        [theme.breakpoints.down('sm')]: {
          fontSize: 12, // Even smaller on smaller screens
        },
      }}
      onClick={() => {
        gtag('event', 'public-ext-link-maps-url');
      }}
    >
      {props.copy}
    </Link>
  );
}

export function ExternalLinkProminent(props: { url: string; copy: string }) {
  const theme = useTheme();
  return (
    <Link
      target="_blank"
      rel="noopener noreferrer"
      href={props.url}
      sx={{
        fontWeight: 600,
        fontSize: 16,
        alignSelf: 'center',
        color: theme.palette.primary.light,
        textDecoration: 'none', // No underline by default
        '&:hover': {
          textDecoration: 'underline', // Underline only on hover
        },
      }}
      onClick={() => {
        gtag('event', 'public-ext-link-maps-url');
      }}
    >
      {props.copy}
    </Link>
  );
}

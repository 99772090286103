import { useLazyQuery, useMutation } from '@apollo/client';
import Backdrop from '@material-ui/core/Backdrop';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Fade,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { setErrorModalOpen, setErrorTitle } from '../../../store/ErrorSlice';
import {
  PrimaryButton,
  ProminentButton,
  SecondaryButton,
} from '../../../theme-components/Buttons';
import { Loader } from '../../../theme-components/Loader';
import { MUTATION_CREATE_USER_TRIP_WITH_DAY } from '../../gql-user/createUserTripMutation';
import { QUERY_GET_COUNTRIES } from '../../gql-user/getCountries';
import { VerticalFlex } from '../../helpers/flex';
import {
  ModalDescription,
  ModalTitle,
  MediumModalPaper,
  CenteredModal,
} from '../../styling/modal';

import { StyledFormControl, useManageTripStyles } from './Styles';
import InfoIcon from '@mui/icons-material/Info'; // Import the Info icon

export default function CreateTripModal(props: { card?: boolean }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const [name, setName] = useState('');
  // const [countryId, setCountryId] = useState('');
  const [placeUrl, setPlaceUrl] = useState('');
  const [openInfo, setOpenInfo] = useState(false);
  const handleOpenInfo = () => setOpenInfo(true);
  const handleCloseInfo = () => setOpenInfo(false);
  const [isValidUrl, setIsValidUrl] = useState(true);

  const validateUrl = url => {
    const validUrlPatterns = [
      'https://maps.app.goo.gl/',
      'https://www.google.com/maps',
    ];

    // Check if the entered URL starts with any of the valid patterns
    return validUrlPatterns.some(pattern => url.startsWith(pattern));
  };

  useEffect(() => {
    const disableScroll = e => e.preventDefault();
    if (open) {
      // Lock body scroll
      document.body.style.overflow = 'hidden';
      document.addEventListener('touchmove', disableScroll, { passive: false });
    } else {
      // Unlock body scroll
      document.body.style.overflow = '';
      document.removeEventListener('touchmove', disableScroll);
    }

    // Cleanup function to restore scroll when component unmounts or modal closes
    return () => {
      document.body.style.overflow = '';
      document.removeEventListener('touchmove', disableScroll);
    };
  }, [open]);

  const handleChange = e => {
    const newUrl = e.target.value;
    setPlaceUrl(newUrl);

    // Validate the URL and update the validation state
    setIsValidUrl(validateUrl(newUrl));
  };

  const [addTrip, { loading, data }] = useMutation(
    MUTATION_CREATE_USER_TRIP_WITH_DAY,
    {
      variables: { name: name, place_url: placeUrl },
    },
  );

  const [
    getCountries,
    { data: countries, loading: countriesLoading, error: countriesError },
  ] = useLazyQuery(QUERY_GET_COUNTRIES);

  if (data && data.createUserTripWithDay.id) {
    navigate('/create-route/' + data.createUserTripWithDay.id);
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const disableScroll = e => e.preventDefault();
    if (open) {
      // Lock body scroll
      document.body.style.overflow = 'hidden';
      document.addEventListener('touchmove', disableScroll, {
        passive: false,
      });
    } else {
      // Unlock body scroll
      document.body.style.overflow = '';
      document.removeEventListener('touchmove', disableScroll);
    }

    // Cleanup function to restore scroll when component unmounts or modal closes
    return () => {
      document.body.style.overflow = '';
      document.removeEventListener('touchmove', disableScroll);
    };
  }, [open]);

  return (
    <Box>
      {!props.card ? (
        <ProminentButton
          onClick={() => {
            gtag('event', 'create-new-trip-button');
            handleOpen();
            getCountries();
          }}
        >
          Add new trip
        </ProminentButton>
      ) : (
        <Card
          sx={theme => ({
            maxWidth: 360,
            position: 'relative',
            borderRadius: 3,
            marginTop: 3,
            marginRight: 1,
            width: '33%',
            [theme.breakpoints.down(1100)]: {
              width: '48%',
            },
            [theme.breakpoints.down('sm')]: {
              marginRight: 0,
              width: '100%',
            },
          })}
          onClick={() => {
            gtag('event', 'create-new-trip-card');
            handleOpen();
            getCountries();
          }}
        >
          <CardMedia
            component="img"
            height="150"
            sx={{ borderRadius: '8px 8px 0 0' }}
            image={'/assets/image/placeholder-svg.svg'}
          />
          <CardContent
            sx={{
              display: 'flex',
              width: '100%',
              flexDirection: 'column',
              alignItems: 'flex-end', // Align items to the right
            }}
          >
            <ProminentButton>Add new trip</ProminentButton>
          </CardContent>
        </Card>
      )}

      <CenteredModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <>
          {countriesLoading && <Loader />}
          {open && countries && countries.countriesList && !countriesLoading ? (
            <>
              <Fade in={open}>
                <MediumModalPaper>
                  <ModalTitle>Create new trip</ModalTitle>
                  <ModalDescription>
                    We just need a few details to get started, pick the main
                    country for this trip and descriptie name.
                  </ModalDescription>
                  <StyledFormControl>
                    <TextField
                      id="standard-basic"
                      label="Trip name/title"
                      name="name"
                      fullWidth={true}
                      variant="outlined"
                      onChange={e => setName(e.target.value)}
                    />
                  </StyledFormControl>
                  <OrDivider />
                  <StyledFormControl>
                    <TextField
                      label="Google maps place shared URL"
                      fullWidth={true}
                      variant="outlined"
                      onChange={handleChange}
                      value={placeUrl}
                      error={!isValidUrl}
                      helperText={
                        !isValidUrl &&
                        'URL must start with https://maps.app.goo.gl/ or https://www.google.com/maps'
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleOpenInfo}
                              aria-label="show share instructions"
                            >
                              <InfoIcon />
                            </IconButton>
                            <SharePlacesModal
                              open={openInfo}
                              handleClose={handleCloseInfo}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </StyledFormControl>
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                    mt={2}
                  >
                    <VerticalFlex>
                      <PrimaryButton
                        disabled={loading || name === ''}
                        onClick={async () => {
                          gtag('event', 'create-new-trip-save');
                          try {
                            await addTrip();
                            handleClose();
                          } catch (e) {
                            gtag('event', 'error-create-new-trip-save');
                            dispatch(setErrorTitle('Error adding location'));
                            dispatch(setErrorModalOpen(true));
                          }
                        }}
                      >
                        {loading ? <Loader /> : 'Create new trip'}
                      </PrimaryButton>
                    </VerticalFlex>
                  </Box>
                </MediumModalPaper>
              </Fade>
            </>
          ) : (
            <Box>
              {countriesError && (
                <p>
                  Something went wrong <br />
                  please try again later
                </p>
              )}
            </Box>
          )}
        </>
      </CenteredModal>
    </Box>
  );
}

function TripTitle(props: { children: ReactNode }) {
  const classes = useManageTripStyles();
  return <Box className={classes.addTripCardTitle}>{props.children}</Box>;
}

const OrDivider = () => (
  <Box sx={{ display: 'flex', alignItems: 'center' }}>
    <Divider sx={{ flexGrow: 1 }} />
    <Typography sx={{ mx: 2, fontSize: 14 }}>OR</Typography>
    <Divider sx={{ flexGrow: 1 }} />
  </Box>
);

function SharePlacesModal({ open, handleClose }) {
  useEffect(() => {
    const disableScroll = e => e.preventDefault();
    if (open) {
      // Lock body scroll
      document.body.style.overflow = 'hidden';
      document.addEventListener('touchmove', disableScroll, {
        passive: false,
      });
    } else {
      // Unlock body scroll
      document.body.style.overflow = '';
      document.removeEventListener('touchmove', disableScroll);
    }

    // Cleanup function to restore scroll when component unmounts or modal closes
    return () => {
      document.body.style.overflow = '';
      document.removeEventListener('touchmove', disableScroll);
    };
  }, [open]);

  return (
    <CenteredModal
      open={open}
      onClose={handleClose}
      aria-labelledby="share-instruction-title"
      aria-describedby="share-instruction-description"
    >
      {/* <Box sx={modalStyle}> */}
      <MediumModalPaper>
        <ModalTitle>How bulk import from Google Maps</ModalTitle>
        <Stack direction="row" mt={3}>
          <Box
            sx={{ display: 'flex', justifyContent: 'center', marginRight: 2 }}
          >
            <img
              src="/assets/image/google-share-list.png"
              alt="Share button on Google Maps"
              style={{
                width: '90%',
                borderRadius: 8,
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
              }}
            />
          </Box>
          <ModalDescription id="share-instruction-description" sx={{ mt: 2 }}>
            To quickly add your saved places from Google Maps, just click on{' '}
            <strong>"Share {'->'} Send link to view"</strong> button and copy
            the URL. Then just paste to rooutie.
          </ModalDescription>
        </Stack>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end', // Aligns items to the right
          }}
        >
          {/* <VerticalFlex> */}
          <SecondaryButton
            onClick={handleClose}
            variant="contained"
            color="primary"
            sx={{ mt: 3 }}
          >
            Got it
          </SecondaryButton>
        </Box>
      </MediumModalPaper>
      {/* </Box> */}
    </CenteredModal>
  );
}

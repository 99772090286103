import * as React from 'react';

function TipsIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      height="1em"
      viewBox="-80 0 512 512.155"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M272.07 384.156h-192v-16a97.59 97.59 0 00-29.293-68.562c-68.172-69.11-67.511-180.364 1.47-248.664 68.983-68.297 180.237-67.848 248.667 1.004 68.426 68.855 68.188 180.113-.539 248.668a94.825 94.825 0 00-28.305 67.554zm0 0"
        fill="#ffcb5a"
      />
      <path
        d="M96.07 416.156h160c8.84 0 16 7.16 16 16 0 8.836-7.16 16-16 16h-160c-8.836 0-16-7.164-16-16 0-8.84 7.164-16 16-16zm0 0M128.07 480.156h96c8.84 0 16 7.16 16 16 0 8.836-7.16 16-16 16h-96c-8.836 0-16-7.164-16-16 0-8.84 7.164-16 16-16zm0 0"
        fill="#62808c"
      />
      <g fill="#fff">
        <path d="M64.07 160.156h32v32h-32zm0 0M128.07 192.156h32v192h32v-192h32v-32h-96zm0 0M256.07 160.156h32v32h-32zm0 0" />
      </g>
    </svg>
  );
}

export default TipsIcon;

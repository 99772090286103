import { Box } from '@mui/material';
import { useCallback, useState } from 'react';
import { Map, Marker } from 'react-map-gl';

import {
  ItineraryCoordinates,
  Maybe,
  Itinerary,
} from '../../generated/user_graphql';

interface TripItineraryProps {
  readonly itinerary: Itinerary;
}

export function TripMap(props: TripItineraryProps) {
  let boundingBox = [
    [0, 0],
    [0, 0],
  ];

  if (
    props.itinerary &&
    props.itinerary.coordinates &&
    props.itinerary.coordinates.length > 0
  ) {
    boundingBox = calculateBoundingBox(props.itinerary.coordinates);
  }

  const [viewport, setViewport] = useState({
    latitude: (boundingBox[0][1] + boundingBox[1][1]) / 2,
    longitude: (boundingBox[0][0] + boundingBox[1][0]) / 2,
    zoom: 10,
    width: '100%',
    height: '100%',
  });

  const fitBounds = useCallback(map => {
    map.fitBounds(boundingBox, {
      padding: { top: 40, bottom: 10, left: 10, right: 10 },
      duration: 2000,
    });
  }, []);

  const handleMapLoad = event => {
    fitBounds(event.target);
  };

  return (
    <Box
      sx={theme => ({
        // display: 'flex',
        flex: '1 0 0',
        position: 'sticky',
        top: '2vh',
        height: '96vh',
        overflow: 'hidden',
        width: '100%',
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
        // maxWidth: '100%',
        display: 'block',
        [theme.breakpoints.down(500)]: {
          display: 'none',
        },
      })}
    >
      <Map
        initialViewState={{
          latitude: (boundingBox[0][1] + boundingBox[1][1]) / 2,
          longitude: (boundingBox[0][0] + boundingBox[1][0]) / 2,
          zoom: 10,
        }}
        style={{ width: viewport.width, height: viewport.height }}
        mapStyle="mapbox://styles/mapbox/streets-v11"
        onLoad={handleMapLoad}
        mapboxAccessToken={process.env.REACT_APP_MAP_BOX_KEY}
      >
        {props.itinerary &&
          props.itinerary.coordinates &&
          props.itinerary.coordinates.length > 0 &&
          props.itinerary.coordinates.map((co, i) => {
            return (
              co && (
                <Marker
                  key={i}
                  longitude={co.longitude!}
                  latitude={co.latitude!}
                  onClick={() => {
                    gtag('event', 'map-click-on-marker');
                  }}
                  anchor="bottom"
                >
                  <img
                    src="/assets/icons/pin.svg"
                    alt="Map Marker"
                    width={30}
                    height={30}
                  />
                </Marker>
              )
            );
          })}
      </Map>
    </Box>
  );
}

function calculateBoundingBox(coordinates: Maybe<ItineraryCoordinates>[]) {
  // Initialize variables with extreme values
  let minLat = Infinity;
  let maxLat = -Infinity;
  let minLng = Infinity;
  let maxLng = -Infinity;

  // Iterate over all coordinates
  coordinates
    .filter(
      (item): item is ItineraryCoordinates =>
        item !== null && item !== undefined,
    )
    .forEach(({ latitude, longitude }) => {
      if (isValidCoordinates(latitude, longitude)) {
      } else {
      }
      if (
        latitude !== null &&
        latitude !== undefined &&
        longitude !== null &&
        longitude !== undefined
      ) {
        if (latitude < minLat) minLat = latitude;
        if (latitude > maxLat) maxLat = latitude;
        if (longitude < minLng) minLng = longitude;
        if (longitude > maxLng) maxLng = longitude;
      }
    });

  // Return the bounding box
  return [
    [minLng, minLat],
    [maxLng, maxLat],
  ];
}

function isValidLatitude(lat) {
  return lat >= -90 && lat <= 90;
}

function isValidLongitude(lng) {
  return lng >= -180 && lng <= 180;
}

function isValidCoordinates(lat, lng) {
  return isValidLatitude(lat) && isValidLongitude(lng);
}

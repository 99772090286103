import { useMutation } from '@apollo/client';
import Backdrop from '@material-ui/core/Backdrop';
import { Box } from '@mui/material';
import Fade from '@mui/material/Fade';
import { Editor } from '@tinymce/tinymce-react';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setErrorModalOpen, setErrorTitle } from '../../../store/ErrorSlice';
import { tripsSelector, setTripDescription } from '../../../store/TripSlice';
import {
  PrimaryButton,
  SecondaryButton,
  TextButton,
} from '../../../theme-components/Buttons';
import { Loader } from '../../../theme-components/Loader';
import { MUTATION_UPDATE_USER_TRIP } from '../../gql-user/updateUserTrip';
import {
  CenteredModal,
  LargeModalPaper,
  ModalDescription,
  ModalTitle,
} from '../../styling/modal';

import { useManageTripStyles } from './Styles';

export default function EditTripDescriptionModal(props: {
  description?: string;
  editTripId: string;
  tripActions: boolean;
  handleCloseModal?: () => void;
}) {
  const classes = useManageTripStyles();
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  const { tripDescription } = useSelector(tripsSelector);

  const [editTrip, { loading }] = useMutation(MUTATION_UPDATE_USER_TRIP, {
    variables: { description: tripDescription, trip_id: props.editTripId },
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (props.handleCloseModal !== undefined) {
      props.handleCloseModal();
    }
  };

  const handleEditorChange = (content: string) => {
    dispatch(setTripDescription(content));
  };

  useEffect(() => {
    const disableScroll = e => e.preventDefault();
    if (open) {
      // Lock body scroll
      document.body.style.overflow = 'hidden';
      document.addEventListener('touchmove', disableScroll, {
        passive: false,
      });
    } else {
      // Unlock body scroll
      document.body.style.overflow = '';
      document.removeEventListener('touchmove', disableScroll);
    }

    // Cleanup function to restore scroll when component unmounts or modal closes
    return () => {
      document.body.style.overflow = '';
      document.removeEventListener('touchmove', disableScroll);
    };
  }, [open]);

  return (
    <>
      {props.tripActions ? (
        <Box
          onClick={() => {
            gtag('event', 'edit-trip-description');
            handleOpen();
          }}
        >
          Edit description
        </Box>
      ) : (
        <>
          {!props.description && (
            <TextButton
              onClick={() => {
                gtag('event', 'add-trip-description');
                handleOpen();
              }}
            >
              Add trip description
            </TextButton>
          )}
        </>
      )}

      <CenteredModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <LargeModalPaper>
            {props.description ? (
              <ModalTitle>Edit description</ModalTitle>
            ) : (
              <ModalTitle>Add description</ModalTitle>
            )}
            <ModalDescription>
              Roughly describe what is traveler going to experience on this
              trip.
            </ModalDescription>
            <Editor
              apiKey={process.env.REACT_APP_TINY_EDITOR_KEY}
              value={tripDescription}
              init={{
                height: 300,
                menubar: false,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount',
                ],
                toolbar:
                  'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
              }}
              onEditorChange={handleEditorChange}
            />

            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
              mt={2}
            >
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
                mt={2}
              >
                <Box>
                  <SecondaryButton
                    disabled={loading}
                    onClick={async () => {
                      gtag('event', 'publish-trip-close');
                      setOpen(false);
                      handleClose();
                    }}
                    sx={{ marginRight: 1 }}
                  >
                    Close
                  </SecondaryButton>
                  <PrimaryButton
                    onClick={async () => {
                      gtag('event', 'edit-trip-description-save');
                      try {
                        await editTrip();
                        handleClose();
                      } catch (e) {
                        gtag('event', 'error-edit-trip-description-save');
                        dispatch(setErrorTitle('Error saving description'));
                        dispatch(setErrorModalOpen(true));
                      }
                    }}
                  >
                    {loading ? <Loader /> : 'Save description'}
                  </PrimaryButton>
                </Box>
              </Box>
            </Box>
          </LargeModalPaper>
        </Fade>
      </CenteredModal>
    </>
  );
}

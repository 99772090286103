import { useQuery } from '@apollo/client';
import { Box, Container, Stack } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { QUERY_USER_TRIP } from '../components/gql-user/userTrip';
import { RouteContent, RoutePage, RouteTitle } from '../components/route';
import { TripDetails } from '../components/trip-details/TripDetails';
import { TripHeader } from '../components/trip-details/TripHeader';
import { TripItinerary } from '../components/trip-details/TripItinerary';
import { TripMap } from '../components/trip-details/TripMap';
import { Trip, Itinerary } from '../generated/public_graphql';
import { LoaderFullScreen } from '../theme-components/Loader';
import AuthorizedApolloProvider from '../AuthorizedApolloProvider';
import AuthorisedUserTrips from '../components/trip-details/AuthorisedUserTrips';

export default function TripPreviewRoute() {
  const { id } = useParams<{ id: string }>();
  const [count, setCount] = useState(0);
  gtag('event', 'trip-preview-page');

  useEffect(() => {
    if (count === 0) {
      window.scrollTo(0, 0);
      setCount(1);
    }
  }, [count]);

  const { data, error, loading } = useQuery(QUERY_USER_TRIP, {
    variables: { trip_id: id },
  });

  if (loading) {
    return <LoaderFullScreen />;
  }

  if ((!id || error || !data) && !loading) {
    return (
      <RoutePage>
        <RouteTitle>Trip</RouteTitle>
        <RouteContent>Trip id not found</RouteContent>
      </RoutePage>
    );
  }

  const trip: Trip = data.userTrip;
  const itinerary: Itinerary = trip.itinerary!;

  return (
    <RoutePage>
      <Container>
        <RouteContent>
          <TripHeader trip={data.userTrip} />
          <Stack
            direction="row"
            alignItems="left"
            sx={{
              width: '100%',
            }}
          >
            <Box
              sx={theme => ({
                display: 'block',
                width: '50%',
                [theme.breakpoints.down('sm')]: {
                  display: 'none',
                },
              })}
            >
              <TripMap itinerary={itinerary} />
            </Box>
            <Box
              sx={theme => ({
                flex: '1 0 0',
                marginLeft: 0,
                width: '50%',
                [theme.breakpoints.down('sm')]: {
                  marginLeft: 0,
                  width: '100%',
                },
              })}
            >
              <Stack
                direction="column"
                alignItems="top"
                sx={theme => ({
                  width: '100%',
                  marginLeft: 1,
                  [theme.breakpoints.down('sm')]: {
                    marginLeft: 0,
                  },
                })}
              >
                <TripDetails trip={data.userTrip} />
                <TripItinerary
                  itinerary={itinerary}
                  startDate={data.userTrip.startDate}
                />
              </Stack>
            </Box>
          </Stack>
        </RouteContent>
      </Container>
      {/* This gets user trips on page load so they can easilly add any item to the trip */}
      <AuthorizedApolloProvider>
        <AuthorisedUserTrips />
      </AuthorizedApolloProvider>
    </RoutePage>
  );
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Trip } from '../generated/user_graphql';

export interface TripState {
  trip: Trip | null;
  userTrips: Trip[] | null;
  loading: boolean;
  errors: string;
  tripName: string;
  tripDescription: string;
}

const initialState: TripState = {
  trip: null,
  userTrips: null,
  loading: false,
  errors: '',
  tripName: '',
  tripDescription: '',
};

const tripSlice = createSlice({
  name: 'trip',
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },

    setErrors: (state, { payload }: PayloadAction<string>) => {
      state.errors = payload;
    },

    setTrip: (state, { payload }: PayloadAction<Trip>) => {
      state.trip = payload;
    },

    setUserTrips: (state, { payload }: PayloadAction<Trip[]>) => {
      state.userTrips = payload;
    },

    setTripName: (state, { payload }: PayloadAction<string>) => {
      state.tripName = payload;
    },

    setTripDescription: (state, { payload }: PayloadAction<string>) => {
      state.tripDescription = payload;
    },
  },
});

export const {
  setLoading,
  setErrors,
  setTrip,
  setUserTrips,
  setTripName,
  setTripDescription,
} = tripSlice.actions;

export const tripSliceReducer = tripSlice.reducer;

export const tripsSelector = (state: { userTrip: TripState }) => state.userTrip;

// export const getTrip = (): AppThunk => {
//   return async dispatch => {
//     dispatch(setLoading(true));
//     try {
//       const baseURL: string = 'https://api.nasa.gov/planetary/apod';
//       // your apiKey should ideally be in a .env file
//       const apiKey = 'LjSjB7YgUOxi3boRxn5SRRv5SIDkt91KIyauEo6Z';

//       const res = await axios.get(
//         `${baseURL}?api_key=${apiKey}&start_date=2020-05-07&end_date=2020-05-09`,
//       );

//       dispatch(setLoading(false));
//       dispatch(setTrip(res.data));
//     } catch (error) {
//       dispatch(setErrors(error));
//       dispatch(setLoading(false));
//     }
//   };
// };

export function formatSecondsToTime(seconds) {
  // Convert seconds to a number in case a string or decimal is passed
  const totalSeconds = Number(seconds);

  if (isNaN(totalSeconds) || totalSeconds < 0) {
    return 'Invalid input';
  }

  // Calculate hours and minutes
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.round((totalSeconds % 3600) / 60);

  // Build the formatted output
  let formattedTime = '';

  if (hours > 0) {
    formattedTime += `${hours} hour${hours !== 1 ? 's' : ''}`;
  }

  if (minutes > 0) {
    if (hours > 0) {
      formattedTime += ` `;
    }
    formattedTime += `${minutes} minute${minutes !== 1 ? 's' : ''}`;
  }

  // Handle edge case when totalSeconds is less than 60 and no hours or minutes were calculated
  if (hours === 0 && minutes === 0) {
    formattedTime = `0 minutes`;
  }

  return formattedTime;
}

import { Box } from '@mui/material';
import React from 'react';

import {
  DeleteTextButton,
  LinkButton,
  PrimaryButton,
  ProminentButton,
  SecondaryButton,
} from './Buttons';
import { Loader, LoaderCentred, LoadingDots } from './Loader';
import { Body1, TitleH2, TitleH3, TitleH4, TitleH5 } from './Typography';

export function ComponentsPreview() {
  return (
    <Box m={2}>
      Buttons
      <Box
        width="100%"
        display="flex"
        flexDirection="row"
        padding={2}
        // justifyContent="flex-start"
        // alignItems="flex-start"
      >
        <PrimaryButton>Primary button</PrimaryButton>
        <SecondaryButton>Secondary button</SecondaryButton>
      </Box>
      <Box width="100%" display="flex" flexDirection="row" padding={2}>
        <ProminentButton>Prominent button</ProminentButton>
      </Box>
      <Box width="100%" display="flex" flexDirection="row" padding={2}>
        <LinkButton>Link button</LinkButton>
      </Box>
      <Box width="100%" display="flex" flexDirection="row" padding={2}>
        <DeleteTextButton>Delete button</DeleteTextButton>
      </Box>
      <TitleH2>Title H2</TitleH2>
      <TitleH3>Title H3</TitleH3>
      <TitleH4>Title H4</TitleH4>
      <TitleH5>Title H5</TitleH5>
      <br />
      Body 1:
      <Body1>
        Curabitur lobortis magna eget sagittis maximus. Integer eget sem arcu.
        Aenean sed ipsum et dolor aliquam vehicula non id orci. Cras placerat eu
        mauris sed molestie. Nam sit amet eros non neque tristique vestibulum
        finibus ut diam. Nunc efficitur ac justo eget venenatis. Vestibulum
        ultricies vestibulum lectus non fringilla.{' '}
      </Body1>
      <br />
      Loader
      <Box width="100%" display="flex" flexDirection="row">
        <Loader />
      </Box>
      <br />
      Loader centred
      <Box width="100%" display="flex" flexDirection="row">
        <LoaderCentred />
      </Box>
      Loading dots
      <Box width="100%" display="flex" flexDirection="row">
        <LoadingDots />
      </Box>
    </Box>
  );
}

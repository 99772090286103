import { useMutation } from '@apollo/client';
import InfoIcon from '@mui/icons-material/Info'; // Import the Info icon
import {
  Box,
  TextField,
  Stack,
  InputAdornment,
  IconButton,
  Tabs,
  Tab,
  Divider,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  setAccommodationName,
  accommodationsSelector,
  setAccommodationId,
} from '../../../../store/AccommodationSlice';
import { setDays } from '../../../../store/DaySlice';
import { setErrorModalOpen, setErrorTitle } from '../../../../store/ErrorSlice';
import {
  setStepDuration,
  setStepId,
  setStepTitle,
  stepsSelector,
  setAddStepModalOpen,
} from '../../../../store/StepSlice';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../../theme-components/Buttons';
import { Loader } from '../../../../theme-components/Loader';
import { MUTATION_ADD_ACCOMMODATION_TO_STEP } from '../../../gql-user/addAccommodationToStep';
import { MUTATION_ADD_PLACE } from '../../../gql-user/addPlacesMutation';
import { MUTATION_ADD_STEP_TO_DAY } from '../../../gql-user/addStepToDay';
import { StyledFormControl } from '../Styles';

import { generateTransportTitle } from './helpers';
import {
  CenteredModal,
  MediumModalPaper,
  ModalDescription,
  ModalTitle,
} from '../../../styling/modal';

export default function AddStep(props: { dayId: string; isManual: boolean }) {
  const dispatch = useDispatch();
  const [placeUrl, setPlaceUrl] = useState('');
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isValidUrl, setIsValidUrl] = useState(true);

  const validateUrl = url => {
    const validUrlPatterns = [
      'https://maps.app.goo.gl/',
      'https://www.google.com/maps',
    ];

    // Check if the entered URL starts with any of the valid patterns
    return validUrlPatterns.some(pattern => url.startsWith(pattern));
  };

  const handleChange = e => {
    const newUrl = e.target.value;
    setPlaceUrl(newUrl);

    // Validate the URL and update the validation state
    setIsValidUrl(validateUrl(newUrl));
  };

  const [createPlace, { loading: loadingPlace }] = useMutation(
    MUTATION_ADD_PLACE,
    {
      variables: {
        url: placeUrl,
        day_id: props.dayId,
      },
    },
  );

  const { stepDuration, stepType, stepTitle } = useSelector(stepsSelector);
  const { accommodationName } = useSelector(accommodationsSelector);

  const [addStep, { loading }] = useMutation(MUTATION_ADD_STEP_TO_DAY, {
    variables: {
      day_id: props.dayId,
      type: stepType,
      duration: stepDuration,
      title: stepTitle,
    },
  });

  const [addAccommodation, { loading: accommodationLoading }] = useMutation(
    MUTATION_ADD_ACCOMMODATION_TO_STEP,
  );

  return (
    <>
      {!props.isManual ? (
        <Stack direction="column">
          <StyledFormControl>
            <TextField
              label="Google maps place shared URL"
              fullWidth={true}
              variant="outlined"
              onChange={handleChange}
              value={placeUrl}
              error={!isValidUrl}
              helperText={
                !isValidUrl &&
                'URL must start with https://maps.app.goo.gl/ or https://www.google.com/maps'
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleOpen}
                      aria-label="show share instructions"
                    >
                      <InfoIcon />
                    </IconButton>
                    <ShareInstructionModal
                      open={open}
                      handleClose={handleClose}
                    />
                  </InputAdornment>
                ),
              }}
            />
            <Box
              sx={{
                marginTop: 2,
                display: 'flex',
                justifyContent: 'flex-end', // Aligns items to the right
              }}
            >
              <PrimaryButton
                variant="contained"
                color="primary"
                disabled={loadingPlace || placeUrl === ''}
                onClick={async () => {
                  gtag('event', 'add-place');
                  const res = await createPlace();
                  if (
                    res &&
                    res.data &&
                    res.data.createUserPlace &&
                    res.data.createUserPlace.days
                  ) {
                    gtag('event', 'place-added');

                    dispatch(setDays(res.data.createUserPlace.days));
                    dispatch(setAddStepModalOpen(false));
                  } else {
                    gtag('event', 'error-add-place');
                    dispatch(setErrorTitle('Error adding place'));
                    dispatch(setErrorModalOpen(true));
                  }
                }}
              >
                {loadingPlace ? <Loader /> : <>{'Add to itinerary'}</>}
              </PrimaryButton>
            </Box>
          </StyledFormControl>
        </Stack>
      ) : (
        <Box>
          <Stack direction="column">
            {stepType && (
              <>
                {stepType === 'CATEGORY_PLACE' && (
                  <StyledFormControl>
                    <TextField
                      label="Place name"
                      name="title"
                      fullWidth={true}
                      variant="outlined"
                      onChange={e => {
                        dispatch(setStepTitle(e.target.value));
                        if (e.target.value === '') {
                          dispatch(setStepTitle(''));
                        }
                      }}
                    />
                  </StyledFormControl>
                )}
                {stepType === 'CATEGORY_FOOD_DRINK' && (
                  <StyledFormControl>
                    <TextField
                      label="Place name"
                      name="title"
                      fullWidth={true}
                      variant="outlined"
                      onChange={e => {
                        dispatch(setStepTitle(e.target.value));
                        if (e.target.value === '') {
                          dispatch(setStepTitle(''));
                        }
                      }}
                    />
                  </StyledFormControl>
                )}
                {stepType === 'CATEGORY_ACTIVITY' && (
                  <StyledFormControl>
                    <TextField
                      label="Activity name"
                      name="title"
                      fullWidth={true}
                      variant="outlined"
                      onChange={e => {
                        dispatch(setStepTitle(e.target.value));
                        if (e.target.value === '') {
                          dispatch(setStepTitle(''));
                        }
                      }}
                    />
                  </StyledFormControl>
                )}
                {stepType === 'CATEGORY_ACCOMMODATION' && (
                  <StyledFormControl>
                    <TextField
                      label="Accommodation name"
                      name="naem"
                      fullWidth={true}
                      variant="outlined"
                      onChange={e => {
                        dispatch(setStepTitle(e.target.value));
                        dispatch(setAccommodationName(e.target.value));
                        if (e.target.value === '') {
                          dispatch(setStepTitle(''));
                          dispatch(setAccommodationName(''));
                        }
                      }}
                    />
                  </StyledFormControl>
                )}
                {stepType === 'CATEGORY_TRANSPORT' && (
                  <StyledFormControl>
                    <TextField
                      label="Transport title"
                      name="naem"
                      fullWidth={true}
                      variant="outlined"
                      onChange={e => {
                        dispatch(setStepTitle(e.target.value));
                        if (e.target.value === '') {
                          dispatch(setStepTitle(''));
                          dispatch(setAccommodationName(''));
                        }
                      }}
                      sx={{ marginBottom: 1 }}
                    />
                    <TextField
                      label="Duration (in minutes)"
                      name="duration"
                      fullWidth={true}
                      type="number"
                      variant="outlined"
                      onChange={e => {
                        dispatch(setStepDuration(parseInt(e.target.value)));
                        // const stepTitle: string = "";
                        const stepTitle: string = generateTransportTitle(
                          stepType,
                          parseInt(e.target.value),
                        );
                        dispatch(setStepTitle(stepTitle));

                        if (isNaN(parseInt(e.target.value))) {
                          dispatch(setStepDuration(0));
                        }
                      }}
                    />
                  </StyledFormControl>
                )}
              </>
            )}
          </Stack>
          <Box
            sx={{
              marginTop: 2,
              display: 'flex',
              justifyContent: 'flex-end', // Aligns items to the right
            }}
          >
            <PrimaryButton
              disabled={
                loading || accommodationLoading || !stepType || !stepTitle
              }
              onClick={async () => {
                gtag('event', 'add-step');
                let lastStepId: string | null = null;
                try {
                  const res = await addStep();
                  if (
                    res &&
                    res.data &&
                    res.data.createUserStep &&
                    res.data.createUserStep.days
                  ) {
                    gtag('event', 'step-added');
                    dispatch(setDays(res.data.createUserStep.days));
                    res.data.createUserStep.days.forEach(day => {
                      if (day && day.id === props.dayId) {
                        // Check if the day has steps and get the last one
                        if (day.steps && day.steps.length > 0) {
                          const lastStep = day.steps[day.steps.length - 1]; // Get the last step
                          lastStepId = lastStep.id;
                          if (lastStepId != null) {
                            dispatch(setStepId(lastStepId));
                          }
                        }
                      }
                    });
                  }
                } catch (e) {
                  gtag('event', 'error-add-step');
                  dispatch(setErrorTitle('Error adding'));
                  dispatch(setErrorModalOpen(true));
                }

                if (
                  stepType === 'CATEGORY_ACCOMMODATION' &&
                  lastStepId != null
                ) {
                  try {
                    const res = await addAccommodation({
                      variables: {
                        step_id: lastStepId,
                        name: accommodationName,
                      },
                    });
                    if (
                      res &&
                      res.data &&
                      res.data.createUserAccommodation &&
                      res.data.createUserAccommodation.days
                    ) {
                      gtag('event', 'accommodation-added');
                      dispatch(setDays(res.data.createUserAccommodation.days));
                      res.data.createUserAccommodation.days.forEach(day => {
                        if (day && day.id === props.dayId) {
                          if (day.steps && day.steps.length > 0) {
                            day.steps.forEach(step => {
                              if (lastStepId === step.id) {
                                if (
                                  step.accommodations &&
                                  step.accommodations.length > 0
                                ) {
                                  dispatch(
                                    setAccommodationId(
                                      step.accommodations[0].id,
                                    ),
                                  );
                                }
                              }
                            });
                          }
                        }
                      });
                    }
                  } catch (e) {
                    gtag('event', 'error-add-accommodation');
                    dispatch(setErrorTitle('Error adding accommodation'));
                    dispatch(setErrorModalOpen(true));
                  }
                }
              }}
            >
              {loading || accommodationLoading ? <Loader /> : 'Next'}
            </PrimaryButton>
          </Box>
        </Box>
      )}
    </>
  );
}

export function ShareInstructionModal({ open, handleClose }) {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    const disableScroll = e => e.preventDefault();
    if (open) {
      // Lock body scroll
      document.body.style.overflow = 'hidden';
      document.addEventListener('touchmove', disableScroll, {
        passive: false,
      });
    } else {
      // Unlock body scroll
      document.body.style.overflow = '';
      document.removeEventListener('touchmove', disableScroll);
    }

    // Cleanup function to restore scroll when component unmounts or modal closes
    return () => {
      document.body.style.overflow = '';
      document.removeEventListener('touchmove', disableScroll);
    };
  }, [open]);
  return (
    <CenteredModal
      open={open}
      onClose={handleClose}
      aria-labelledby="share-instruction-title"
      aria-describedby="share-instruction-description"
    >
      <MediumModalPaper>
        <ModalTitle>How to Share from Google Maps</ModalTitle>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          variant="fullWidth"
          sx={{ mb: 2 }}
        >
          <Tab label="Single place import" />
          <Tab label="Bulk import" />
        </Tabs>
        <Divider sx={{ mb: 2 }} />
        {selectedTab === 0 && (
          <>
            <ModalDescription>
              To quickly add any place from Google Maps, just click on{' '}
              <strong>"Share"</strong> button and copy the URL. Then just paste
              it to rooutie.
            </ModalDescription>
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
              <img
                src="/assets/image/google-share.png"
                alt="Share button on Google Maps"
                style={{
                  maxWidth: '80%',
                  borderRadius: 8,
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                }}
              />
            </Box>
          </>
        )}
        {selectedTab === 1 && (
          <Stack direction="row" mt={3}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                marginRight: 2,
              }}
            >
              <img
                src="/assets/image/google-share-list.png"
                alt="Share button on Google Maps"
                style={{
                  maxWidth: '90%',
                  borderRadius: 8,
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                }}
              />
            </Box>
            <ModalDescription id="share-instruction-description" sx={{ mt: 2 }}>
              To quickly add your saved places from Google Maps, just click on{' '}
              <strong>"Share {'->'} Send link to view"</strong> button and copy
              the URL. Then just paste it to rooutie.
            </ModalDescription>
          </Stack>
        )}
        <Box
          sx={{
            marginTop: 2,
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <SecondaryButton
            onClick={handleClose}
            variant="contained"
            color="primary"
            sx={{ mt: 3 }}
          >
            Got it
          </SecondaryButton>
        </Box>
      </MediumModalPaper>
      {/* </Box> */}
    </CenteredModal>
  );
}

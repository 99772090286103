import * as React from 'react';

function Passport(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512.001 512.001"
      width="1em"
      height="1em"
      {...props}
    >
      <path d="M256 341.334c12.221 0 29.409-33.047 31.573-85.333h-63.146c2.164 52.286 19.352 85.333 31.573 85.333zM161.078 234.667h41.807c1.181-29.056 7.182-57.952 17.599-78.342-32.169 12.884-55.394 42.679-59.406 78.342zM291.516 156.325c10.417 20.391 16.418 49.286 17.599 78.342h41.807c-4.012-35.663-27.237-65.458-59.406-78.342zM256 149.334c-12.221 0-29.409 33.047-31.573 85.333h63.146c-2.164-52.286-19.351-85.333-31.573-85.333zM161.078 256.001c4.012 35.663 27.237 65.458 59.406 78.342-10.417-20.391-16.418-49.286-17.599-78.342h-41.807z" />
      <path d="M405.334 64.001H384V41.932c0-26.637-25.125-47.461-52.198-40.617L104.233 64.247C81.853 65.538 64 83.969 64 106.667v362.667C64 492.865 83.136 512 106.667 512h298.667C428.865 512 448 492.865 448 469.334V106.667c0-23.531-19.135-42.666-42.666-42.666zM337.281 21.93c12.448-3.177 25.385 6.906 25.385 20.001V64H185.164l152.117-42.07zm-27.947 426.071H192a10.66 10.66 0 01-10.667-10.667A10.66 10.66 0 01192 426.667h117.333A10.66 10.66 0 01320 437.334a10.66 10.66 0 01-10.666 10.667zM352 405.344H149.334a10.66 10.66 0 01-10.667-10.667 10.66 10.66 0 0110.667-10.667H352a10.66 10.66 0 0110.667 10.667A10.66 10.66 0 01352 405.344zm-96-42.677c-64.698 0-117.333-52.635-117.333-117.333S191.302 128.001 256 128.001s117.333 52.635 117.333 117.333S320.698 362.667 256 362.667z" />
      <path d="M291.516 334.343c32.169-12.884 55.395-42.68 59.406-78.342h-41.807c-1.181 29.056-7.182 57.951-17.599 78.342z" />
    </svg>
  );
}

export default Passport;

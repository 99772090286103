// import { Theme, Typography } from '@mui/material';
// import { makeStyles } from '@material-ui/core';
// import React, { ReactNode } from 'react';
// import { useTheme } from '@mui/material/styles';

// export const useStyles = makeStyles(theme => ({
//   h1: {
//     fontWeight: 700,
//     fontSize: 40,
//     marginTop: 20,
//     marginBottom: 10,
//     [theme.breakpoints.down('sm')]: {
//       marginBottom: 5,
//       marginTop: 10,
//       fontSize: 30,
//     },
//   },
//   h2: {
//     fontWeight: 600,
//     fontSize: 30,
//     [theme.breakpoints.down('sm')]: {
//       marginBottom: 5,
//       fontSize: 26,
//     },
//   },
//   h3: {
//     fontWeight: 600,
//     fontSize: 24,
//     color: '#14142B',
//     [theme.breakpoints.down('sm')]: {
//       fontSize: 21,
//     },
//   },
//   h4: {
//     fontWeight: 600,
//     fontSize: 20,
//     color: '#14142B',
//     [theme.breakpoints.down('sm')]: {
//       fontSize: 18,
//       marginBottom: 5,
//     },
//   },
//   h5: {
//     fontWeight: 600,
//     fontSize: 18,
//     color: '#393745',
//     [theme.breakpoints.down('sm')]: {
//       fontSize: 16,
//       marginBottom: 5,
//     },
//   },
//   body2: {
//     fontWeight: 500,
//     fontSize: 18,
//     color: theme.palette.secondary.dark,
//     [theme.breakpoints.down('sm')]: {
//       fontSize: 16,
//     },
//   },
//   body1: {
//     fontWeight: 400,
//     fontSize: 18,
//     color: '#636363',
//     [theme.breakpoints.down('sm')]: {
//       fontSize: 16,
//     },
//   },

//   helpText: {
//     fontWeight: 500,
//     fontSize: 14,
//     color: '#636363',
//     [theme.breakpoints.down('sm')]: {
//       fontSize: 14,
//     },
//   },
// }));

// export function TitleH1(props: { children: ReactNode }) {
//   const classes = useStyles();
//   return <Typography className={classes.h1}>{props.children}</Typography>;
// }

// export function TitleH2(props: { children: ReactNode }) {
//   const classes = useStyles();
//   return <Typography className={classes.h2}>{props.children}</Typography>;
// }

// export function TitleH3(props: { children: ReactNode }) {
//   const classes = useStyles();
//   return <Typography className={classes.h3}>{props.children}</Typography>;
// }

// export function TitleH4(props: { children: ReactNode }) {
//   const classes = useStyles();
//   return <Typography className={classes.h4}>{props.children}</Typography>;
// }

// export function TitleH5(props: { children: ReactNode }) {
//   const classes = useStyles();
//   return <Typography className={classes.h5}>{props.children}</Typography>;
// }

// export function Body1(props: { children: ReactNode }) {
//   const classes = useStyles();
//   return <Typography className={classes.body1}>{props.children}</Typography>;
// }

// export function Body2(props: { children: ReactNode }) {
//   const classes = useStyles();
//   return <Typography className={classes.body2}>{props.children}</Typography>;
// }

// export function HelpText(props: { children: ReactNode }) {
//   const classes = useStyles();
//   return <Typography className={classes.helpText}>{props.children}</Typography>;
// }
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

// Define styled components with MUI v5
export const TitleH1 = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: 40,
  // marginTop: 20,
  // marginBottom: 10,
  [theme.breakpoints.down('sm')]: {
    // marginBottom: 5,
    // marginTop: 10,
    fontSize: 30,
  },
}));

export const TitleH2 = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: 30,
  [theme.breakpoints.down('sm')]: {
    // marginBottom: 5,
    fontSize: 26,
    width: '85%',
  },
}));

export const TitleH3 = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: 24,
  color: '#14142B',
  [theme.breakpoints.down('sm')]: {
    fontSize: 21,
  },
}));

export const TitleH4 = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: 20,
  color: '#14142B',
  overflow: 'hidden', // Hide overflow content
  textOverflow: 'ellipsis', // Show ellipsis when content overflows
  whiteSpace: 'nowrap', // Prevent text from wrapping to a new line
  // maxWidth: '100%', // Ensure the component does not exceed the width of its container
  boxSizing: 'border-box', // Include padding and border in the element's total width and height
  flexGrow: 1,
  paddingRight: 1,
  [theme.breakpoints.down('lg')]: {
    fontSize: 18,
    // maxWidth: '480px', // Adjust maxWidth for smaller screens if needed
  },
  [theme.breakpoints.down('md')]: {
    fontSize: 18,
    // maxWidth: '480px', // Adjust maxWidth for smaller screens if needed
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: 18,
    // maxWidth: '320px', // Adjust maxWidth for smaller screens if needed
  },
}));

export const TitleH5 = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: 18,
  color: '#393745',
  [theme.breakpoints.down('sm')]: {
    fontSize: 16,
    // marginBottom: 5,
  },
}));

export const Body1 = styled(Box)(({ theme }) => ({
  fontWeight: 400,
  fontSize: 18,
  color: '#636363',
  [theme.breakpoints.down('sm')]: {
    fontSize: 16,
  },
}));

export const Body2 = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: 18,
  color: theme.palette.secondary.dark,
  [theme.breakpoints.down('sm')]: {
    fontSize: 16,
  },
}));

export const SubHeader = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: 16,
  color: theme.palette.secondary.dark,
  [theme.breakpoints.down('sm')]: {
    fontSize: 14,
  },
}));

export const HelpText = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: 14,
  color: '#636363',
  [theme.breakpoints.down('sm')]: {
    fontSize: 14,
  },
}));

import { Box } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';

import {
  setAddStepModalOpen,
  stepsSelector,
} from '../../../../store/StepSlice';
import { tripsSelector } from '../../../../store/TripSlice';
import { PrimaryButton } from '../../../../theme-components/Buttons';
import { VerticalFlex } from '../../../helpers/flex';
import UploadImage from '../../../Upload';

export default function AddPhotosAndAdditionalInfoToStep() {
  const dispatch = useDispatch();

  const { stepId } = useSelector(stepsSelector);
  const { trip } = useSelector(tripsSelector);

  return (
    <>
      <VerticalFlex>
        <UploadImage tripId={trip ? trip.id : ''} stepId={stepId} main={true} />
        <Box
          sx={{
            marginTop: 2,
            display: 'flex',
            justifyContent: 'flex-end', // Aligns items to the right
          }}
        >
          <VerticalFlex>
            <PrimaryButton
              onClick={() => {
                gtag('event', 'finish-adding-images');
                dispatch(setAddStepModalOpen(false));
              }}
            >
              Done
            </PrimaryButton>
          </VerticalFlex>
        </Box>
      </VerticalFlex>
    </>
  );
}

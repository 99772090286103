import { Box } from '@mui/material';
import Fade from '@mui/material/Fade';
import { useSelector, useDispatch } from 'react-redux';

import {
  errorsSelector,
  resetError,
  setErrorModalOpen,
} from '../store/ErrorSlice';
import { PrimaryButton } from '../theme-components/Buttons';

import {
  CenteredModal,
  ModalDescription,
  ModalTitle,
  SmallModalPaper,
} from './styling/modal';

export default function ErrorModal() {
  const { errorModalOpen, errorTitle } = useSelector(errorsSelector);
  const dispatch = useDispatch();

  const handleClose = () => {
    resetError();
  };

  return (
    <CenteredModal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={errorModalOpen}
      onClose={() => handleClose()}
      closeAfterTransition
    >
      <Fade in={errorModalOpen}>
        <SmallModalPaper>
          <ModalTitle>{errorTitle}</ModalTitle>
          <ModalDescription>
            Please try again later. If error still ocures get in touch with us.
          </ModalDescription>
          <Box
            sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
            mt={2}
          >
            <PrimaryButton
              onClick={() => {
                dispatch(setErrorModalOpen(false));
              }}
            >
              Close
            </PrimaryButton>
          </Box>
        </SmallModalPaper>
      </Fade>
    </CenteredModal>
  );
}

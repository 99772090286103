import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import LanguageIcon from '@mui/icons-material/Language';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import Box from '@mui/material/Box';
import { createStyles, makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';

import { Country } from '../../generated/user_graphql';
import { LightButton } from '../../theme-components/Buttons';
import Passport from '../icons/Passport';
import { TripSidebar } from '../trip-details/TripSidebar';

const useStyles = makeStyles(theme =>
  createStyles({
    largeIcon: {
      width: 30,
      height: 30,
      marginRight: 10,
      color: '#4D4B66',
      [theme.breakpoints.down('sm')]: {
        width: 25,
        height: 25,
      },
    },
    moreInfoBox: {
      padding: 10,
      // minWidth: 500,
      display: 'flex',
      justifyContent: 'center',
      //justifyContent: 'space-between',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    buttonBox: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(2),
    },
  }),
);

export function CountryInfoLinks(props: { country: Country }) {
  const navigate = useNavigate();
  const classes = useStyles();

  function onShowSeasons() {
    gtag('event', 'public-country-seasons');
    navigate('?modal=seasons');
  }

  function onShowTips() {
    gtag('event', 'public-country-tips');
    navigate('?modal=tips');
  }

  function onShowVaccinations() {
    gtag('event', 'public-country-health');
    navigate('?modal=vaccinations');
  }

  function onShowCountriesDetails() {
    gtag('event', 'public-country-details');
    navigate('?modal=countries_details');
  }
  function onShowVisas() {
    gtag('event', 'public-country-visas');
    navigate('?modal=visas');
  }

  let showAboutCountry = false;
  let showCountryVaccination = false;
  let showCountryVisa = false;
  let showCountryTips = false;
  let showCountrySeasons = false;
  let country: Country | null = null;

  if (props.country) {
    country = props.country;

    if (country.name && country.description) {
      showAboutCountry = true;
    }

    if (country.seasons && country.seasons.length > 0) {
      showCountrySeasons = true;
    }

    if (country.recommendations && country.recommendations.length > 0) {
      country.recommendations.forEach(recommendation => {
        if (
          recommendation!.type === 'RECOMMENDATION_VACCINATION' ||
          recommendation!.type === 'RECOMMENDATION_HEALTH'
        ) {
          showCountryVaccination = true;
        }

        if (recommendation!.type === 'RECOMMENDATION_VISAS') {
          showCountryVisa = true;
        }

        if (
          recommendation!.type !== 'RECOMMENDATION_VISAS' &&
          recommendation!.type !== 'RECOMMENDATION_VACCINATION' &&
          recommendation!.type !== 'RECOMMENDATION_HEALTH'
        ) {
          showCountryTips = true;
        }
      });
    }
  }

  return (
    <>
      <Box className={classes.moreInfoBox}>
        {showAboutCountry && (
          <Box className={classes.buttonBox}>
            <LightButton onClick={onShowCountriesDetails}>
              <LanguageIcon className={classes.largeIcon} />
              {'About ' + country!.name}
            </LightButton>
          </Box>
        )}
        {showCountryTips && (
          <Box className={classes.buttonBox}>
            <LightButton onClick={onShowTips}>
              <AssignmentTurnedInIcon className={classes.largeIcon} />
              Tips before you go
            </LightButton>
          </Box>
        )}
        {showCountryVisa && (
          <Box className={classes.buttonBox}>
            <LightButton onClick={onShowVisas}>
              <Passport fill="#4D4B66" className={classes.largeIcon} />
              Visas
            </LightButton>
          </Box>
        )}
        {showCountryVaccination && (
          <Box className={classes.buttonBox}>
            <LightButton onClick={onShowVaccinations}>
              <LocalHospitalIcon className={classes.largeIcon} />
              Health
            </LightButton>
          </Box>
        )}
        {showCountrySeasons && (
          <Box className={classes.buttonBox}>
            <LightButton onClick={onShowSeasons}>
              <WbSunnyIcon className={classes.largeIcon} />
              When to go
            </LightButton>
          </Box>
        )}
      </Box>
      {country && <TripSidebar countries={[country]} />}
    </>
  );
}

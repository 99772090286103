import { Divider, Box } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import _ from 'lodash';
import { Fragment } from 'react';

import { Country } from '../../generated/public_graphql';
import { NumberToMonth } from '../../utils/helpers';
import { HorizontalFlex, VerticalFlex } from '../helpers/flex';

import {
  SideBarDescription,
  SideBarHeader,
  SideBarIcon,
  SideBarSubHeader,
  SideBarSubSubHeader,
  SideBarTitleDivider,
} from './TripSidebar';

const useStyles = makeStyles(theme =>
  createStyles({
    month: {
      marginRight: 5,
      fontWeight: 600,
      fontSize: 18,
      [theme.breakpoints.down('sm')]: {
        fontSize: 18,
      },
    },
    seasonBox: {
      marginBottom: 0,
    },
    divider: {
      marginTop: 10,
      marginBottom: 20,
    },
    inSetBox: {
      paddingLeft: 15,
    },
    monthCopy: {
      marginRight: 5,
      fontWeight: 500,
      fontSize: 18,
      [theme.breakpoints.down('sm')]: {
        fontSize: 18,
      },
    },
  }),
);

interface Props {
  readonly countries: [Country];
}

export function TripSeasons(props: Props) {
  const classes = useStyles();

  // For now we will support only one country per
  // trip untill we have designs to show multiple
  const country = props.countries[0];

  let showRegionSeasons = false;
  if (country && country.seasons) {
    country.seasons.forEach(season => {
      if (
        season &&
        season.countryRegionName &&
        season.countryRegionName !== ''
      ) {
        return (showRegionSeasons = true);
      }
    });
  }

  const seasonsByRegion = _.groupBy(country.seasons, 'countryRegionName');

  return (
    <VerticalFlex>
      <HorizontalFlex>
        <SideBarIcon $iconType={'WEATHER'} />
        <SideBarHeader>{'Seasons'}</SideBarHeader>
      </HorizontalFlex>
      <SideBarTitleDivider />

      {/* 
          This shows regions seasons
          */}
      {country &&
        showRegionSeasons &&
        Object.keys(seasonsByRegion).map(function (countryRegionName, i) {
          return (
            <Fragment key={'region' + i}>
              <Box className={classes.seasonBox}>
                {countryRegionName !== 'null' && countryRegionName !== '' && (
                  <>
                    <SideBarSubHeader>
                      {countryRegionName + ' seasons'}
                    </SideBarSubHeader>
                    <Box className={classes.inSetBox}>
                      {seasonsByRegion[countryRegionName].map(
                        function (season, i) {
                          return (
                            <Fragment key={'season' + i}>
                              {season && (
                                <>
                                  <SideBarSubSubHeader>
                                    {season.name}
                                  </SideBarSubSubHeader>
                                  {season.startMonth &&
                                    season.endMonth &&
                                    season.minTemperature &&
                                    season.maxTemperature && (
                                      <SeasonDetails
                                        startMonth={season.startMonth}
                                        endMonth={season.endMonth}
                                        minTemperature={season.minTemperature}
                                        maxTemperature={season.maxTemperature}
                                        description={season.description}
                                      />
                                    )}

                                  <Divider className={classes.divider} />
                                </>
                              )}
                            </Fragment>
                          );
                        },
                      )}
                    </Box>
                  </>
                )}
              </Box>
            </Fragment>
          );
        })}
      {/* 
            This shows season without any region as last in the list if there is at 
            least one season assigned to country region
            */}
      {showRegionSeasons &&
        Object.keys(seasonsByRegion).map(function (countryRegionName, i) {
          return (
            <Fragment key={'region' + i}>
              <Box className={classes.seasonBox}>
                {countryRegionName === '' ||
                  (countryRegionName === 'null' && (
                    <>
                      <SideBarSubHeader>General seasons</SideBarSubHeader>
                      <Box className={classes.inSetBox}>
                        {seasonsByRegion[countryRegionName].map(
                          function (season, i) {
                            return (
                              <Fragment key={'season' + i}>
                                {season && (
                                  <>
                                    <SideBarSubSubHeader>
                                      {season.name}
                                    </SideBarSubSubHeader>
                                    {season.startMonth &&
                                      season.endMonth &&
                                      season.minTemperature &&
                                      season.maxTemperature && (
                                        <SeasonDetails
                                          startMonth={season.startMonth}
                                          endMonth={season.endMonth}
                                          minTemperature={season.minTemperature}
                                          maxTemperature={season.maxTemperature}
                                          description={season.description}
                                        />
                                      )}

                                    <Divider className={classes.divider} />
                                  </>
                                )}
                              </Fragment>
                            );
                          },
                        )}
                      </Box>
                    </>
                  ))}
              </Box>
            </Fragment>
          );
        })}
      {/* 
            This shows seasons if seasons are not assigned to country region
            leaving off country region header
            */}
      {!showRegionSeasons &&
        Object.keys(seasonsByRegion).map(function (countryRegionName, i) {
          return seasonsByRegion[countryRegionName].map(function (season, i) {
            return (
              <Fragment key={'season' + i}>
                {season && (
                  <>
                    <SideBarSubHeader>{season.name}</SideBarSubHeader>
                    {season.startMonth &&
                      season.endMonth &&
                      season.minTemperature &&
                      season.maxTemperature && (
                        <SeasonDetails
                          startMonth={season.startMonth}
                          endMonth={season.endMonth}
                          minTemperature={season.minTemperature}
                          maxTemperature={season.maxTemperature}
                          description={season.description}
                        />
                      )}
                    <Divider className={classes.divider} />
                  </>
                )}
              </Fragment>
            );
          });
        })}
    </VerticalFlex>
  );
}

function SeasonDetails(props: {
  startMonth: number;
  endMonth: number;
  minTemperature: number;
  maxTemperature: number;
  description: string | null | undefined;
}) {
  const classes = useStyles();
  return (
    <VerticalFlex>
      <Box mb={2}>
        <HorizontalFlex>
          <Box className={classes.monthCopy}>{'Runs from'}</Box>
          <Box className={classes.month}>{NumberToMonth(props.startMonth)}</Box>
          <Box className={classes.monthCopy}>{'until'}</Box>
          <Box className={classes.month}>{NumberToMonth(props.endMonth)}</Box>
        </HorizontalFlex>
        <HorizontalFlex>
          <Box className={classes.monthCopy}>{'Minimum temperature'}</Box>
          <Box className={classes.month}>{props.minTemperature + '°C'}</Box>
        </HorizontalFlex>
        <HorizontalFlex>
          <Box className={classes.monthCopy}>{'Maximum temperature'}</Box>
          <Box className={classes.month}>{props.maxTemperature + '°C'}</Box>
        </HorizontalFlex>
      </Box>
      {props.description && (
        <SideBarDescription>{props.description}</SideBarDescription>
      )}
    </VerticalFlex>
  );
}

import { useMutation, useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import DateRangeTwoToneIcon from '@mui/icons-material/DateRangeTwoTone';
import Favorite from '@mui/icons-material/Favorite';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import { Divider, Typography, Stack, IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AuthorizedApolloProvider from '../../AuthorizedApolloProvider';
import { Trip, UserPublicInfo } from '../../generated/public_graphql';
import { setUser, userSelector } from '../../store/UserSlice';
import { LoaderCentred } from '../../theme-components/Loader';
import { Body1, TitleH2 } from '../../theme-components/Typography';
import { QUERY_GET_SIDEBAR_COUNTRIES } from '../gql-public/sideBarQuery';
import { QUERY_GET_USER_SIDEBAR_COUNTRIES } from '../gql-user/sideBarQuery';
import { MUTATION_FOLLOW_USER } from '../gql-user/user';
import { RenderHtmlFromResponse } from '../RenderHtmlFromResponse';

import CopyTrip from './CopyTrip';
import { TripCountryBox } from './TripCountryBox';

export function TripDetails(props: { trip: Trip }) {
  let isPreview = false;

  if (window.location.href.indexOf('preview') > -1) {
    isPreview = true;
  }

  console.log('isPreview', isPreview);

  const {
    data: countriesData,
    error: countriesError,
    loading: countriesLoading,
  } = useQuery(
    isPreview ? QUERY_GET_USER_SIDEBAR_COUNTRIES : QUERY_GET_SIDEBAR_COUNTRIES,
    {
      variables: { trip_id: props.trip.id },
    },
  );

  return (
    <Box
      sx={theme => ({
        width: '100%',
        paddingLeft: 1,
      })}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          width: '100%',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <TitleH2>{props.trip.name}</TitleH2>

        <AuthorizedApolloProvider>
          <CopyTrip tripId={props.trip.id} />
        </AuthorizedApolloProvider>
      </Stack>

      {props.trip.userDetails && props.trip.userDetails.nickName && (
        <AuthorizedApolloProvider>
          <Follow tripUser={props.trip.userDetails} />
        </AuthorizedApolloProvider>
      )}

      <Stack
        direction="row"
        alignItems="center"
        sx={theme => ({
          width: '100%',
          marginBottom: 1,
        })}
      >
        <DateRangeTwoToneIcon
          sx={theme => ({
            marginRight: 1,
            width: 25,
            height: 25,
            color: theme.palette.icon.main,
            [theme.breakpoints.down('sm')]: {
              width: 20,
              height: 20,
            },
          })}
        />
        {props.trip && (
          <Typography
            sx={theme => ({
              fontWeight: 500,
              fontSize: 18,
              color: theme.palette.secondary.main,
              [theme.breakpoints.down('sm')]: {
                fontSize: 16,
              },
            })}
          >
            {props.trip.length && (
              <>
                {props.trip!.length + ' days itinerary'}
                {' ('}
              </>
            )}

            {props.trip &&
              props.trip.itineraryCountries &&
              props.trip.itineraryCountries.length > 0 &&
              props.trip.itineraryCountries.map((country, index) => (
                <React.Fragment key={index}>
                  {country && (
                    <>
                      {country.countryName}
                      {props.trip.itineraryCountries &&
                        index !== props.trip.itineraryCountries.length - 1 && (
                          <>{', '}</>
                        )}
                    </>
                  )}
                </React.Fragment>
              ))}
            {props.trip.length && <>{')'}</>}
          </Typography>
        )}
      </Stack>
      <Box>
        <Divider />
      </Box>
      {props.trip.description && (
        <Box>
          <Body1>
            <RenderHtmlFromResponse content={props.trip.description} />
          </Body1>
        </Box>
      )}

      <Box mb={2}>
        <Divider />
      </Box>
      {countriesLoading && <LoaderCentred />}
      {!countriesLoading && !countriesError && !isPreview && (
        <TripCountryBox
          trip={isPreview ? countriesData.userTrip : countriesData.trip}
        />
      )}
    </Box>
  );
}

export function Follow(props: { tripUser: UserPublicInfo }) {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const [isFollowing, setIsFollowing] = useState(false);
  const dispatch = useDispatch();

  const { myUser } = useSelector(userSelector);

  const [followUser, { loading, data }] = useMutation(MUTATION_FOLLOW_USER, {
    variables: {
      following_user_id: props.tripUser.id,
      follow: !isFollowing,
    },
  });

  useEffect(() => {
    if (myUser) {
      const following =
        myUser.following &&
        myUser.following.some(follower => follower!.id === props.tripUser.id);
      if (following === true || following === false) {
        setIsFollowing(following);
      }
    }
    if (data && data.followUser) {
      dispatch(setUser(data.followUser)); // Dispatch the updated user info to the store
    }
  }, [myUser, data, dispatch]);

  return (
    <>
      {props.tripUser && props.tripUser.nickName && (
        <Stack direction="row" alignItems="center" mt={-1}>
          <Typography
            variant="body1"
            color="textPrimary"
            sx={{ fontWeight: 600 }}
          >
            @{props.tripUser.nickName}
          </Typography>
          <IconButton
            size="small"
            color="error"
            onClick={async () => {
              if (isAuthenticated) {
                try {
                  gtag('event', 'follow-user');

                  await followUser();
                } catch (e) {
                  // TODO handle error
                }
              } else {
                gtag('event', 'follow-user-login');
                loginWithRedirect({
                  appState: { targetUrl: window.location.pathname },
                });
              }
            }}
          >
            {isFollowing ? <Favorite /> : <FavoriteBorder />}
          </IconButton>
        </Stack>
      )}
    </>
  );
}

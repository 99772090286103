import * as React from 'react';

function WeatherIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      height="1em"
      viewBox="0 0 512 512"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={297.841} cy={154.837} fill="#fef48b" r={92.778} />
      <path
        d="M322.836 244.2a92.86 92.86 0 01-25 3.41c-51.24 0-92.77-41.53-92.77-92.77s41.53-92.78 92.77-92.78c8.66 0 17.05 1.19 25 3.41-39.09 10.91-67.77 46.79-67.77 89.37s28.68 78.45 67.77 89.36z"
        fill="#fef056"
      />
      <path
        d="M271.336 68.61v175.15c-38.33-11.39-66.27-46.89-66.27-88.92 0-42.01 27.92-77.5 66.22-88.92.04.89.05 1.79.05 2.69z"
        fill="#fee97d"
      />
      <path
        d="M271.336 68.61v175.15c-38.33-11.39-66.27-46.89-66.27-88.92 0-42.01 27.92-77.5 66.22-88.92.04.89.05 1.79.05 2.69z"
        fill="#fef056"
      />
      <path
        d="M251.341 270.996V68.614c0-33.137-26.863-60-60-60s-60 26.863-60 60v202.382c-38.97 21.458-65.223 63.164-64.568 110.947.92 67.091 55.602 121.831 122.692 122.818 69.663 1.025 126.456-55.138 126.456-124.566 0-47.05-26.086-88.003-64.58-109.199z"
        fill="#ecf6fe"
      />
      <path
        d="M201.406 504.37c-3.93.32-7.91.45-11.94.39-67.09-.99-121.77-55.73-122.69-122.82-.66-47.78 25.59-89.49 64.56-110.94V68.61c0-16.57 6.72-31.57 17.58-42.42 10.85-10.86 25.85-17.58 42.42-17.58 3.41 0 6.75.28 10 .84-12.56 2.1-23.8 8.11-32.42 16.74-10.86 10.85-17.58 25.85-17.58 42.42V271c-38.97 21.45-65.22 63.16-64.56 110.94.88 64.38 51.27 117.39 114.63 122.43z"
        fill="#dbebfc"
      />
      <path
        d="M211.341 298.024V68.614c0-11.046-8.954-20-20-20s-20 8.954-20 20v229.41c-36.99 9.005-64.545 42.38-64.58 82.093-.04 44.715 35.315 82.149 79.966 84.533 48.723 2.602 89.194-36.29 89.194-84.457 0-39.746-27.567-73.158-64.58-82.169z"
        fill="#fd8087"
      />
      <path
        d="M201.296 464.18c-4.75.57-9.62.73-14.57.47-44.65-2.38-80.01-39.82-79.97-84.53.04-39.72 27.59-73.09 64.58-82.1V68.61c0-5.52 2.24-10.52 5.86-14.14s8.62-5.86 14.14-5.86c3.65 0 7.06.97 10 2.69-1.52.87-2.91 1.94-4.14 3.17a19.938 19.938 0 00-5.86 14.14v229.41c-36.99 9.01-64.54 42.38-64.58 82.1-.04 42.88 32.49 79.07 74.54 84.06z"
        fill="#fe646f"
      />
      <path d="M297.794 37.182a7.492 7.492 0 007.492-7.492V7.492c0-4.138-3.354-7.492-7.492-7.492s-7.492 3.354-7.492 7.492V29.69a7.492 7.492 0 007.492 7.492zM366.91 259.674a7.492 7.492 0 00-12.976 7.492l11.099 19.224c3.034 3.792 6.445 4.706 10.234 2.742a7.492 7.492 0 002.742-10.234zM429.238 222.184l-19.224-11.099a7.492 7.492 0 00-7.492 12.976l19.224 11.099c3.789 1.964 7.2 1.05 10.234-2.742a7.49 7.49 0 00-2.742-10.234zM445.248 147.453H423.05c-4.138 0-7.492 3.354-7.492 7.492s3.354 7.492 7.492 7.492h22.198c4.138 0 7.492-3.354 7.492-7.492s-3.354-7.492-7.492-7.492zM410.015 98.806l19.224-11.099a7.492 7.492 0 00-7.492-12.976L402.523 85.83a7.492 7.492 0 00-2.742 10.234c1.388 2.403 6.329 4.936 10.234 2.742zM356.675 52.959c3.789 1.964 7.2 1.05 10.234-2.742l11.099-19.224a7.492 7.492 0 00-12.976-7.492l-11.099 19.224a7.492 7.492 0 002.742 10.234z" />
      <path d="M297.79 54.776a100.288 100.288 0 00-39.242 8c-3.064-34.361-31.999-61.393-67.144-61.393-36.442 0-67.428 30.904-67.428 67.428v197.831C83.43 290.759 58.83 334.516 59.485 381.909c.96 70.01 59.915 129.044 129.939 130.077 60.898.913 116.669-42.58 130.486-101.899a7.493 7.493 0 00-14.594-3.398c-12.233 52.519-61.801 91.094-115.671 90.316-62.651-.924-114.319-52.648-115.179-115.301-.599-43.336 22.627-83.246 60.613-104.155a7.492 7.492 0 003.88-6.563V68.811c0-28.605 23.839-52.444 52.444-52.444 28.917 0 52.444 23.526 52.444 52.444v33.894c0 4.138 3.354 7.492 7.492 7.492s7.492-3.354 7.492-7.492v-23.52a85.169 85.169 0 0138.958-9.425c46.973 0 85.188 38.215 85.188 85.189 0 46.968-38.215 85.179-85.188 85.179a85.163 85.163 0 01-38.958-9.425V135.35c0-4.138-3.354-7.492-7.492-7.492s-7.492 3.354-7.492 7.492v135.635a7.495 7.495 0 003.88 6.564c35.959 19.791 59.162 57.514 60.555 98.449a7.494 7.494 0 007.742 7.233 7.492 7.492 0 007.233-7.743c-.813-23.859-8.438-47.044-21.243-66.901a7.482 7.482 0 003.27-6.188V280.2c0-4.138-3.354-7.492-7.492-7.492s-7.492 3.354-7.492 7.492v12.793c-9.002-10.321-19.596-19.278-31.47-26.35v-19.408a100.3 100.3 0 0038.958 7.876c55.235 0 100.172-44.933 100.172-100.163.002-55.235-44.935-100.172-100.17-100.172z" />
      <path d="M173.199 305.255a7.492 7.492 0 005.718-7.279v-111.98c0-4.138-3.354-7.492-7.492-7.492s-7.492 3.354-7.492 7.492v106.288c-38.231 11.925-64.47 47.18-64.511 87.698-.045 49.765 42.38 92.063 92.048 92.063 50.054-.002 91.914-41.899 91.914-91.987 0-40.551-26.24-75.837-64.51-87.775V68.811c0-15.147-12.324-27.471-27.471-27.471-16.74 1.574-25.897 10.731-27.471 27.471v84.54c0 4.138 3.354 7.492 7.492 7.492s7.492-3.354 7.492-7.492v-84.54c.717-7.607 4.879-11.77 12.487-12.487 6.885 0 12.487 5.601 12.487 12.487v229.165a7.493 7.493 0 005.718 7.279c34.615 8.434 58.792 39.194 58.792 74.803 0 43.221-37.98 79.181-81.203 76.889-40.855-2.178-72.828-35.979-72.791-76.95.036-35.584 24.212-66.319 58.793-74.742z" />
      <g>
        <path d="M96.76 107.333h-30c-4.143 0-7.5-3.358-7.5-7.5s3.357-7.5 7.5-7.5h30c4.143 0 7.5 3.358 7.5 7.5s-3.357 7.5-7.5 7.5z" />
      </g>
      <g>
        <path d="M96.76 153.83h-30c-4.143 0-7.5-3.358-7.5-7.5s3.357-7.5 7.5-7.5h30c4.143 0 7.5 3.358 7.5 7.5s-3.357 7.5-7.5 7.5z" />
      </g>
      <g>
        <path d="M96.76 200.328h-30c-4.143 0-7.5-3.358-7.5-7.5s3.357-7.5 7.5-7.5h30c4.143 0 7.5 3.358 7.5 7.5s-3.357 7.5-7.5 7.5z" />
      </g>
      <g>
        <path d="M96.76 246.825h-30c-4.143 0-7.5-3.358-7.5-7.5s3.357-7.5 7.5-7.5h30c4.143 0 7.5 3.358 7.5 7.5s-3.357 7.5-7.5 7.5z" />
      </g>
    </svg>
  );
}

export default WeatherIcon;

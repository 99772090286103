import { Box } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

import React from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { VerticalFlex } from '../../helpers/flex';
import { DayStepDetails } from '../edit-trip/DayStepDetails';
import { Clone, Container, CreateTripStepTitle, Handle, Item } from './Styles';

export function DragabbleDaySteps(props: { day; collapsedSteps: boolean }) {
  return (
    <Droppable
      droppableId={'existingStepDayId_' + props.day.id}
      type="dayStep"
      isDropDisabled={true}
    >
      {(provided, snapshot) => (
        <Container
          ref={provided.innerRef}
          $isDraggingOver={snapshot.isDraggingOver}
        >
          {props.day.steps &&
            props.day.steps.map((step, index) => (
              <Box key={index}>
                {step && (
                  <Draggable
                    key={step.id.replace('_temp', '')}
                    draggableId={step.id.replace('_temp', '')}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <>
                        <Item
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          $isDragging={snapshot.isDragging}
                          style={provided.draggableProps.style}
                        >
                          <Box
                            display="flex"
                            width="100%"
                            flexDirection="column"
                          >
                            <Handle {...provided.dragHandleProps}>
                              <CreateTripStepTitle
                                step={step}
                                showEdit={false}
                              />
                            </Handle>
                            {!props.collapsedSteps && (
                              <Box>
                                <DayStepDetails step={step} />
                              </Box>
                            )}
                          </Box>
                        </Item>
                        {snapshot.isDragging && (
                          <>
                            <Clone>
                              <Item>
                                <VerticalFlex>
                                  <Handle>
                                    {/* <DragIndicatorIcon /> */}
                                    <CreateTripStepTitle
                                      step={step}
                                      showEdit={false}
                                    />
                                  </Handle>
                                  {!props.collapsedSteps && (
                                    <DayStepDetails step={step} />
                                  )}
                                </VerticalFlex>
                              </Item>
                            </Clone>
                          </>
                        )}
                      </>
                    )}
                  </Draggable>
                )}
              </Box>
            ))}
        </Container>
      )}
    </Droppable>
  );
}

import { Container } from '@mui/material';
import React from 'react';

import { RoutePage } from '../components/route';
import { ComponentsPreview } from '../theme-components/ComponentsPreview';

export default function ComponentsPreviewRoute() {
  return (
    <RoutePage>
      <Container>
        <ComponentsPreview />
      </Container>
    </RoutePage>
  );
}

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import EditLocationAltTwoToneIcon from '@mui/icons-material/EditLocationAltTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import MapTwoToneIcon from '@mui/icons-material/MapTwoTone';
import PhotoSizeSelectActualTwoToneIcon from '@mui/icons-material/PhotoSizeSelectActualTwoTone';
import { Box, Button } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { useEffect, useState } from 'react';

import { Step } from '../../../generated/user_graphql';
import { DeleteStepModal } from '../manage-trip-modals/DeleteStepModal';
import { EditImages } from '../manage-trip-modals/EditAddStepImages';
import { EditStepModal } from '../manage-trip-modals/EditStepModal';
import { LinkPlaceToStepModal } from '../manage-trip-modals/LinkPlaceToStepModal';
// import Confetti from 'react-confetti'; // Confetti animation

interface EditOrAddStepButtonProps {
  tripId: string;
  copy: string;
  step: Step;
}

export const ActionsStep: React.FC<EditOrAddStepButtonProps> = ({
  tripId,
  copy,
  step,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseModal = () => {
    handleClose();
  };

  const openMenu = Boolean(anchorEl);
  useEffect(() => {
    if (openMenu) {
      const handleTouchMove = () => {
        handleClose();
      };

      // Listen to touchmove events
      window.addEventListener('touchmove', handleTouchMove);

      // Cleanup the event listener when the menu is closed
      return () => {
        window.removeEventListener('touchmove', handleTouchMove);
      };
    }
  }, [openMenu]);

  return (
    <Box
      sx={theme => ({
        flexShrink: 0, // Prevent the button from shrinking
      })}
    >
      <Button
        variant="contained"
        // startIcon={
        //   <EditLocationAltTwoToneIcon
        //     sx={theme => ({
        //       height: 17,
        //       width: 17,
        //       pointerEvents: 'none',
        //     })}
        //   />
        // }
        endIcon={
          <ArrowDropDownIcon
            sx={theme => ({
              height: 17,
              width: 17,
              pointerEvents: 'none',
            })}
          />
        }
        onClick={handleClick}
        sx={theme => ({
          fontSize: 12,
          paddingTop: 0.5,
          paddingBottom: 0.5,
          paddingLeft: 1.75,
          paddingRight: 1.75,
          backgroundColor: '#36454F',
          borderRadius: 20,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          boxShadow: 'none', // Disable shadow
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: '#4A5D6F',
          },
        })}
      >
        {copy}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom', // Adjust vertical anchor
          horizontal: 'left', // Adjust horizontal anchor
        }}
        transformOrigin={{
          vertical: 'top', // Adjust vertical transform
          horizontal: 'left', // Adjust horizontal transform
        }}
      >
        <MenuItem>
          <ListItemIcon>
            <EditTwoToneIcon />
          </ListItemIcon>
          <EditStepModal step={step} handleCloseModal={handleCloseModal} />
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <PhotoSizeSelectActualTwoToneIcon />
          </ListItemIcon>
          <EditImages
            step={step}
            tripId={tripId}
            handleCloseModal={handleCloseModal}
          />
        </MenuItem>
        {!step.place && (
          <MenuItem>
            <ListItemIcon>
              <MapTwoToneIcon />
            </ListItemIcon>
            <LinkPlaceToStepModal
              stepId={step.id}
              handleCloseModal={handleCloseModal}
            />
          </MenuItem>
        )}

        <MenuItem>
          <ListItemIcon>
            <DeleteTwoToneIcon />
          </ListItemIcon>
          <DeleteStepModal
            stepId={step.id}
            handleCloseModal={handleCloseModal}
          />
        </MenuItem>
      </Menu>
    </Box>
  );
};

import { styled, FormControl } from '@mui/material';
import { makeStyles } from '@mui/styles';
// Rest of the code remains the same
export const useManageTripStyles = makeStyles(theme => ({
  alignRight: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  primaryButton: {
    marginTop: 20,
    fontWeight: 600,
    fontSize: 18,
    borderRadius: 40,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  orangeButton: {
    backgroundColor: '#F4B740',
    color: '#000000',
    fontWeight: 600,
    padding: 0,
    fontSize: 18,
    borderRadius: 40,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  secondaryButton: {
    marginTop: 20,
    fontWeight: 600,
    fontSize: 18,
    backgroundColor: '#ab8ffc',
    color: '#5e5e5e',
    borderRadius: 40,
    marginRight: 10,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  iconButton: {
    width: 25,
    height: 25,
    [theme.breakpoints.down('sm')]: {
      width: 20,
      height: 20,
    },
  },
  editIcon: {
    width: 20,
    height: 20,
    [theme.breakpoints.down('sm')]: {
      width: 15,
      height: 15,
    },
  },
  addTripCardTitle: {
    fontWeight: 600,
    fontSize: 20,
    marginTop: 5,
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
  },
  cardRoot: {
    borderRadius: 20,
    marginRight: 20,
    width: 300,
    [theme.breakpoints.down('sm')]: {
      width: 280,
    },
  },
  cardAddTripText: {
    color: theme.palette.primary.main,
  },
  tripImage: {
    borderBottomLeftRadius: 0,
    height: 150,
    [theme.breakpoints.down('sm')]: {
      height: 140,
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    marginLeft: 0,
    marginRight: 0,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  stepImageBox: {
    marginRight: 5,
    height: '100%',
    width: '50%',
  },
  topMenuTab: {
    width: '30%',
  },
  tab30: {
    width: '30%',
    fontWeight: 600,
    fontSize: 18,
    [theme.breakpoints.down('sm')]: {
      fontWeight: 500,
      fontSize: 16,
    },
  },
  tab50: {
    width: '50%',
    fontWeight: 600,
    fontSize: 18,
  },
  emailBox: {
    color: theme.palette.secondary.dark,
    fontSize: 18,
    fontWeight: 500,
    marginBottom: theme.spacing(0.5),
  },
  viewBox: {
    color: theme.palette.secondary.light,
    fontSize: 16,
    fontWeight: 400,
    marginBottom: theme.spacing(1),
  },
  divider: {
    marginBottom: theme.spacing(1),
  },
}));

export const StyledFormControl = styled(props => (
  <FormControl variant="outlined" {...props} />
))(({ theme }) => ({
  margin: theme.spacing(1),
  width: '100%',
  minWidth: 120,
  marginLeft: 0,
  marginRight: 0,
}));

import { useMutation } from '@apollo/client';
import Backdrop from '@material-ui/core/Backdrop';
import { Box, TextField } from '@mui/material';
import Fade from '@mui/material/Fade';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setErrorModalOpen, setErrorTitle } from '../../../store/ErrorSlice';
import { tripsSelector, setTripName } from '../../../store/TripSlice';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../theme-components/Buttons';
import { Loader } from '../../../theme-components/Loader';
import { MUTATION_UPDATE_USER_TRIP } from '../../gql-user/updateUserTrip';
import {
  ModalDescription,
  ModalTitle,
  MediumModalPaper,
  CenteredModal,
} from '../../styling/modal';

import { useManageTripStyles } from './Styles';

export default function EditTripNameModal(props: {
  name?: string;
  editTripId: string;
  handleCloseModal: () => void;
}) {
  const classes = useManageTripStyles();
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  const { tripName } = useSelector(tripsSelector);

  const [editTrip, { loading }] = useMutation(MUTATION_UPDATE_USER_TRIP, {
    variables: { name: tripName, trip_id: props.editTripId },
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    props.handleCloseModal();
  };

  useEffect(() => {
    const disableScroll = e => e.preventDefault();
    if (open) {
      // Lock body scroll
      document.body.style.overflow = 'hidden';
      document.addEventListener('touchmove', disableScroll, {
        passive: false,
      });
    } else {
      // Unlock body scroll
      document.body.style.overflow = '';
      document.removeEventListener('touchmove', disableScroll);
    }

    // Cleanup function to restore scroll when component unmounts or modal closes
    return () => {
      document.body.style.overflow = '';
      document.removeEventListener('touchmove', disableScroll);
    };
  }, [open]);

  return (
    <>
      <Box
        onClick={() => {
          gtag('event', 'edit-trip-name-icon');
          handleOpen();
        }}
        className={classes.iconButton}
      >
        Edit title
      </Box>
      <CenteredModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <MediumModalPaper>
            <ModalTitle>Edit trip name</ModalTitle>
            <ModalDescription>
              Short and descriptive name is always the best choice
            </ModalDescription>
            <TextField
              id="standard-basic"
              label="Trip name"
              name="name"
              defaultValue={tripName}
              fullWidth={true}
              variant="outlined"
              onChange={e => dispatch(setTripName(e.target.value))}
            />
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
              mt={2}
            >
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
                mt={2}
              >
                <Box>
                  <SecondaryButton
                    disabled={loading}
                    onClick={async () => {
                      gtag('event', 'publish-trip-close');
                      setOpen(false);
                      handleClose();
                    }}
                    sx={{ marginRight: 1 }}
                  >
                    Close
                  </SecondaryButton>
                  <PrimaryButton
                    disabled={loading}
                    onClick={async () => {
                      gtag('event', 'edit-trip-name-save');
                      try {
                        await editTrip();
                        handleClose();
                      } catch (e) {
                        gtag('event', 'error-edit-trip-name');
                        dispatch(setErrorTitle('Error saving trip name'));
                        dispatch(setErrorModalOpen(true));
                      }
                    }}
                  >
                    {loading ? <Loader /> : 'Save name'}
                  </PrimaryButton>
                </Box>
              </Box>
            </Box>
          </MediumModalPaper>
        </Fade>
      </CenteredModal>
    </>
  );
}

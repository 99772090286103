import { useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { Hidden } from '@material-ui/core';
import { Box } from '@mui/material';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { setLoading, setDays } from '../../store/DaySlice';
import { errorsSelector } from '../../store/ErrorSlice';
import {
  tripsSelector,
  setTrip,
  setTripDescription,
  setTripName,
} from '../../store/TripSlice';
import { LoaderFullScreen } from '../../theme-components/Loader';
import { Header } from '../AppWrapper';
import LoginButton from '../auth/LoginButton';
import ErrorModal from '../Error';
import { QUERY_USER_TRIP } from '../gql-user/userTrip';
import { VerticalFlex } from '../helpers/flex';
import { RoutePage, RouteTitle, RouteContent } from '../route';

import { DragAndDropUserItinerarySingleTrip } from './drag-and-drop/DragAndDropUserItinerarySingleTrip';
import { DragAndDropUserItineraryTwoTrips } from './drag-and-drop/DragAndDropUserItineraryTwoTrips';
import { TripsResults } from './TripsResults';

export function CreateUserTrip() {
  const dispatch = useDispatch();
  const { isAuthenticated, isLoading: loadingAuth } = useAuth0();
  const { userTripId } = useParams<{ userTripId: string }>();

  const params = new URLSearchParams(window.location.search);
  const selectedTripIdOnLoad: string | null = params.get('selected-trip');

  const { data, loading, error } = useQuery(QUERY_USER_TRIP, {
    variables: { trip_id: userTripId },
  });
  const { errorModalOpen } = useSelector(errorsSelector);
  const { trip } = useSelector(tripsSelector);

  React.useEffect(() => {
    if (data && data.userTrip) {
      dispatch(setTrip(data.userTrip));
      dispatch(setTripName(data.userTrip.name));
      dispatch(setTripDescription(data.userTrip.description));
      dispatch(setDays(data.userTrip.itinerary.days));
      // }
    }
  }, [data, dispatch]); // dependencies for useEffect

  // Handle loading states
  React.useEffect(() => {
    dispatch(setLoading(loading));
  }, [loading, dispatch]);

  if (loading || loadingAuth) {
    return <LoaderFullScreen />;
  }

  if (!isAuthenticated) {
    return <LoginButton />;
  }

  if (!trip && !loading && !loadingAuth) {
    return (
      <RoutePage>
        <RouteTitle>Trip</RouteTitle>
        <RouteContent>Trip not found</RouteContent>
      </RoutePage>
    );
  }

  return (
    <Box width="100%">
      {errorModalOpen && <ErrorModal />}
      <VerticalFlex>
        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          alignItems="center"
          mt={1}
        >
          <Box flexGrow={1}>
            <Header>Create your trip</Header>
          </Box>
          <Hidden only={['xs']}>
            {trip && (
              <Box justifyContent="flex-end" mt={2}>
                <TripsResults userTripId={trip.id} />
              </Box>
            )}
          </Hidden>
        </Box>
        {selectedTripIdOnLoad ? (
          <DragAndDropUserItineraryTwoTrips />
        ) : (
          <DragAndDropUserItinerarySingleTrip />
        )}
        {error && <>Error</>}
      </VerticalFlex>
    </Box>
  );
}

import { Box, Typography } from '@mui/material';
import { ReactNode } from 'react';

interface ChildProps {
  readonly children?: ReactNode;
}

export function RoutePage(props: ChildProps) {
  return <Box>{props.children}</Box>;
}

export function RouteTitle(props: ChildProps) {
  return (
    <Typography variant="h4" component="h1" gutterBottom>
      {props.children}
    </Typography>
  );
}

export function RouteContent(props: ChildProps) {
  return <Box>{props.children}</Box>;
}

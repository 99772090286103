// import { Button, ButtonProps, ButtonTypeMap, Theme } from '@mui/material';
// import { makeStyles } from '@material-ui/core';
// import React from 'react';

// export const useStyles = makeStyles(theme => ({
//   editLink: {
//     alignSelf: 'center',
//     fontWeight: 500,
//     fontSize: 16,
//     paddingTop: 5,
//     paddingBottom: 5,
//     paddingRight: 10,
//     paddingLeft: 10,
//     cursor: 'pointer',
//     borderRadius: 20,
//     backgroundColor: '#F5F5F5',
//     '&:hover, &:visited, &:link, &:active': {
//       textDecoration: 'none',
//     },
//     '&:hover': {
//       backgroundColor: '#eaeaea',
//     },
//     color: theme.palette.primary.main,
//     [theme.breakpoints.down('sm')]: {
//       fontSize: 14,
//     },
//   },
//   textLink: {
//     alignSelf: 'center',
//     fontWeight: 500,
//     fontSize: 16,
//     paddingTop: 5,
//     paddingBottom: 5,
//     paddingRight: 10,
//     paddingLeft: 0,
//     cursor: 'pointer',
//     borderRadius: 20,
//     backgroundColor: 'none',
//     '&:hover': {
//       backgroundColor: 'transparent',
//     },
//     color: theme.palette.primary.main,
//     [theme.breakpoints.down('sm')]: {
//       fontSize: 14,
//     },
//   },
//   prominentButton: {
//     alignSelf: 'center',
//     fontWeight: 600,
//     fontSize: 18,
//     paddingTop: 5,
//     paddingBottom: 5,
//     paddingRight: 15,
//     paddingLeft: 15,
//     cursor: 'pointer',
//     borderRadius: 30,
//     color: '#000000',
//     backgroundColor: '#F4B740',
//     '&:hover, &:visited, &:link, &:active': {
//       textDecoration: 'none',
//     },
//     '&:hover': {
//       backgroundColor: '#F6AF24',
//     },
//     [theme.breakpoints.down('sm')]: {
//       fontSize: 16,
//     },
//   },
//   prominentButtonWide: {
//     alignSelf: 'center',
//     fontWeight: 600,
//     width: 200,
//     fontSize: 18,
//     paddingTop: 7,
//     paddingBottom: 7,
//     paddingRight: 25,
//     paddingLeft: 25,
//     cursor: 'pointer',
//     borderRadius: 30,
//     color: '#000000',
//     backgroundColor: '#F4B740',
//     '&:hover, &:visited, &:link, &:active': {
//       textDecoration: 'none',
//     },
//     '&:hover': {
//       backgroundColor: '#F6AF24',
//     },
//     [theme.breakpoints.down('sm')]: {
//       fontSize: 16,
//     },
//   },
//   secondaryButton: {
//     alignSelf: 'center',
//     fontWeight: 600,
//     fontSize: 18,
//     paddingTop: 7,
//     paddingBottom: 7,
//     paddingRight: 25,
//     paddingLeft: 25,
//     cursor: 'pointer',
//     borderRadius: 40,
//     color: '#4E4B66',
//     backgroundColor: '#D6D9FC',
//     '&:hover, &:visited, &:link, &:active': {
//       textDecoration: 'none',
//     },
//     '&:hover': {
//       backgroundColor: '#B5B6FA',
//     },
//     [theme.breakpoints.down('sm')]: {
//       fontSize: 16,
//     },
//     [theme.breakpoints.down('sm')]: {
//       fontSize: 16,
//     },
//   },
//   textDelete: {
//     alignSelf: 'center',
//     fontWeight: 500,
//     fontSize: 16,
//     paddingTop: 5,
//     paddingBottom: 5,
//     paddingRight: 10,
//     paddingLeft: 0,
//     cursor: 'pointer',
//     color: theme.palette.error.main,
//     borderRadius: 20,
//     // backgroundColor: '#F5F5F5',
//     '&:hover, &:visited, &:link, &:active': {
//       textDecoration: 'none',
//     },
//     '&:hover': {
//       backgroundColor: 'transparent',
//     },
//     [theme.breakpoints.down('sm')]: {
//       fontSize: 14,
//     },
//   },
//   primaryButton: {
//     alignSelf: 'center',
//     fontWeight: 600,
//     fontSize: 18,
//     paddingTop: 7,
//     paddingBottom: 7,
//     paddingRight: 25,
//     paddingLeft: 25,
//     cursor: 'pointer',
//     borderRadius: 40,
//     color: '#FFFFFF',
//     backgroundColor: '#676BEE',
//     '&:hover, &:visited, &:link, &:active': {
//       textDecoration: 'none',
//     },
//     '&:hover': {
//       backgroundColor: '#4447B1',
//     },
//     '&:disabled': {
//       backgroundColor: '#D6D9FC',
//     },
//     [theme.breakpoints.down('sm')]: {
//       fontSize: 16,
//     },
//     // margin: 10,
//     [theme.breakpoints.down('sm')]: {
//       fontSize: 16,
//     },
//   },
//   lightButton: {
//     alignSelf: 'center',
//     minWidth: 100,
//     fontWeight: 600,
//     fontSize: 18,
//     paddingTop: 7,
//     paddingBottom: 7,
//     paddingRight: 25,
//     paddingLeft: 25,
//     cursor: 'pointer',
//     whiteSpace: 'nowrap',
//     borderRadius: 40,
//     color: '#6529B1',
//     backgroundColor: '#DEE0FC',
//     '&:hover, &:visited, &:link, &:active': {
//       textDecoration: 'none',
//     },
//     '&:hover': {
//       backgroundColor: '#B5B6FA',
//     },
//     '&:disabled': {
//       backgroundColor: '#D6D9FC',
//     },
//     [theme.breakpoints.down('sm')]: {
//       fontSize: 16,
//     },
//     // margin: 10,
//     [theme.breakpoints.down('sm')]: {
//       fontSize: 16,
//     },
//   },
//   primaryButtonWide: {
//     alignSelf: 'center',
//     width: 200,
//     fontWeight: 600,
//     fontSize: 18,
//     paddingTop: 7,
//     paddingBottom: 7,
//     paddingRight: 25,
//     paddingLeft: 25,
//     cursor: 'pointer',
//     borderRadius: 40,
//     color: '#FFFFFF',
//     backgroundColor: '#676BEE',
//     '&:hover, &:visited, &:link, &:active': {
//       textDecoration: 'none',
//     },
//     '&:hover': {
//       backgroundColor: '#4447B1',
//     },
//     '&:disabled': {
//       backgroundColor: '#D6D9FC',
//     },
//     [theme.breakpoints.down('sm')]: {
//       fontSize: 16,
//     },
//     // margin: 10,
//     [theme.breakpoints.down('sm')]: {
//       fontSize: 16,
//     },
//   },
// }));

// export const LinkButton = <
//   D extends React.ElementType = ButtonTypeMap['defaultComponent'],
//   P = Record<string, unknown>,
// >(
//   props: ButtonProps<D, P>,
// ) => {
//   const classes = useStyles();
//   return <Button className={classes.editLink} {...props}></Button>;
// };

// export const TextButton = <
//   D extends React.ElementType = ButtonTypeMap['defaultComponent'],
//   P = Record<string, unknown>,
// >(
//   props: ButtonProps<D, P>,
// ) => {
//   const classes = useStyles();
//   return <Button className={classes.textLink} {...props}></Button>;
// };
// export const DeleteTextButton = <
//   D extends React.ElementType = ButtonTypeMap['defaultComponent'],
//   P = Record<string, unknown>,
// >(
//   props: ButtonProps<D, P>,
// ) => {
//   const classes = useStyles();
//   return <Button className={classes.textDelete} {...props}></Button>;
// };

// export const ProminentButton = <
//   D extends React.ElementType = ButtonTypeMap['defaultComponent'],
//   P = Record<string, unknown>,
// >(
//   props: ButtonProps<D, P>,
// ) => {
//   const classes = useStyles();
//   return <Button className={classes.prominentButton} {...props}></Button>;
// };

// export const ProminentButtonWide = <
//   D extends React.ElementType = ButtonTypeMap['defaultComponent'],
//   P = Record<string, unknown>,
// >(
//   props: ButtonProps<D, P>,
// ) => {
//   const classes = useStyles();
//   return <Button className={classes.prominentButtonWide} {...props}></Button>;
// };

// export const SecondaryButton = <
//   D extends React.ElementType = ButtonTypeMap['defaultComponent'],
//   P = Record<string, unknown>,
// >(
//   props: ButtonProps<D, P>,
// ) => {
//   const classes = useStyles();
//   return <Button className={classes.secondaryButton} {...props}></Button>;
// };

// export const PrimaryButton = <
//   D extends React.ElementType = ButtonTypeMap['defaultComponent'],
//   P = Record<string, unknown>,
// >(
//   props: ButtonProps<D, P>,
// ) => {
//   const classes = useStyles();
//   return <Button className={classes.primaryButton} {...props}></Button>;
// };

// export const PrimaryButtonWide = <
//   D extends React.ElementType = ButtonTypeMap['defaultComponent'],
//   P = Record<string, unknown>,
// >(
//   props: ButtonProps<D, P>,
// ) => {
//   const classes = useStyles();
//   return <Button className={classes.primaryButtonWide} {...props}></Button>;
// };

// export const LightButton = <
//   D extends React.ElementType = ButtonTypeMap['defaultComponent'],
//   P = Record<string, unknown>,
// >(
//   props: ButtonProps<D, P>,
// ) => {
//   const classes = useStyles();
//   return <Button className={classes.lightButton} {...props}></Button>;
// };
// import { Button, ButtonProps, ButtonTypeMap } from '@mui/material';
// import { styled } from '@mui/material/styles';
// import React from 'react';

// /** Styled Components **/

// const StyledLinkButton = styled(Button)(({ theme }) => ({
//   alignSelf: 'center',
//   fontWeight: 500,
//   fontSize: 16,
//   padding: '5px 10px',
//   cursor: 'pointer',
//   borderRadius: 20,
//   backgroundColor: '#F5F5F5',
//   color: theme.palette.primary.main,
//   '&:hover, &:visited, &:link, &:active': {
//     textDecoration: 'none',
//     backgroundColor: '#eaeaea',
//   },
//   [theme.breakpoints.down('sm')]: {
//     fontSize: 14,
//   },
// }));

// const StyledTextButton = styled(Button)(({ theme }) => ({
//   alignSelf: 'center',
//   fontWeight: 500,
//   fontSize: 16,
//   padding: '5px 10px 5px 0',
//   cursor: 'pointer',
//   borderRadius: 20,
//   backgroundColor: 'transparent',
//   color: theme.palette.primary.main,
//   '&:hover': {
//     backgroundColor: 'transparent',
//   },
//   [theme.breakpoints.down('sm')]: {
//     fontSize: 14,
//   },
// }));

// const StyledDeleteTextButton = styled(Button)(({ theme }) => ({
//   alignSelf: 'center',
//   fontWeight: 500,
//   fontSize: 16,
//   padding: '5px 10px 5px 0',
//   cursor: 'pointer',
//   borderRadius: 20,
//   backgroundColor: 'transparent',
//   color: theme.palette.error.main,
//   '&:hover, &:visited, &:link, &:active': {
//     textDecoration: 'none',
//     backgroundColor: 'transparent',
//   },
//   [theme.breakpoints.down('sm')]: {
//     fontSize: 14,
//   },
// }));

// const StyledProminentButton = styled(Button)(({ theme }) => ({
//   alignSelf: 'center',
//   fontWeight: 600,
//   fontSize: 18,
//   padding: '5px 15px',
//   cursor: 'pointer',
//   borderRadius: 30,
//   backgroundColor: '#F4B740',
//   color: '#000000',
//   '&:hover, &:visited, &:link, &:active': {
//     textDecoration: 'none',
//     backgroundColor: '#F6AF24',
//   },
//   [theme.breakpoints.down('sm')]: {
//     fontSize: 16,
//   },
// }));

// const StyledProminentButtonWide = styled(Button)(({ theme }) => ({
//   alignSelf: 'center',
//   width: 200,
//   fontWeight: 600,
//   fontSize: 18,
//   padding: '7px 25px',
//   cursor: 'pointer',
//   borderRadius: 30,
//   backgroundColor: '#F4B740',
//   color: '#000000',
//   '&:hover, &:visited, &:link, &:active': {
//     textDecoration: 'none',
//     backgroundColor: '#F6AF24',
//   },
//   [theme.breakpoints.down('sm')]: {
//     fontSize: 16,
//   },
// }));

// const StyledSecondaryButton = styled(Button)(({ theme }) => ({
//   alignSelf: 'center',
//   fontWeight: 600,
//   fontSize: 18,
//   padding: '7px 25px',
//   cursor: 'pointer',
//   borderRadius: 40,
//   backgroundColor: '#D6D9FC',
//   color: '#4E4B66',
//   '&:hover, &:visited, &:link, &:active': {
//     textDecoration: 'none',
//     backgroundColor: '#B5B6FA',
//   },
//   [theme.breakpoints.down('sm')]: {
//     fontSize: 16,
//   },
// }));

// const StyledPrimaryButton = styled(Button)(({ theme }) => ({
//   alignSelf: 'center',
//   fontWeight: 600,
//   fontSize: 18,
//   padding: '7px 25px',
//   cursor: 'pointer',
//   borderRadius: 40,
//   backgroundColor: '#676BEE',
//   color: '#FFFFFF',
//   '&:hover, &:visited, &:link, &:active': {
//     textDecoration: 'none',
//     backgroundColor: '#4447B1',
//   },
//   '&:disabled': {
//     backgroundColor: '#D6D9FC',
//   },
//   [theme.breakpoints.down('sm')]: {
//     fontSize: 16,
//   },
// }));

// const StyledPrimaryButtonWide = styled(Button)(({ theme }) => ({
//   alignSelf: 'center',
//   width: 200,
//   fontWeight: 600,
//   fontSize: 18,
//   padding: '7px 25px',
//   cursor: 'pointer',
//   borderRadius: 40,
//   backgroundColor: '#676BEE',
//   color: '#FFFFFF',
//   '&:hover, &:visited, &:link, &:active': {
//     textDecoration: 'none',
//     backgroundColor: '#4447B1',
//   },
//   '&:disabled': {
//     backgroundColor: '#D6D9FC',
//   },
//   [theme.breakpoints.down('sm')]: {
//     fontSize: 16,
//   },
// }));

// const StyledLightButton = styled(Button)(({ theme }) => ({
//   alignSelf: 'center',
//   minWidth: 100,
//   fontWeight: 600,
//   fontSize: 18,
//   padding: '7px 25px',
//   cursor: 'pointer',
//   whiteSpace: 'nowrap',
//   borderRadius: 40,
//   backgroundColor: '#DEE0FC',
//   color: '#6529B1',
//   '&:hover, &:visited, &:link, &:active': {
//     textDecoration: 'none',
//     backgroundColor: '#B5B6FA',
//   },
//   '&:disabled': {
//     backgroundColor: '#D6D9FC',
//   },
//   [theme.breakpoints.down('sm')]: {
//     fontSize: 16,
//   },
// }));

// /** Exported Components **/

// export const LinkButton = <
//   D extends React.ElementType = ButtonTypeMap['defaultComponent'],
//   P = {},
// >(
//   props: ButtonProps<D, P>,
// ) => {
//   return <StyledLinkButton {...props} />;
// };

// export const TextButton = <
//   D extends React.ElementType = ButtonTypeMap['defaultComponent'],
//   P = {},
// >(
//   props: ButtonProps<D, P>,
// ) => {
//   return <StyledTextButton {...props} />;
// };

// export const DeleteTextButton = <
//   D extends React.ElementType = ButtonTypeMap['defaultComponent'],
//   P = {},
// >(
//   props: ButtonProps<D, P>,
// ) => {
//   return <StyledDeleteTextButton {...props} />;
// };

// export const ProminentButton = <
//   D extends React.ElementType = ButtonTypeMap['defaultComponent'],
//   P = {},
// >(
//   props: ButtonProps<D, P>,
// ) => {
//   return <StyledProminentButton {...props} />;
// };

// export const ProminentButtonWide = <
//   D extends React.ElementType = ButtonTypeMap['defaultComponent'],
//   P = {},
// >(
//   props: ButtonProps<D, P>,
// ) => {
//   return <StyledProminentButtonWide {...props} />;
// };

// export const SecondaryButton = <
//   D extends React.ElementType = ButtonTypeMap['defaultComponent'],
//   P = {},
// >(
//   props: ButtonProps<D, P>,
// ) => {
//   return <StyledSecondaryButton {...props} />;
// };

// export const PrimaryButton = <
//   D extends React.ElementType = ButtonTypeMap['defaultComponent'],
//   P = {},
// >(
//   props: ButtonProps<D, P>,
// ) => {
//   return <StyledPrimaryButton {...props} />;
// };

// export const PrimaryButtonWide = <
//   D extends React.ElementType = ButtonTypeMap['defaultComponent'],
//   P = {},
// >(
//   props: ButtonProps<D, P>,
// ) => {
//   return <StyledPrimaryButtonWide {...props} />;
// };

// export const LightButton = <
//   D extends React.ElementType = ButtonTypeMap['defaultComponent'],
//   P = {},
// >(
//   props: ButtonProps<D, P>,
// ) => {
//   return <StyledLightButton {...props} />;
// };
import { Button, ButtonProps, ButtonTypeMap } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

const EditLinkButton = styled(Button)(({ theme }) => ({
  alignSelf: 'center',
  fontWeight: 500,
  fontSize: 16,
  paddingTop: 5,
  paddingBottom: 5,
  paddingRight: 10,
  paddingLeft: 10,
  cursor: 'pointer',
  borderRadius: 20,
  backgroundColor: '#F5F5F5',
  '&:hover, &:visited, &:link, &:active': {
    textDecoration: 'none',
  },
  '&:hover': {
    backgroundColor: '#eaeaea',
  },
  color: theme.palette.primary.main,
  [theme.breakpoints.down('sm')]: {
    fontSize: 14,
  },
}));

// const TextLinkButton = styled(Button)(({ theme }) => ({
//   alignSelf: 'center',
//   fontWeight: 500,
//   fontSize: 16,
//   paddingTop: 5,
//   paddingBottom: 5,
//   paddingRight: 10,
//   paddingLeft: 0,
//   cursor: 'pointer',
//   borderRadius: 20,
//   backgroundColor: 'none',
//   '&:hover': {
//     backgroundColor: 'transparent',
//   },
//   color: theme.palette.primary.main,
//   [theme.breakpoints.down('sm')]: {
//     fontSize: 14,
//   },
// }));
interface TextLinkButtonProps extends ButtonProps {
  customColor?: string; // Use a different name for custom color
}

const TextLinkButton = styled(Button, {
  shouldForwardProp: prop => prop !== 'customColor', // Prevent customColor prop from being forwarded to the DOM
})<TextLinkButtonProps>(({ theme, customColor }) => ({
  alignSelf: 'center',
  fontWeight: 500,
  fontSize: 16,
  paddingTop: 5,
  paddingBottom: 5,
  paddingRight: 10,
  paddingLeft: 0,
  cursor: 'pointer',
  borderRadius: 20,
  backgroundColor: 'none',
  '&:hover': {
    backgroundColor: 'transparent',
  },
  color: customColor || theme.palette.primary.main, // Use custom color if provided
  [theme.breakpoints.down('sm')]: {
    fontSize: 14,
  },
}));

const DeleteTextButtonStyled = styled(Button)(({ theme }) => ({
  alignSelf: 'center',
  fontWeight: 500,
  fontSize: 16,
  paddingTop: 5,
  paddingBottom: 5,
  paddingRight: 10,
  paddingLeft: 0,
  cursor: 'pointer',
  color: theme.palette.error.main,
  borderRadius: 20,
  '&:hover, &:visited, &:link, &:active': {
    textDecoration: 'none',
  },
  '&:hover': {
    backgroundColor: 'transparent',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: 14,
  },
}));

const ProminentButtonStyled = styled(Button)(({ theme }) => ({
  alignSelf: 'center',
  fontWeight: 600,
  fontSize: 18,
  paddingTop: 5,
  paddingBottom: 5,
  paddingRight: 15,
  paddingLeft: 15,
  cursor: 'pointer',
  borderRadius: 30,
  color: '#000000',
  backgroundColor: '#F4B740',
  '&:hover, &:visited, &:link, &:active': {
    textDecoration: 'none',
  },
  '&:hover': {
    backgroundColor: '#F6AF24',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: 16,
  },
}));

const ProminentButtonWideStyled = styled(Button)(({ theme }) => ({
  // alignSelf: 'center',
  fontWeight: 600,
  width: 200,
  fontSize: 18,
  paddingTop: 7,
  paddingBottom: 7,
  paddingRight: 25,
  paddingLeft: 25,
  cursor: 'pointer',
  borderRadius: 30,
  color: '#000000',
  backgroundColor: '#F4B740',
  '&:hover, &:visited, &:link, &:active': {
    textDecoration: 'none',
  },
  '&:hover': {
    backgroundColor: '#F6AF24',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: 16,
  },
}));

const SecondaryButtonStyled = styled(Button)(({ theme }) => ({
  alignSelf: 'center',
  fontWeight: 600,
  fontSize: 18,
  paddingTop: 7,
  paddingBottom: 7,
  paddingRight: 25,
  paddingLeft: 25,
  cursor: 'pointer',
  borderRadius: 40,
  color: '#4E4B66',
  backgroundColor: '#D6D9FC',
  '&:hover, &:visited, &:link, &:active': {
    textDecoration: 'none',
  },
  '&:hover': {
    backgroundColor: '#B5B6FA',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: 16,
  },
}));

const PrimaryButtonStyled = styled(Button)(({ theme }) => ({
  alignSelf: 'center',
  fontWeight: 600,
  fontSize: 18,
  paddingTop: 7,
  paddingBottom: 7,
  paddingRight: 25,
  paddingLeft: 25,
  cursor: 'pointer',
  borderRadius: 40,
  color: '#FFFFFF',
  backgroundColor: '#676BEE',
  '&:hover, &:visited, &:link, &:active': {
    textDecoration: 'none',
  },
  '&:hover': {
    backgroundColor: '#4447B1',
  },
  '&:disabled': {
    backgroundColor: '#D6D9FC',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: 16,
  },
}));

const LightButtonStyled = styled(Button)(({ theme }) => ({
  alignSelf: 'center',
  minWidth: 100,
  fontWeight: 600,
  fontSize: 18,
  paddingTop: 7,
  paddingBottom: 7,
  paddingRight: 25,
  paddingLeft: 25,
  cursor: 'pointer',
  whiteSpace: 'nowrap',
  borderRadius: 40,
  color: '#6529B1',
  backgroundColor: '#DEE0FC',
  '&:hover, &:visited, &:link, &:active': {
    textDecoration: 'none',
  },
  '&:hover': {
    backgroundColor: '#B5B6FA',
  },
  '&:disabled': {
    backgroundColor: '#D6D9FC',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: 16,
  },
}));

const PrimaryButtonWideStyled = styled(Button)(({ theme }) => ({
  alignSelf: 'center',
  width: 200,
  fontWeight: 600,
  fontSize: 18,
  paddingTop: 7,
  paddingBottom: 7,
  paddingRight: 25,
  paddingLeft: 25,
  cursor: 'pointer',
  borderRadius: 40,
  color: '#FFFFFF',
  backgroundColor: '#676BEE',
  '&:hover, &:visited, &:link, &:active': {
    textDecoration: 'none',
  },
  '&:hover': {
    backgroundColor: '#4447B1',
  },
  '&:disabled': {
    backgroundColor: '#D6D9FC',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: 16,
  },
}));

// Export the components with the original constant names
export const LinkButton = <
  D extends React.ElementType = ButtonTypeMap['defaultComponent'],
  P = Record<string, unknown>,
>(
  props: ButtonProps<D, P>,
) => <EditLinkButton {...props} />;

// export const TextButton = <
//   D extends React.ElementType = ButtonTypeMap['defaultComponent'],
//   P = Record<string, unknown>,
// >(
//   props: ButtonProps<D, P>,
// ) => <TextLinkButton {...props} />;

export const TextButton = <
  D extends React.ElementType = ButtonTypeMap['defaultComponent'],
  P = Record<string, unknown>,
>({
  customColor, // Use customColor instead of color
  ...props
}: ButtonProps<D, P> & { customColor?: string }) => {
  return <TextLinkButton customColor={customColor} {...props} />;
};

export const DeleteTextButton = <
  D extends React.ElementType = ButtonTypeMap['defaultComponent'],
  P = Record<string, unknown>,
>(
  props: ButtonProps<D, P>,
) => <DeleteTextButtonStyled {...props} />;

export const ProminentButton = <
  D extends React.ElementType = ButtonTypeMap['defaultComponent'],
  P = Record<string, unknown>,
>(
  props: ButtonProps<D, P>,
) => <ProminentButtonStyled {...props} />;

export const ProminentButtonWide = <
  D extends React.ElementType = ButtonTypeMap['defaultComponent'],
  P = Record<string, unknown>,
>(
  props: ButtonProps<D, P>,
) => <ProminentButtonWideStyled {...props} />;

export const SecondaryButton = <
  D extends React.ElementType = ButtonTypeMap['defaultComponent'],
  P = Record<string, unknown>,
>(
  props: ButtonProps<D, P>,
) => <SecondaryButtonStyled {...props} />;

export const PrimaryButton = <
  D extends React.ElementType = ButtonTypeMap['defaultComponent'],
  P = Record<string, unknown>,
>(
  props: ButtonProps<D, P>,
) => <PrimaryButtonStyled {...props} />;

export const LightButton = <
  D extends React.ElementType = ButtonTypeMap['defaultComponent'],
  P = Record<string, unknown>,
>(
  props: ButtonProps<D, P>,
) => <LightButtonStyled {...props} />;

export const PrimaryButtonWide = <
  D extends React.ElementType = ButtonTypeMap['defaultComponent'],
  P = Record<string, unknown>,
>(
  props: ButtonProps<D, P>,
) => <PrimaryButtonWideStyled {...props} />;

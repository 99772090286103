import { useMutation, useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import CheckIcon from '@mui/icons-material/Check';
import PersonIcon from '@mui/icons-material/Person';
import StarIcon from '@mui/icons-material/Star';
import {
  InputAdornment,
  Stack,
  TextField,
  Card,
  Typography,
  Button,
  Avatar,
  IconButton,
  Box,
  Tooltip,
  Fade,
} from '@mui/material';
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { User } from '../../generated/user_graphql';
import { setUser, userSelector } from '../../store/UserSlice';
import { PrimaryButton } from '../../theme-components/Buttons';
import { Loader, LoaderFullScreen } from '../../theme-components/Loader';
import {
  isPublisher,
  returnFormatedDate,
  useUserRoles,
} from '../../utils/helpers';
import {
  MUTATION_UPDATE_USER,
  MUTATION_UPSERT_INSURANCE,
  QUERY_GET_USER,
} from '../gql-user/user';
import { CenteredModal, ModalTitle } from '../styling/modal';

import LogoutButton from './LogoutButton';

export function Profile() {
  const { isLoading } = useAuth0();
  const roles = useUserRoles();
  const dispatch = useDispatch();

  const { data } = useQuery<{ getUser: User }>(QUERY_GET_USER);

  useEffect(() => {
    if (data && data.getUser) {
      // setUserData(data.getUser);
      dispatch(setUser(data.getUser));
    }
  }, [data, dispatch]);

  if (isLoading) {
    return <LoaderFullScreen />;
  }

  return (
    <Box>
      {/* <HorizontalFlex>
        {user && user.email && (
          <Box className={classes.emailBox}>{user.email + '  '}</Box>
        )}
        <LogoutButton />
      </HorizontalFlex> */}
      <UserProfileCard />
      {/* {userData && userData.pasportExpiry && (
        <>Pasport expires: {userData.pasportExpiry}</>
      )}
      {userData && userData.pasportExpiry ? (
        <Box onClick={handleOpen}>Chnage pasport expiry</Box>
      ) : (
        <Box onClick={handleOpen}>Add pasport expiry</Box>
      )} */}
      {/* <TextField
        label="Nickname"
        variant="outlined"
        value={nickname}
        onChange={handleChange}
        fullWidth
      />
      <Button
        variant="contained"
        color="primary"
        onClick={async () => {
          try {
            await updateUser();
            handleClose();
            setNickName('');
            setPasportExpiry(null);
          } catch (e) {
            //TODO handle error
          }
        }}
        style={{ marginTop: 13 }}
      >
        Save
      </Button>
      {userData && userData.nickName && (
        <Typography variant="body1" style={{ marginTop: 16 }}>
          Saved Nickname: {userData.nickName}
        </Typography>
      )} */}
      {isPublisher(roles) && (
        <Stack direction="row" alignItems="center" spacing={0.5} mt={2}>
          <Typography>Thanks for adding all the trips, you are a </Typography>
          <StarIcon
            sx={theme => ({
              width: 20,
              height: 20,
              marginRight: 10,
              color: '#F7D469',
              [theme.breakpoints.down('sm')]: {
                width: 20,
                height: 20,
              },
            })}
          />
        </Stack>
      )}
    </Box>
  );
}

export default Profile;

type ModalContentType = 'passportExpiry' | 'insuranceExpiry' | null;

const UserProfileCard = () => {
  const [isEditingNickname, setIsEditingNickname] = useState(false);
  const [nickName, setNickName] = useState('');
  const [modalContent, setModalContent] = useState<ModalContentType>(null);
  const [pasportExpiry, setPasportExpiry] = useState<Date | null>(new Date());
  const [insuranceExpiry, setInsuranceExpiry] = useState<Date | null>(
    new Date(),
  );
  const [insuranceContactNo, setInsuranceContactNo] = useState('');
  const [insurancePolicyNo, setInsurancePolicyNo] = useState('');
  const [isEditingInsuranceExpiry, setIsEditingInsuranceExpiry] =
    useState(false);
  const [isEditingPolicyNumber, setIsEditingPolicyNumber] = useState(false);
  const [isEditingInsuranceContact, setIsEditingInsuranceContact] =
    useState(false);
  const [isEditingPassportExpiry, setIsEditingPassportExpiry] = useState(false);
  const { myUser } = useSelector(userSelector);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const disableScroll = e => e.preventDefault();
    if (open) {
      // Lock body scroll
      document.body.style.overflow = 'hidden';
      document.addEventListener('touchmove', disableScroll, {
        passive: false,
      });
    } else {
      // Unlock body scroll
      document.body.style.overflow = '';
      document.removeEventListener('touchmove', disableScroll);
    }

    // Cleanup function to restore scroll when component unmounts or modal closes
    return () => {
      document.body.style.overflow = '';
      document.removeEventListener('touchmove', disableScroll);
    };
  }, [open]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [updateUser, { loading: updateUserLoading, data: updateUserData }] =
    useMutation<{ updateUser: User }>(MUTATION_UPDATE_USER, {
      variables: {
        ...(nickName.trim() && { nickname: nickName.trim() }),
        ...(pasportExpiry && { pasport_expiry_date: pasportExpiry }),
      },
    });

  const [
    updateInsurance,
    { loading: updateInsuranceLoading, data: updateInsuranceData },
  ] = useMutation<{ updateUser: User }>(MUTATION_UPSERT_INSURANCE, {
    variables: {
      ...(insuranceExpiry && { insurance_expiry_date: insuranceExpiry }),
      ...(insuranceContactNo.trim() && {
        contact_number: insuranceContactNo.trim(),
      }),
      ...(insurancePolicyNo.trim() && {
        policy_number: insurancePolicyNo.trim(),
      }),
    },
  });

  useEffect(() => {
    if (updateUserData && updateUserData.updateUser) {
      // setUserData(updateUserData.updateUser);
      dispatch(setUser(updateUserData.updateUser));
    }

    if (updateInsuranceData && updateInsuranceData.updateUser) {
      // setUserData(updateUserData.updateUser);
      dispatch(setUser(updateInsuranceData.updateUser));
    }
  }, [updateUserData, updateInsuranceData, dispatch]);

  return (
    <>
      <Card
        sx={{
          maxWidth: '100%',
          margin: 'auto',
          mt: 2,
          boxShadow: 1, // Softer shadow for a more subtle effect
          p: 2,
          borderRadius: 2,
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            '@media (min-width:900px)': {
              flexDirection: 'row',
            },
          }}
        >
          {/* Left Section: User Profile */}
          <Box
            sx={{
              flex: 1,
              mb: { xs: 2, sm: 0 },
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              width: '100%',
              '@media (min-width:900px)': {
                borderRight: 1,
                borderColor: 'divider',
                pr: 2,
                mr: { xs: 0, sm: 2 },
                minWidth: '33%',
              },
            }}
          >
            <Tooltip title="Upload coming soon!">
              <Avatar
                alt="Profile Picture"
                sx={{ width: 80, height: 80, bgcolor: 'primary.main' }}
              >
                <PersonIcon sx={{ fontSize: 40 }} />
              </Avatar>
            </Tooltip>
            <Box
              sx={{
                flex: 1,
                p: 1,
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                maxWidth: '100%',
                '@media (min-width:900px)': {
                  mr: { xs: 0, sm: 2 },
                },
              }}
            >
              {/* User Email */}
              {myUser?.email && (
                <Typography
                  variant="h6"
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    width: '100%',
                    flexShrink: 1,
                    color: 'text.primary', // Use theme color for primary text
                  }}
                >
                  {myUser.email}
                </Typography>
              )}

              {/* User Nickname */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {isEditingNickname ? (
                  <TextField
                    variant="standard"
                    defaultValue={myUser?.nickName || ''}
                    label="Nickname"
                    onChange={e => setNickName(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            color="primary"
                            disabled={updateUserLoading}
                            onClick={async () => {
                              await updateUser();
                              setIsEditingNickname(false);
                            }}
                          >
                            {updateUserLoading ? <Loader /> : <CheckIcon />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    sx={{ color: 'text.secondary' }} // Softer text color for input
                  />
                ) : (
                  <Typography
                    variant="body1"
                    sx={{ mr: 1, color: 'text.primary' }}
                  >
                    {myUser?.nickName ? (
                      `@${myUser.nickName}`
                    ) : (
                      <Button
                        color="primary"
                        onClick={() => setIsEditingNickname(true)}
                      >
                        Add Nickname
                      </Button>
                    )}
                  </Typography>
                )}
                {myUser?.nickName && !isEditingNickname && (
                  <Box
                    sx={theme => ({
                      fontSize: 12,
                      color: theme.palette.primary.main,
                      cursor: 'pointer',
                      '&:hover': {
                        textDecoration: 'underline',
                      },
                    })}
                    onClick={() => setIsEditingNickname(true)}
                  >
                    Edit
                  </Box>
                )}
              </Box>

              {/* Logout Button */}
              <Box mt={2}>
                <LogoutButton />
              </Box>
            </Box>
          </Box>

          {/* Details Section */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              '@media (min-width:700px)': {
                flexDirection: 'row',
              },
              '@media (min-width:900px)': {
                width: '60%',
              },
            }}
          >
            {/* Important Info */}
            <Box
              sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                mb: { xs: 2, sm: 0 },
                '@media (min-width:900px)': {
                  borderRight: 1,
                  borderColor: 'divider',
                  pr: 2,
                  mr: { xs: 0, sm: 2 },
                },
              }}
            >
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ fontWeight: 600, color: 'text.secondary' }} // Softer color for section headers
              >
                Important info on hand
              </Typography>

              {/* Passport Expiry Date */}
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  variant="body2"
                  sx={{ mr: 1, fontWeight: 600, color: 'text.primary' }}
                >
                  Passport expiry:
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ mr: 1, color: 'text.secondary' }}
                >
                  {myUser?.pasportExpiry ? (
                    returnFormatedDate(myUser.pasportExpiry, 0)
                  ) : (
                    <Button
                      color="primary"
                      onClick={() => {
                        handleOpen();
                        setModalContent('passportExpiry');
                      }}
                    >
                      Add
                    </Button>
                  )}
                </Typography>
                {myUser?.pasportExpiry && (
                  <Box
                    sx={theme => ({
                      fontSize: 12,
                      color: theme.palette.primary.main,
                      cursor: 'pointer',
                      '&:hover': {
                        textDecoration: 'underline',
                      },
                    })}
                    onClick={() => {
                      handleOpen();
                      setModalContent('passportExpiry');
                    }}
                  >
                    Edit
                  </Box>
                )}
              </Box>

              {/* Insurance Expiry Date */}
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  variant="body2"
                  sx={{ mr: 1, fontWeight: 600, color: 'text.primary' }}
                >
                  Insurance expiry:
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ mr: 1, color: 'text.secondary' }}
                >
                  {myUser?.insurance?.expiry ? (
                    <Box
                      onClick={() => {
                        handleOpen();
                        setModalContent('insuranceExpiry');
                      }}
                    >
                      {returnFormatedDate(myUser.insurance.expiry, 0)}
                    </Box>
                  ) : (
                    <Button
                      color="primary"
                      onClick={() => {
                        handleOpen();
                        setModalContent('insuranceExpiry');
                      }}
                    >
                      Add
                    </Button>
                  )}
                </Typography>
                {myUser && myUser.insurance && myUser.insurance.expiry && (
                  <Box
                    sx={theme => ({
                      fontSize: 12,
                      color: theme.palette.primary.main,
                      cursor: 'pointer',
                      '&:hover': {
                        textDecoration: 'underline',
                      },
                    })}
                    onClick={() => {
                      handleOpen();
                      setModalContent('insuranceExpiry');
                    }}
                  >
                    Edit
                  </Box>
                )}
              </Box>

              {/* Insurance Contact No */}
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  variant="body2"
                  sx={{ mr: 1, fontWeight: 600, color: 'text.primary' }}
                >
                  Insurance contact No:
                </Typography>
                {isEditingInsuranceContact ? (
                  <TextField
                    variant="standard"
                    defaultValue={myUser?.insurance?.contactNumber || ''}
                    label="Contact No."
                    onChange={e => setInsuranceContactNo(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            color="primary"
                            disabled={updateInsuranceLoading}
                            onClick={async () => {
                              await updateInsurance();
                              setIsEditingInsuranceContact(false);
                            }}
                          >
                            {updateInsuranceLoading ? (
                              <Loader />
                            ) : (
                              <CheckIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    sx={{ color: 'text.secondary' }}
                  />
                ) : (
                  <Typography
                    variant="body2"
                    sx={{ mr: 1, color: 'text.secondary' }}
                  >
                    {myUser?.insurance?.contactNumber || (
                      <Button
                        color="primary"
                        onClick={() => setIsEditingInsuranceContact(true)}
                      >
                        Add
                      </Button>
                    )}
                  </Typography>
                )}
                {myUser?.insurance?.contactNumber &&
                  !isEditingInsuranceContact && (
                    <Box
                      sx={theme => ({
                        fontSize: 12,
                        color: theme.palette.primary.main,
                        cursor: 'pointer',
                        '&:hover': {
                          textDecoration: 'underline',
                        },
                      })}
                      onClick={() => setIsEditingInsuranceContact(true)}
                    >
                      Edit
                    </Box>
                  )}
              </Box>

              {/* Insurance Policy Number */}
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  variant="body2"
                  sx={{ mr: 1, fontWeight: 600, color: 'text.primary' }}
                >
                  Insurance policy No:
                </Typography>
                {isEditingPolicyNumber ? (
                  <TextField
                    variant="standard"
                    defaultValue={myUser?.insurance?.policyNumber || ''}
                    label="Policy No."
                    onChange={e => setInsurancePolicyNo(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            color="primary"
                            disabled={updateInsuranceLoading}
                            onClick={async () => {
                              await updateInsurance();
                              setIsEditingPolicyNumber(false);
                            }}
                          >
                            {updateInsuranceLoading ? (
                              <Loader />
                            ) : (
                              <CheckIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    sx={{ color: 'text.secondary' }}
                  />
                ) : (
                  <Typography
                    variant="body2"
                    sx={{ mr: 1, color: 'text.secondary' }}
                  >
                    {myUser?.insurance?.policyNumber || (
                      <Button
                        color="primary"
                        onClick={() => setIsEditingPolicyNumber(true)}
                      >
                        Add
                      </Button>
                    )}
                  </Typography>
                )}
                {myUser?.insurance?.policyNumber && !isEditingPolicyNumber && (
                  <Box
                    sx={theme => ({
                      fontSize: 12,
                      color: theme.palette.primary.main,
                      cursor: 'pointer',
                      '&:hover': {
                        textDecoration: 'underline',
                      },
                    })}
                    onClick={() => setIsEditingPolicyNumber(true)}
                  >
                    Edit
                  </Box>
                )}
              </Box>
            </Box>

            {/* Right Section: Social Info */}
            <Box
              sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                '@media (min-width:900px)': {
                  alignItems: 'flex-start',
                },
              }}
            >
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ fontWeight: 600, color: 'text.secondary' }}
              >
                Social info
              </Typography>
              <Typography
                variant="body2"
                sx={{ mr: 1, fontWeight: 600, color: 'text.primary' }}
              >
                Followers: {myUser?.followers?.length || 0}
              </Typography>
              <Typography
                variant="body2"
                sx={{ mr: 1, fontWeight: 600, color: 'text.primary' }}
              >
                Following: {myUser?.following?.length || 0}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Card>
      <CenteredModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        // BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box
            sx={theme => ({
              backgroundColor: theme.palette.background.paper,
              outline: 'none',
              borderRadius: 5,
              maxHeight: '95%',
              boxShadow: theme.shadows[5],
              padding: theme.spacing(2, 4, 3),
              [theme.breakpoints.down('sm')]: {
                width: '95%',
                padding: theme.spacing(2, 2, 2),
              },
            })}
          >
            {modalContent === 'passportExpiry' && (
              <ModalTitle>When your pasport expires?</ModalTitle>
            )}
            {modalContent === 'insuranceExpiry' && (
              <ModalTitle>When your insurance expires?</ModalTitle>
            )}

            <Box>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                {modalContent === 'passportExpiry' && (
                  <StaticDatePicker
                    displayStaticWrapperAs="desktop"
                    openTo="day"
                    value={pasportExpiry}
                    onChange={(newValue: Date | null) => {
                      setPasportExpiry(newValue);
                    }}
                  />
                )}
                {modalContent === 'insuranceExpiry' && (
                  <StaticDatePicker
                    displayStaticWrapperAs="desktop"
                    openTo="day"
                    value={pasportExpiry}
                    onChange={(newValue: Date | null) => {
                      setInsuranceExpiry(newValue);
                    }}
                  />
                )}
              </LocalizationProvider>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <PrimaryButton
                  disabled={updateUserLoading}
                  onClick={async () => {
                    setNickName('');
                    try {
                      if (modalContent === 'passportExpiry') {
                        await updateUser();
                      }
                      if (modalContent === 'insuranceExpiry') {
                        await updateInsurance();
                      }
                      handleClose();
                      setModalContent(null);
                    } catch (e) {
                      //TODO handle error
                    }
                  }}
                >
                  {updateUserLoading ? <Loader /> : 'Save expiry date'}
                </PrimaryButton>
              </Box>
            </Box>
          </Box>
        </Fade>
      </CenteredModal>
    </>
  );
};

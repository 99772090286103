import { Box, Divider, Stack } from '@mui/material';
import React from 'react';

import { Itinerary } from '../../generated/public_graphql';
import { returnFormatedDate } from '../../utils/helpers';

import { TripDay } from './TripDay';

export function TripItinerary(props: {
  itinerary: Itinerary;
  startDate?: Date;
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignSelf: 'center',
        height: '100%',
        width: '100%',
      }}
    >
      {props.itinerary && (
        <Stack
          direction="row"
          alignItems="left"
          sx={theme => ({
            width: '100%',
          })}
        >
          {/* <Divider
            sx={theme => ({
              color: '#636363',
              marginTop: 3,
              width: 3,
              height: '100%',
              position: 'relative',
              left: 13,
              zIndex: -1,
              [theme.breakpoints.down('sm')]: {
                left: 10.25,
              },
            })}
            orientation="vertical"
          /> */}
          <Box width="100%" mt={1} mb={3}>
            {props.itinerary.days &&
              props.itinerary.days.length > 0 &&
              props.itinerary.days.map((day, i) => {
                return (
                  <React.Fragment key={i}>
                    {day && (
                      <>
                        {props.startDate ? (
                          <TripDay
                            key={i + day.id}
                            day={day}
                            date={returnFormatedDate(props.startDate, i)}
                          />
                        ) : (
                          <TripDay key={i + day.id} day={day} />
                        )}
                      </>
                    )}
                  </React.Fragment>
                );
              })}
          </Box>
        </Stack>
      )}
    </Box>
  );
}

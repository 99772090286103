import { useQuery } from '@apollo/client';
import {
  Box,
  CardContent,
  CardMedia,
  Container,
  Divider,
  Grid,
  Typography,
  styled,
} from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { makeStyles } from '@mui/styles';
import { Maybe } from 'graphql/jsutils/Maybe';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Trip } from '../../generated/public_graphql';
import { TitleH2 } from '../../theme-components/Typography';
import { getFourImages } from '../../utils/helpers';
import { QUERY_COUNTRY_TRIPS } from '../gql-public/countryTripsQuery';
import { HorizontalFlex } from '../helpers/flex';

interface PropsTripsList {
  readonly trips: Maybe<Trip>[];
}

const useStyles = makeStyles(theme => ({
  Grid: {
    paddingTop: 20,
    paddingBottom: 20,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 10,
    },
  },
  tripImage: {
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
    height: 200,
    width: '100%',
  },
  divider: {
    marginBottom: 20,
  },
  background: {
    width: '100%',
    background: 'linear-gradient(180deg, #F9E7E1 0%, #FFFFFF 100%)',
    paddingTop: 20,
  },

  tripName: {
    fontWeight: 600,
    fontSize: 20,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
  },
  tripLength: {
    fontWeight: 400,
    fontSize: 18,
    marginTop: 5,
    color: theme.palette.secondary.main,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  tripCountry: {
    fontWeight: 400,
    fontSize: 18,
    marginRight: 5,
    color: theme.palette.secondary.main,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  card: {
    borderRadius: 20,
    marginRight: 10,
    marginLeft: 10,
    paddingLeft: 0,
    paddingRight: 0,
    marginBottom: 20,
    width: '31%',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.down('md')]: {
      width: '47%',
    },

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxHeight: 300,
    },
  },
  cardContent: {
    paddingTop: 10,
    paddingRight: 10,
    paddingLeft: 10,
    backgroundColor: '#FFFFFF',
    '&:last-child': {
      paddingBottom: 10,
    },
    width: '100%',
  },
  cardContentCountries: {
    paddingTop: 10,
    paddingRight: 10,
    paddingLeft: 10,
    borderRadius: 20,
    backgroundColor: '#FFFFFF',
    '&:last-child': {
      paddingBottom: 18,
    },
    width: '100%',
  },
}));

export function CountryTrips(props: { countryId: string; countryName }) {
  const classes = useStyles();
  const { loading, error, data } = useQuery(QUERY_COUNTRY_TRIPS, {
    variables: {
      country_id: props.countryId,
    },
  });

  return (
    <Box className={classes.background}>
      <Container>
        <Box>
          <TitleH2>{props.countryName + ' itineraries'}</TitleH2>
        </Box>
        {loading ? (
          <LoadingCards />
        ) : (
          <>
            {error || !data || !data.countryTrips ? (
              <div>Couldn't load trips, please refresh or come back later.</div>
            ) : (
              <Trips trips={data.countryTrips} />
            )}
          </>
        )}
      </Container>
    </Box>
  );
}

function LoadingCards() {
  const classes = useStyles();
  const loadingCards = [1, 2];

  return (
    <Grid className={classes.Grid} key={1}>
      {loadingCards.map(index => (
        <>
          <Box className={classes.card} boxShadow={1}>
            <Skeleton variant="rectangular" className={classes.tripImage} />
            <CardContent className={classes.cardContent}>
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </CardContent>
            <Divider />
            <CardContent className={classes.cardContentCountries}>
              <Skeleton />
            </CardContent>
          </Box>
        </>
      ))}
    </Grid>
  );
}

const TripTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: 20,
  [theme.breakpoints.down('sm')]: {
    fontSize: 18,
  },
}));

const TripLength = styled(Box)(({ theme }) => ({
  fontWeight: 400,
  fontSize: 18,
  marginTop: 5,
  color: theme.palette.secondary.main,
  [theme.breakpoints.down('sm')]: {
    fontSize: 16,
  },
}));

const TripCountry = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: 18,
  marginRight: 5,
  color: theme.palette.secondary.main,
  [theme.breakpoints.down('sm')]: {
    fontSize: 16,
  },
}));

function Trips(props: PropsTripsList) {
  const classes = useStyles();
  const navigate = useNavigate();
  const contentWrapper = React.useRef(null);

  return (
    <>
      <Grid className={classes.Grid} ref={contentWrapper}>
        {props.trips &&
          props.trips.length > 0 &&
          props.trips.map((trip, index) => (
            <>
              <Box
                key={index}
                className={classes.card}
                boxShadow={1}
                onClick={() => {
                  navigate('/trip/' + trip!.id);
                }}
              >
                {trip!.images && trip!.images.length > 0 ? (
                  <CardMedia
                    className={classes.tripImage}
                    image={
                      trip!.images[0]!.path! && getFourImages(trip!.images!)[0]!
                    }
                  />
                ) : (
                  <CardMedia
                    className={classes.tripImage}
                    image={'/assets/image/placeholder-svg.svg'}
                  />
                )}
                <Box flexGrow={1}>
                  <CardContent className={classes.cardContent}>
                    <TripTitle>{trip!.name}</TripTitle>
                    {trip!.length && (
                      <TripLength>{trip!.length + ' days'}</TripLength>
                    )}
                  </CardContent>
                </Box>
                <Box justifyContent="flex-end">
                  {trip &&
                    trip.itineraryCountries &&
                    trip.itineraryCountries.length > 0 && (
                      <>
                        <Divider />
                        <CardContent className={classes.cardContentCountries}>
                          <HorizontalFlex>
                            {trip.itineraryCountries.map((country, index) => (
                              <Box key={index}>
                                {country && (
                                  <TripCountry>
                                    {country.countryName}
                                    {trip.itineraryCountries &&
                                      index !==
                                        trip.itineraryCountries.length - 1 && (
                                        <>{','}</>
                                      )}
                                  </TripCountry>
                                )}
                              </Box>
                            ))}
                          </HorizontalFlex>
                        </CardContent>
                      </>
                    )}
                </Box>
              </Box>
            </>
          ))}
      </Grid>
    </>
  );
}

import { useAuth0 } from '@auth0/auth0-react';
import { Box, Container, Stack } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';

import AuthorizedApolloProvider from '../AuthorizedApolloProvider';
import { Header } from '../components/AppWrapper';
import { Profile } from '../components/auth/Profile';
import { VerticalFlex } from '../components/helpers/flex';
import LoginIllustration from '../components/illustrations/Login';
import { UserTrips } from '../components/user-account/UserTrips';
import { ProminentButtonWide } from '../theme-components/Buttons';
import { LoaderFullScreen } from '../theme-components/Loader';
import { Body2, HelpText } from '../theme-components/Typography';

const useStyles = makeStyles(theme =>
  createStyles({
    illustration: {
      width: 550,
      height: 500,
      [theme.breakpoints.down('md')]: {
        width: 450,
        height: 400,
      },
      [theme.breakpoints.down('sm')]: {
        width: 250,
        height: 200,
      },
    },
  }),
);

export default function MyAccountRoute() {
  const classes = useStyles();
  const [count, setCount] = useState(0);
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (count === 0) {
      window.scrollTo(0, 0);
    }
    setCount(1);
  }, [count]);

  if (isLoading) {
    return <LoaderFullScreen />;
  }

  gtag('event', 'user-account-page');

  return (
    <Container>
      {!isAuthenticated ? (
        <Box
          sx={theme => ({
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            height: '100%',
            justifyContent: 'space-around',
            alignSelf: 'center',
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
            },
          })}
        >
          <Box
            display="flex"
            flexDirection="column"
            sx={{
              marginTop: 1,
              display: {
                xs: 'block',
                sm: 'none',
                md: 'none',
                lg: 'none',
                xl: 'none',
              },
            }}
          >
            <Header>Login</Header>
            <Box display="flex" justifyContent="center" width="100%" mt={3}>
              <LoginIllustration className={classes.illustration} />
            </Box>

            <Box mt={1}>
              <Body2>
                We do not believe in passwords and only thing you need to use
                rooutie is your email address.
                <br />
                <br /> Eeach time you login you will receive different 6-digits
                code to access your account.
              </Body2>
            </Box>

            <Box
              display="flex"
              flexDirection="row"
              width="100%"
              justifyContent="flex-end"
              mt={4}
            >
              <VerticalFlex>
                <ProminentButtonWide
                  onClick={() => {
                    gtag('event', 'login-button');
                    loginWithRedirect();
                  }}
                >
                  Login
                </ProminentButtonWide>

                <Box ml={3} mt={0.5} mb={3}>
                  <HelpText>Protected with Auth0</HelpText>
                </Box>
              </VerticalFlex>
            </Box>
          </Box>
          <Box
            sx={{
              display: {
                xs: 'none',
                sm: 'block',
                md: 'none',
                lg: 'none',
                xl: 'none',
              },
            }}
          >
            <Box display="flex" flexDirection="column" mt={8}>
              <Header>Login</Header>
              <LoginIllustration className={classes.illustration} />
              <Box mt={3}>
                <Body2>
                  We do not believe in passwords and only thing you
                  <br />
                  need to use rooutie is your email address.
                  <br />
                  <br /> Eeach time you login you will receive different
                  <br />
                  6-digits code to access your account.
                </Body2>
              </Box>

              <Box
                display="flex"
                flexDirection="row"
                width="100%"
                justifyContent="flex-end"
                mt={6}
              >
                <VerticalFlex>
                  <ProminentButtonWide
                    onClick={() => {
                      gtag('event', 'login-button');
                      loginWithRedirect();
                    }}
                  >
                    Login
                  </ProminentButtonWide>

                  <Box ml={3} mt={0.5}>
                    <HelpText>Protected with Auth0</HelpText>
                  </Box>
                </VerticalFlex>
              </Box>
            </Box>
          </Box>
          <Box
            mt={8}
            sx={{
              display: {
                xs: 'none',
                sm: 'none',
                md: 'block',
                lg: 'block',
                xl: 'block',
              },
            }}
          >
            <Stack direction="row">
              <Stack direction="column">
                <Header>Login</Header>
                <Box mt={3}>
                  <Body2>
                    We do not believe in passwords <br />
                    and only thing you need to use
                    <br />
                    rooutie is your email address.
                    <br />
                    <br /> Eeach time you login you will
                    <br />
                    receive different 6-digits code to
                    <br />
                    access your account.
                  </Body2>
                </Box>
                <Stack direction="column" alignItems="flex-end" mt={5}>
                  <ProminentButtonWide
                    onClick={() => {
                      gtag('event', 'login-button');
                      loginWithRedirect();
                    }}
                  >
                    Login
                  </ProminentButtonWide>

                  <Box mt={0.5}>
                    <HelpText>Protected with Auth0</HelpText>
                  </Box>
                </Stack>
              </Stack>
              <LoginIllustration className={classes.illustration} />
            </Stack>
          </Box>
        </Box>
      ) : (
        <AuthorizedApolloProvider>
          <VerticalFlex>
            <Box display="flex" flexGrow={1}>
              <Header>Your traveler profile</Header>
            </Box>
            <Profile />
            <UserTrips />
          </VerticalFlex>
        </AuthorizedApolloProvider>
      )}
    </Container>
  );
}

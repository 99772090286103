import gql from 'graphql-tag';

export const MUTATION_CREATE_USER_TRIP_WITH_DAY = gql`
  mutation createUserTripWithDay(
    $name: String!
    $place_url: String
    $step_id: String
  ) {
    createUserTripWithDay(
      name: $name
      place_url: $place_url
      step_id: $step_id
    ) {
      id
      description
      name
      length
      archived
    }
  }
`;

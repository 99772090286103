import gql from 'graphql-tag';

export const MUTATION_UPDATE_USER = gql`
  mutation updateUser($nickname: String, $pasport_expiry_date: DateTime) {
    updateUser(nickname: $nickname, pasport_expiry_date: $pasport_expiry_date) {
      id
      email
      pasportExpiry
      nickName
      insurance {
        id
        policyNumber
        contactNumber
        expiry
      }
      following {
        id
        nickName
      }
      followers {
        id
        nickName
      }
    }
  }
`;

export const MUTATION_FOLLOW_USER = gql`
  mutation followUser($following_user_id: String!, $follow: Boolean!) {
    followUser(following_user_id: $following_user_id, follow: $follow) {
      id
      email
      pasportExpiry
      nickName
      insurance {
        id
        policyNumber
        contactNumber
        expiry
      }
      following {
        id
        nickName
      }
      followers {
        id
        nickName
      }
    }
  }
`;

export const MUTATION_UPSERT_INSURANCE = gql`
  mutation updateInsurance(
    $contact_number: String
    $policy_number: String
    $insurance_expiry_date: DateTime
  ) {
    updateInsurance(
      contact_number: $contact_number
      policy_number: $policy_number
      insurance_expiry_date: $insurance_expiry_date
    ) {
      id
      email
      pasportExpiry
      nickName
      insurance {
        id
        policyNumber
        contactNumber
        expiry
      }
      following {
        id
        nickName
      }
      followers {
        id
        nickName
      }
    }
  }
`;

export const QUERY_GET_USER = gql`
  query getUser {
    getUser {
      id
      email
      pasportExpiry
      nickName
      insurance {
        id
        policyNumber
        contactNumber
        expiry
      }
      following {
        id
        nickName
      }
      followers {
        id
        nickName
      }
    }
  }
`;

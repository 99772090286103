import { Box, Modal } from '@mui/material';
import styled from '@mui/styles/styled';

export const ModalTitle = styled(Box)(({ theme }) => ({
  fontWeight: 600,
  fontSize: 22,
  marginBottom: 5,
  color: '#14142B',
  // alignSelf: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: 18,
    marginBottom: 5,
  },
}));

export const ModalDescription = styled(Box)(({ theme }) => ({
  marginBottom: 5,
  marginLeft: 0,
  fontWeight: 500,
  fontSize: 18,
  color: theme.palette.secondary.main,
  [theme.breakpoints.down('sm')]: {
    fontSize: 14,
    marginBottom: 5,
  },
}));

export const LargeModalPaper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  outline: 'none',
  width: 700,
  borderRadius: 20,
  maxHeight: '95%',
  maxWidth: '95%',
  overflow: 'scroll',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 3, 2),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2, 2, 2),
  },
}));

export const MediumModalPaper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  outline: 'none',
  width: 500,
  borderRadius: 20,
  maxHeight: '95%',
  maxWidth: '95%',
  overflow: 'scroll',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2, 2, 2),
  },
}));

export const SmallModalPaper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  outline: 'none',
  width: 300,
  borderRadius: 20,
  maxHeight: '95%',
  maxWidth: '95%',
  overflow: 'scroll',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2, 2, 2),
  },
}));

// export const CenteredModal = styled(Modal)(({ theme }) => ({
//   // display: 'flex',
//   // flexDirection: 'column',
//   // alignSelf: 'center',
//   // alignItems: 'center',
//   // justifyContent: 'center',
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
//   // maxHeight: '95%',

//   // maxHeight: '95%',

//   // top: '1%',
// }));

export const CenteredModal = styled(Modal)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  maxHeight: '100vh', // Limit height to viewport height
  maxWidth: '100vw', // Limit width to viewport width
  overflowY: 'auto', // Add scroll if content exceeds the modal height
  padding: '0 16px', // Optional padding to avoid edge cuts
}));

import { useLazyQuery, useMutation } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Card,
  CardMedia,
  CircularProgress,
  Fade,
  Grid,
  IconButton,
  Skeleton,
  Tooltip,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import imageCompression from 'browser-image-compression';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Step } from '../../../generated/user_graphql';
import { setDays } from '../../../store/DaySlice';
import { setTrip } from '../../../store/TripSlice';
import { SecondaryButton } from '../../../theme-components/Buttons';
import { Loader } from '../../../theme-components/Loader';
import {
  MUTATION_DELETE_STEP_PLACE_IMAGE,
  MUTATION_DELETE_USER_IMAGE,
} from '../../gql-user/deleteImage';
import { FETCH_IMAGE_URL } from '../../gql-user/getPlaceImage';
import { QUERY_USER_TRIP } from '../../gql-user/userTrip';
import { HorizontalFlex } from '../../helpers/flex';
import UploadIcon from '../../icons/Upload';
import {
  CenteredModal,
  MediumModalPaper,
  ModalDescription,
  ModalTitle,
} from '../../styling/modal';

const useStyles = makeStyles(theme =>
  createStyles({
    uploadIcon: {
      width: 25,
      height: 25,
      marginRight: 10,
    },
    deleteIcon: {
      width: 20,
      height: 20,
      marginRight: 12,
    },
    uploadCopy: {
      alignSelf: 'center',
      fontWeight: 500,
      fontSize: 16,
      color: theme.palette.primary.main,
    },
    buttonBox: {
      marginLeft: 5,
      marginTop: 5,
      cursor: 'pointer',
    },
  }),
);

export const EditImages: React.FC<{
  step: Step;
  tripId: string;
  handleCloseModal: () => void;
}> = ({ step, tripId, handleCloseModal }) => {
  const [open, setOpen] = useState(false);

  const closeModal = () => {
    setOpen(false);
    handleCloseModal();
  };

  useEffect(() => {
    const disableScroll = e => e.preventDefault();
    if (open) {
      // Lock body scroll
      document.body.style.overflow = 'hidden';
      document.addEventListener('touchmove', disableScroll, {
        passive: false,
      });
    } else {
      // Unlock body scroll
      document.body.style.overflow = '';
      document.removeEventListener('touchmove', disableScroll);
    }

    // Cleanup function to restore scroll when component unmounts or modal closes
    return () => {
      document.body.style.overflow = '';
      document.removeEventListener('touchmove', disableScroll);
    };
  }, [open]);
  return (
    <>
      <Box
        onClick={() => {
          gtag('event', 'delete-step-button');
          setOpen(true);
        }}
        sx={{ width: '100%' }}
      >
        Edit images
      </Box>

      <CenteredModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={closeModal}
        closeAfterTransition
      >
        <Fade in={open}>
          <MediumModalPaper>
            <ManageStepImages tripId={tripId} step={step} />
            {step.placeImages && (
              <StepImages
                tripId={tripId}
                images={step.placeImages}
                userImages={false}
                placeImages={true}
              />
            )}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
              <SecondaryButton
                onClick={async () => {
                  gtag('event', 'edit-images-close');
                  setOpen(false);
                  closeModal();
                }}
                sx={{ marginRight: 1 }}
              >
                Close
              </SecondaryButton>
            </Box>
          </MediumModalPaper>
        </Fade>
      </CenteredModal>
    </>
  );
};

export function ManageStepImages(props: { step: Step; tripId: string }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const [imagePreview, setImagePreview] = useState('');
  const [imageLoading, setImageLoading] = useState(false);
  const [compressedFile, setCompressedFile] = useState(null);

  const addImage = async (e: any) => {
    if (e.target.files.length) {
      const formData = new FormData();
      gtag('event', 'upload-image');
      formData.append('stepId', props.step.id);
      setImageLoading(true);
      const preview = URL.createObjectURL(e.target.files[0]);
      setImagePreview(preview);
      await handleUpload(e.target.files[0].type, preview, formData);
    }
  };

  const [getUserTrip, { data }] = useLazyQuery(QUERY_USER_TRIP, {
    fetchPolicy: 'no-cache',
    variables: { trip_id: props.tripId },
  });

  useEffect(() => {
    if (data && data.userTrip) {
      dispatch(setDays(data.userTrip.itinerary.days));
    }
  }, [data, dispatch]);

  const handleUpload = async (
    imageType: string,
    preview: string,
    formData: FormData,
  ) => {
    const blob = await fetch(preview).then(r => r.blob());

    const file = new File([blob], 'image', {
      type: imageType,
    });

    const imageFile = file;
    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1920,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      // console.log(
      //   `compressedFile size ${compressedFile.size / 1024 / 1024} MB`,
      // );

      formData.append('image', compressedFile);

      const token = await getAccessTokenSilently();
      await fetch(process.env.REACT_APP_CONFIG_URL_LOCKED + '/upload', {
        credentials: 'same-origin',
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      await getUserTrip();
      setImageLoading(false);
    } catch (error) {
      // TODO
      console.log(error);
    }
  };

  //NEW
  let image: string | null = null;
  if (imagePreview) {
    image = imagePreview;
  }

  return (
    <>
      {props.step.images && (
        <>
          <ModalTitle>Your images</ModalTitle>
          <ModalDescription>
            Only 3 images will show on your itinerary
          </ModalDescription>
          <StepImages
            tripId={props.tripId}
            images={props.step.images}
            userImages={true}
            placeImages={false}
          />
        </>
      )}
      <label htmlFor="upload-image">
        <Box>
          {!imageLoading ? (
            <Box className={classes.buttonBox}>
              <HorizontalFlex>
                <UploadIcon className={classes.uploadIcon} />
                <Box className={classes.uploadCopy}>Upload image</Box>
              </HorizontalFlex>
            </Box>
          ) : (
            <Loader />
          )}
        </Box>
      </label>
      <input
        type="file"
        id="upload-image"
        style={{ display: 'none' }}
        onChange={addImage}
      />
    </>
  );
}

function StepImages(props: {
  tripId;
  images;
  userImages: boolean;
  placeImages: boolean;
}) {
  //
  return (
    <>
      {props.images && props.images.length > 0 && (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          {/* {props.userImages && <ModalTitle>Your images</ModalTitle>} */}
          {props.placeImages && (
            <>
              <ModalTitle>Place images</ModalTitle>
              <ModalDescription>
                Only 3 images will show on your itinerary
              </ModalDescription>
            </>
          )}

          <Grid
            container
            spacing={1}
            direction="row"
            // wrap="nowrap"
            sx={{ justifyContent: 'left', paddingTop: 2 }}
          >
            {props.images.map((image, index) => (
              <Grid
                item
                xs={4} // 12/4 = 3 items per row on extra-small screens and above
                sm={4} // 3 items per row on small screens and above
                md={4} // 3 items per row on medium screens and above
                lg={4} // 3 items per row on large screens and above
                xl={4} // 3 items per row on extra-large screens and above
                key={index}
              >
                <ImageCard
                  tripId={props.tripId}
                  image={image}
                  index={index}
                  userImages={props.userImages}
                  placeImages={props.placeImages}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </>
  );
}

function ImageCard(props: {
  image;
  index: number;
  userImages: boolean;
  placeImages: boolean;
  tripId: string;
}) {
  const [imageLoaded, setImageLoaded] = useState(false);
  const dispatch = useDispatch();
  const [fetchImageUrl, { data, loading: loadingImage, error }] =
    useMutation(FETCH_IMAGE_URL);
  const [imageSrc, setImageSrc] = useState('');

  // if (props.placeImages) {
  //   if (imageSrc === '') {
  //   }
  // }

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await fetchImageUrl({
          variables: { image_url: props.image.imageUrl },
        });
        if (response.data && response.data.fetchImage.imageData) {
          // Ensure the response contains base64 data in correct format
          const base64Data = `data:image/jpeg;base64,${response.data.fetchImage.imageData}`; // Adjust MIME type as needed
          setImageSrc(base64Data);
          setImageLoaded(true);
        }
      } catch (err) {
        return <></>;
      }
    };

    fetchImage();
  }, [fetchImageUrl, props.image.imageUrl]);

  const [deleteUserImage, { loading }] = useMutation(
    MUTATION_DELETE_USER_IMAGE,
    {
      variables: {
        id: props.image.id,
      },
    },
  );
  const [deleteStepPlaceImage, { loading: loadingPlaceImage }] = useMutation(
    MUTATION_DELETE_STEP_PLACE_IMAGE,
    {
      variables: {
        step_place_image_id: props.image.id,
      },
    },
  );

  const [getUserTrip] = useLazyQuery(QUERY_USER_TRIP, {
    fetchPolicy: 'no-cache',
    variables: { trip_id: props.tripId },
  });

  let imageUrl = '';
  if (props.userImages) {
    imageUrl = props.image.path;
  }

  if (props.placeImages) {
    if (imageSrc === '') {
      return (
        <Skeleton
          variant="rectangular"
          sx={theme => ({
            height: 150,
            width: '100%',
            objectFit: 'cover',
            [theme.breakpoints.down('sm')]: {
              height: 130,
            },
          })}
        />
      );
    }
  }

  if (props.placeImages) {
    imageUrl = imageSrc;
  }

  const handleDeleteImage = async () => {
    try {
      if (props.placeImages) {
        await deleteStepPlaceImage();
      } else if (props.userImages) {
        await deleteUserImage();
      }

      const { data } = await getUserTrip();
      if (data && data.userTrip) {
        dispatch(setTrip(data.userTrip));
        dispatch(setDays(data.userTrip.itinerary.days));
      }
    } catch (error) {
      console.error('Error deleting image:', error);
    }
  };

  return (
    <Card
      sx={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
        {!imageLoaded && (
          <Skeleton
            variant="rectangular"
            width="100%"
            height="100%"
            sx={{
              maxHeight: 100,
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        )}
        <Tooltip
          key={props.index}
          title={(props.image && props.image.title) || 'Image'}
          disableInteractive
          arrow
          placement="top"
        >
          <CardMedia
            component="img"
            src={imageUrl}
            alt={props.image?.title || 'Place Image'}
            onLoad={() => setImageLoaded(true)} // Set imageLoaded to true once the image is loaded
            sx={{
              maxHeight: 150,
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              display: imageLoaded ? 'block' : 'none',
            }}
          />
        </Tooltip>
        <Box
          sx={{
            position: 'absolute',
            bottom: 4,
            right: 4,
            width: 39,
            height: 39,
            backgroundColor: 'rgba(255, 255, 255, 0.6)',
            borderRadius: 20,
            zIndex: 2,
          }}
        >
          <IconButton
            aria-label="delete"
            sx={{
              position: 'absolute',
              bottom: 4,
              right: 4,
              zIndex: 3,
              color: '#FFFFFF',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
              },
            }}
            disabled={loading || loadingPlaceImage}
            onClick={handleDeleteImage}
          >
            {loading ? (
              <CircularProgress size={15} sx={{ color: '#FFFFFF' }} />
            ) : (
              <DeleteIcon
                sx={{
                  height: 15,
                  width: 15,
                }}
              />
            )}
          </IconButton>
        </Box>
      </Box>
    </Card>
  );
}

import { Container } from '@mui/material';
import { useEffect, useState } from 'react';

import AuthorizedApolloProvider from '../AuthorizedApolloProvider';
import { CreateUserTrip } from '../components/create-trip/CreateUserTrip';
import { HorizontalFlex } from '../components/helpers/flex';
import { RoutePage } from '../components/route';

export default function CreateTripRoute() {
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (count === 0) {
      window.scrollTo(0, 0);
      setCount(1);
    }
  }, [count]);

  return (
    <RoutePage>
      <HorizontalFlex>
        <AuthorizedApolloProvider>
          <Container
            sx={{
              paddingLeft: '12px',
              paddingRight: '12px',
            }}
          >
            <CreateUserTrip />
          </Container>
        </AuthorizedApolloProvider>
      </HorizontalFlex>
    </RoutePage>
  );
}

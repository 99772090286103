import gql from 'graphql-tag';

export const QUERY_GET_USER_TRIPS = gql`
  query userTripsList {
    userTripsList {
      id
      name
      length
      published
      publish_pending
      images {
        id
        path
        header
        main
        secondary
        tertiary
      }
      countries {
        id
        name
      }
      itineraryCountries {
        countryName
        itineraryId
      }
    }
  }
`;

export const QUERY_GET_USER_TRIPS_WITH_DAYS_ONLY = gql`
  query userTripsWithDaysOnly {
    userTripsWithDaysOnly {
      id
      name
      length
      published
      publish_pending
      images {
        id
        path
        header
        main
        secondary
        tertiary
      }
      countries {
        id
        name
      }
      itineraryCountries {
        countryName
        itineraryId
      }
      itinerary {
        id
        days {
          id
          dayNumber
          cities {
            cityName
          }
        }
      }
    }
  }
`;

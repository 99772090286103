import { useLazyQuery } from '@apollo/client';
import { Backdrop } from '@material-ui/core';
import { Box, Divider, Fade, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { makeStyles } from '@mui/styles';
import { Fragment, ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ProminentButton } from '../../theme-components/Buttons';
import { Loader, LoaderCentred } from '../../theme-components/Loader';
import { QUERY_GET_COUNTRIES_WITH_PUBLISHED_TRIPS } from '../gql-user/getCountriesWithPublishedTripsQuery';
import { QUERY_GET_PUBLISHED_TRIPS_FOR_COUNTRY } from '../gql-user/getPublishedTripsByCountryQuery';
import { HorizontalFlex } from '../helpers/flex';
import {
  CenteredModal,
  LargeModalPaper,
  ModalDescription,
  ModalTitle,
} from '../styling/modal';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
  },
  Grid: {
    flexWrap: 'nowrap',
    [theme.breakpoints.down('sm')]: {},
  },
  header: {
    fontWeight: 900,
    fontSize: 24,
    marginBottom: 10,
  },
  subHeader: {
    fontWeight: 600,
    fontSize: 20,
    marginBottom: 10,
  },
  results: {
    marginTop: -10,
    padding: 20,
    width: '100%',
    // zIndex: 1,
    // position: 'absolute',
    backgroundColor: 'white',
  },
  tripName: {
    fontWeight: 500,
    fontSize: 16,
    marginTop: 5,
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
  },
  tripLength: {
    fontWeight: 400,
    fontSize: 14,
    marginRight: 5,

    color: theme.palette.secondary.main,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
  },
  divider: {
    marginTop: 10,
    marginBottom: 10,
  },
  searchInput: {
    transition: theme.transitions.create('width'),
    paddingLeft: 10,
  },
}));

export function TripsResults(props: { userTripId: string }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [countryId, setCountryId] = useState('');

  const [getCountries, { loading, error, data }] = useLazyQuery(
    QUERY_GET_COUNTRIES_WITH_PUBLISHED_TRIPS,
  );

  const [
    searchForTrips,
    { data: dataCountryTrips, loading: loadingCountryTrips },
  ] = useLazyQuery(QUERY_GET_PUBLISHED_TRIPS_FOR_COUNTRY);

  useEffect(() => {
    if (countryId !== '') {
      searchForTrips({
        variables: { country_id: countryId },
      });
    }
  }, [countryId, searchForTrips]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    // error = undefined;
    setOpen(false);
  };

  useEffect(() => {
    const disableScroll = e => e.preventDefault();
    if (open) {
      // Lock body scroll
      document.body.style.overflow = 'hidden';
      document.addEventListener('touchmove', disableScroll, {
        passive: false,
      });
    } else {
      // Unlock body scroll
      document.body.style.overflow = '';
      document.removeEventListener('touchmove', disableScroll);
    }

    // Cleanup function to restore scroll when component unmounts or modal closes
    return () => {
      document.body.style.overflow = '';
      document.removeEventListener('touchmove', disableScroll);
    };
  }, [open]);

  return (
    <>
      <ProminentButton
        onClick={() => {
          handleOpen();
          getCountries();
        }}
      >
        Get inspired
      </ProminentButton>
      <CenteredModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <LargeModalPaper>
            <ModalTitle>Search for trip</ModalTitle>
            <ModalDescription>
              Search for the trip by country you are visiting. We are doing
              everything we can to add more trips and countries every day.
            </ModalDescription>
            {loading ? (
              <Loader />
            ) : (
              <>
                {error || !data ? (
                  <Box>Error please close and try again</Box>
                ) : (
                  <>
                    <Autocomplete
                      freeSolo
                      disableClearable
                      options={data.countriesWithPublishedTripsList.map(
                        option => option.name,
                      )}
                      onInputChange={async (event, newInputValue) => {
                        data.countriesWithPublishedTripsList.forEach(
                          country => {
                            if (newInputValue === country.name) {
                              setCountryId(country.id);
                              // searchForTrips({
                              //   variables: { country_id: country.id },
                              // });
                            }
                          },
                        );
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Search by country"
                          margin="normal"
                          variant="outlined"
                          InputProps={{ ...params.InputProps, type: 'search' }}
                        />
                      )}
                    />
                    {loadingCountryTrips && <LoaderCentred />}
                    {dataCountryTrips &&
                      dataCountryTrips.searchTripsByCountry &&
                      dataCountryTrips.searchTripsByCountry.length > 0 && (
                        <Box className={classes.results} boxShadow={1}>
                          {dataCountryTrips.searchTripsByCountry.map(
                            (trip, index) => (
                              <>
                                {trip && props.userTripId !== trip.id && (
                                  <Fragment key={index + trip.id}>
                                    <Box
                                      onClick={() => {
                                        navigate(
                                          '/create-route/' +
                                            props.userTripId +
                                            '?selected-trip=' +
                                            trip.id,
                                        );
                                        handleClose();
                                      }}
                                    >
                                      <Box>
                                        <ResultTitle>{trip.name}</ResultTitle>
                                      </Box>
                                      {trip.length ? (
                                        <Box>
                                          <HorizontalFlex>
                                            <ResultTripLength>
                                              {trip.length + ' days in'}
                                            </ResultTripLength>
                                            <Box justifyContent="flex-end">
                                              {trip &&
                                                trip.itineraryCountries &&
                                                trip.itineraryCountries.length >
                                                  0 && (
                                                  <>
                                                    <HorizontalFlex>
                                                      {trip.itineraryCountries.map(
                                                        (country, index) => (
                                                          <Box key={index}>
                                                            {country && (
                                                              <ResultTripLength>
                                                                {country.name}
                                                                {trip.itineraryCountries &&
                                                                  index !==
                                                                    trip
                                                                      .itineraryCountries
                                                                      .length -
                                                                      1 && (
                                                                    <>{','}</>
                                                                  )}
                                                              </ResultTripLength>
                                                            )}
                                                          </Box>
                                                        ),
                                                      )}
                                                    </HorizontalFlex>
                                                  </>
                                                )}
                                            </Box>
                                          </HorizontalFlex>
                                        </Box>
                                      ) : null}
                                    </Box>
                                    <Divider className={classes.divider} />
                                  </Fragment>
                                )}
                              </>
                            ),
                          )}
                        </Box>
                      )}
                  </>
                )}
              </>
            )}
          </LargeModalPaper>
        </Fade>
      </CenteredModal>
    </>
  );
}

function ResultTitle(props: { children: ReactNode }) {
  const classes = useStyles();
  return <Box className={classes.tripName}>{props.children}</Box>;
}
function ResultTripLength(props: { children: ReactNode }) {
  const classes = useStyles();
  return <Box className={classes.tripLength}>{props.children}</Box>;
}

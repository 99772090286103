import { Divider, Box } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import _ from 'lodash';
import { Fragment } from 'react';

import { Country, Recommendation } from '../../generated/public_graphql';
import { VerticalFlex, HorizontalFlex } from '../helpers/flex';

import {
  SideBarIcon,
  SideBarHeader,
  SideBarTitleDivider,
  SideBarSubHeader,
  SideBarSubSubHeader,
  SideBarDescription,
  SideBarLink,
} from './TripSidebar';

const useStyles = makeStyles(theme =>
  createStyles({
    divider: {
      marginTop: 10,
      marginBottom: 20,
    },
    inSetBox: {
      paddingLeft: 15,
    },
  }),
);

interface Props {
  readonly countries: [Country];
}

export function TripHealth(props: Props) {
  const classes = useStyles();

  // For now we will support only one country per
  // trip untill we have designs to show multiple
  const country = props.countries[0];

  const healthTips = _.groupBy(country.recommendations, 'type');

  return (
    <VerticalFlex>
      <HorizontalFlex>
        <SideBarIcon $iconType={'VACCINE'} />
        <SideBarHeader>{'Health'}</SideBarHeader>
      </HorizontalFlex>
      <SideBarTitleDivider />
      {country &&
        country.recommendations &&
        Object.keys(healthTips).map(function (type, i) {
          return (
            <>
              <Fragment key={'vaccination' + i}>
                {type === 'RECOMMENDATION_VACCINATION' && (
                  <>
                    <SideBarSubHeader>{'Vaccinations'}</SideBarSubHeader>
                    <Box className={classes.inSetBox}>
                      {healthTips[type].map(function (recommendation, i) {
                        return (
                          <Fragment key={'season' + i}>
                            {recommendation && (
                              <RecommendationDetails
                                recommendation={recommendation}
                              />
                            )}
                          </Fragment>
                        );
                      })}
                    </Box>
                  </>
                )}
              </Fragment>
              <Fragment key={'vaccination' + i}>
                {type === 'RECOMMENDATION_HEALTH' && (
                  <>
                    <SideBarSubHeader>{'Health info'}</SideBarSubHeader>
                    <Box className={classes.inSetBox}>
                      {healthTips[type].map(function (recommendation, i) {
                        return (
                          <Fragment key={'season' + i}>
                            {recommendation && (
                              <RecommendationDetails
                                recommendation={recommendation}
                              />
                            )}
                          </Fragment>
                        );
                      })}
                    </Box>
                  </>
                )}
              </Fragment>
              {/* <Fragment key={'vaccination' + i}>
                {type === 'RECOMMENDATION_MEDICATION' && (
                  <>
                    <SideBarSubHeader>{'Medication'}</SideBarSubHeader>
                    {healthTips[type].map(function (recommendation, i) {
                      return (
                        <Fragment key={'season' + i}>
                          {recommendation && (
                            <RecommendationDetails
                              recommendation={recommendation}
                            />
                          )}
                        </Fragment>
                      );
                    })}
                  </>
                )}
              </Fragment> */}
            </>
          );
        })}
    </VerticalFlex>
  );
}

function RecommendationDetails(props: { recommendation: Recommendation }) {
  const classes = useStyles();
  return (
    <VerticalFlex>
      <SideBarSubSubHeader>{props.recommendation.name}</SideBarSubSubHeader>
      {/* {props.recommendation.importance === 'IMPORTANCE_HIGH' && (
        <Typography variant="subtitle1" gutterBottom color="secondary">
          Please follow the advise - very important
        </Typography>
      )}

      {props.recommendation.importance === 'IMPORTANCE_MEDIUM' && (
        <Typography variant="subtitle1" gutterBottom color="secondary">
          Advise recommended
        </Typography>
      )} */}
      {props.recommendation.description && (
        <SideBarDescription>
          {props.recommendation.description}
        </SideBarDescription>
      )}
      {props.recommendation.url && (
        <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <SideBarLink url={props.recommendation.url} copy={'More info'} />
        </Box>
      )}
      <Divider className={classes.divider} />
    </VerticalFlex>
  );
}

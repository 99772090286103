import { useQuery } from '@apollo/client';
import { Hidden } from '@material-ui/core';
import { Container } from '@mui/material';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Img } from 'react-image';

import { LoaderCentred } from '../../theme-components/Loader';
import { TitleH1 } from '../../theme-components/Typography';
import { QUERY_GET_COUNTRY } from '../gql-public/countryQuery';
import { VerticalFlex } from '../helpers/flex';
import { TripCountryBox } from '../trip-details/TripCountryBox';

import { CountryInfoLinks } from './country-info';

const useStyles = makeStyles<Theme>(theme => ({
  main: {
    width: '100%',
    background: 'linear-gradient(180deg, #FFF3DB 0%, #FFFFFF 100%)',
  },
  singleImageBox: {
    height: '100%',
    width: '100%',
  },
  singleImage: {
    objectFit: 'cover',
    borderRadius: 20,
    width: '100%',
    height: 420,
    [theme.breakpoints.down('sm')]: {
      height: 320,
    },
    [theme.breakpoints.down('sm')]: {
      height: 200,
    },
  },
}));

export function CountryHeader(props: {
  countryId: string;
  countryName: string;
  countryImagePath: string;
}) {
  const classes = useStyles();

  const { data, error, loading } = useQuery(QUERY_GET_COUNTRY, {
    variables: { country_id: props.countryId },
  });

  return (
    <>
      <Box width="100%" bgcolor="#D5F6FF" className={classes.main}>
        <Container>
          <VerticalFlex>
            <TitleH1>{props.countryName} </TitleH1>
            <Box className={classes.singleImage}>
              <Img
                className={classes.singleImage}
                src={props.countryImagePath}
                loader={
                  <Skeleton
                    variant="rectangular"
                    className={classes.singleImage}
                  />
                }
              />
            </Box>
            <Box mb={2}>
              {loading && <LoaderCentred />}
              {!loading && !error && (
                <>
                  <Hidden xsUp>
                    <CountryInfoLinks country={data.country} />
                  </Hidden>

                  <Hidden only={['sm', 'md', 'lg', 'xl']}>
                    <Box mt={2}>
                      <TripCountryBox country={data.country} />
                    </Box>
                  </Hidden>
                </>
              )}
            </Box>
          </VerticalFlex>
        </Container>
      </Box>
    </>
  );
}

import { useAuth0 } from '@auth0/auth0-react';
import { Image, Maybe } from '../generated/user_graphql';
import { useEffect, useState } from 'react';

export function NumberToMonth(monthNumber: number) {
  switch (monthNumber) {
    case 1:
      return 'January';
    case 2:
      return 'February';
    case 3:
      return 'March';
    case 4:
      return 'April';
    case 5:
      return 'May';
    case 6:
      return 'Jun';
    case 7:
      return 'July';
    case 8:
      return 'August';
    case 9:
      return 'September';
    case 10:
      return 'October';
    case 11:
      return 'November';
    case 12:
      return 'December';
  }
}

export function getFourImages(images: Maybe<Image>[]) {
  let headerImage: string | null | undefined = null;
  let mainImage: string | null | undefined = null;
  let secondaryImage: string | null | undefined = null;
  let tertiaryImage: string | null | undefined = null;

  if (images) {
    // Get HEADER
    images.forEach(image => {
      if (image && image.header) {
        headerImage = image.path;
      }
    });

    if (!headerImage) {
      images.forEach(image => {
        if (image && image.main) {
          headerImage = image.path;
        }
      });
    }

    if (!headerImage) {
      images.forEach(image => {
        if (image && image.secondary) {
          headerImage = image.path;
        }
      });
    }

    if (!headerImage) {
      images.forEach(image => {
        if (image && image.tertiary) {
          headerImage = image.path;
        }
      });
    }

    if (!headerImage) {
      return [headerImage, mainImage, secondaryImage, tertiaryImage];
    }

    // get Main image
    if (headerImage) {
      images.forEach(image => {
        if (image && image.main && image.path !== headerImage) {
          mainImage = image.path;
        }
      });

      if (!mainImage) {
        images.forEach(image => {
          if (image && image.secondary && image.path !== headerImage) {
            mainImage = image.path;
          }
        });
      }

      if (!mainImage) {
        images.forEach(image => {
          if (image && image.tertiary && image.path !== headerImage) {
            mainImage = image.path;
          }
        });
      }
    }
    if (!mainImage) {
      return [headerImage, mainImage, secondaryImage, tertiaryImage];
    }

    // get Secondary image
    if (mainImage) {
      images.forEach(image => {
        if (
          image &&
          image.secondary &&
          image.path !== headerImage &&
          image.path !== mainImage
        ) {
          secondaryImage = image.path;
        }
      });

      if (!secondaryImage) {
        images.forEach(image => {
          if (
            image &&
            image.tertiary &&
            image.path !== headerImage &&
            image.path !== mainImage
          ) {
            secondaryImage = image.path;
          }
        });
      }
    }
    if (!secondaryImage) {
      return [headerImage, mainImage, secondaryImage, tertiaryImage];
    }

    // get Tertiary image
    if (secondaryImage) {
      images.forEach(image => {
        if (
          image &&
          image.tertiary &&
          image.path !== headerImage &&
          image.path !== mainImage &&
          image.path !== secondaryImage
        ) {
          tertiaryImage = image.path;
        }
      });
    }
  }

  return [headerImage, mainImage, secondaryImage, tertiaryImage];
}

export function getTwoImages(images: Maybe<Image>[]) {
  let mainImage: string | null | undefined = null;
  let secondaryImage: string | null | undefined = null;

  if (images) {
    // Get primary image
    images.forEach(image => {
      if (image && image.header) {
        mainImage = image.path;
      }
    });

    if (!mainImage) {
      images.forEach(image => {
        if (image && image.main) {
          mainImage = image.path;
        }
      });
    }

    if (!mainImage) {
      images.forEach(image => {
        if (image && image.secondary) {
          mainImage = image.path;
        }
      });
    }

    if (!mainImage) {
      images.forEach(image => {
        if (image && image.tertiary) {
          mainImage = image.path;
        }
      });
    }

    if (!mainImage) {
      return [mainImage, secondaryImage];
    }

    // Get secondary image
    if (mainImage) {
      images.forEach(image => {
        if (image && image.main && image.path !== mainImage) {
          secondaryImage = image.path;
        }
      });
    }

    if (!secondaryImage) {
      images.forEach(image => {
        if (image && image.secondary && image.path !== mainImage) {
          secondaryImage = image.path;
        }
      });
    }

    if (!secondaryImage) {
      images.forEach(image => {
        if (image && image.tertiary && image.path !== mainImage) {
          secondaryImage = image.path;
        }
      });
    }
  }
  return [mainImage, secondaryImage];
}

export function validateEmail(mail) {
  if (
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/.test(
      mail,
    )
  ) {
    return true;
  }
  // alert('You have entered an invalid email address!');
  return false;
}

export function returnFormatedDate(date: Date, addDays: number) {
  const inputDate = new Date(date);

  if (isNaN(inputDate.getTime())) {
    // If the input date is invalid, return an empty string
    return '';
  }
  const newDate = new Date(inputDate.setDate(inputDate.getDate() + addDays));

  const weekday = new Array(7);
  weekday[0] = 'Sun';
  weekday[1] = 'Mon';
  weekday[2] = 'Tue';
  weekday[3] = 'Wed';
  weekday[4] = 'Thu';
  weekday[5] = 'Fri';
  weekday[6] = 'Sat';

  const month = new Array(12);
  month[0] = '1';
  month[1] = '2';
  month[2] = '3';
  month[3] = '4';
  month[4] = '5';
  month[5] = '6';
  month[6] = '7';
  month[7] = '8';
  month[8] = '9';
  month[9] = '10';
  month[10] = '11';
  month[11] = '12';

  let displayDay: string | null = null;

  if (newDate) {
    displayDay =
      weekday[newDate.getDay()] +
      ' ' +
      newDate.getDate() +
      '/' +
      month[newDate.getMonth()] +
      '/' +
      newDate.getFullYear();
  }

  if (displayDay) {
    return displayDay;
  } else {
    return '';
  }
}

export const useUserRoles = () => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    const getUserRoles = async () => {
      if (isAuthenticated) {
        try {
          const token = await getAccessTokenSilently();
          const decodedToken = JSON.parse(atob(token.split('.')[1]));

          // Assuming roles are stored under a custom claim (namespace-based)
          const userRoles = decodedToken['https://rooutie.com/roles'] || [];
          setRoles(userRoles);
        } catch (err) {
          console.error('Error fetching roles:', err);
        }
      }
    };

    getUserRoles();
  }, [isAuthenticated, getAccessTokenSilently]);

  return roles;
};

export const isPublisher = roles => {
  return roles.includes('Publisher');
};

export const isAdmin = roles => {
  return roles.includes('Admin');
};

import { Auth0Provider } from '@auth0/auth0-react';
import { configureStore, Action } from '@reduxjs/toolkit';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThunkAction } from 'redux-thunk';

import { App } from './components/App';
import { AppWrapper } from './components/AppWrapper';
import accommodationSliceReducer from './store/AccommodationSlice';
import daysSliceReducer from './store/DaySlice';
import errorSliceReducer from './store/ErrorSlice';
import { publicTripSliceReducer } from './store/PublicTripSlice';
import stepsSliceReducer from './store/StepSlice';
import { tripSliceReducer, TripState } from './store/TripSlice';
import UnauthorizedApolloProvider from './UnAuthorizedApolloProvider';
import Auth0ProviderWithRedirectCallback from './AppoloProviderWithCallback';
import { userSliceReducer } from './store/UserSlice';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// The AppThunk type will help us in writing type definitions for thunk actions
export type AppThunk = ThunkAction<void, TripState, unknown, Action<string>>;

const store = configureStore({
  reducer: {
    publicTrip: publicTripSliceReducer,
    userTrip: tripSliceReducer,
    userTripDays: daysSliceReducer,
    userAddingStep: stepsSliceReducer,
    userAddingAccommodation: accommodationSliceReducer,
    errorModal: errorSliceReducer,
    user: userSliceReducer,
  },
  devTools: process.env.NODE_ENV !== 'development' ? false : true,
});

ReactDOM.render(
  <Router>
    <Auth0ProviderWithRedirectCallback>
      <UnauthorizedApolloProvider>
        <AppWrapper>
          <Provider store={store}>
            <App />
          </Provider>
        </AppWrapper>
      </UnauthorizedApolloProvider>
    </Auth0ProviderWithRedirectCallback>
  </Router>,
  document.getElementById('root'),
);

// Register the service worker
serviceWorkerRegistration.register();

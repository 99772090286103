import { useMutation } from '@apollo/client';
import { Card, CardMedia, Grid, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import LazyLoad from 'react-lazyload';

import { Maybe, Image, StepPlaceImage } from '../../../generated/user_graphql';
import { FETCH_IMAGE_URL } from '../../gql-user/getPlaceImage';

export function UserStepSingleImage(props: { images: Maybe<Image>[] }) {
  let selectedTripIdOnLoad: string | null = null;
  const params = new URLSearchParams(window.location.search);
  selectedTripIdOnLoad = params.get('selected-trip');
  return (
    <LazyLoad
      height={200}
      offset={150}
      overflow={selectedTripIdOnLoad ? true : false}
    >
      {props.images && props.images.length > 0 && (
        <ImagesBox>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Grid
              container
              spacing={1}
              direction="row"
              wrap="nowrap"
              sx={{ justifyContent: 'left' }}
            >
              {props.images.map((image, index) => (
                <React.Fragment key={index}>
                  <Grid item key={index}>
                    <UserImageCard image={image} index={index} />
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </Box>
        </ImagesBox>
      )}
    </LazyLoad>
  );
}

export function UserStepImages(props: { images: Maybe<Image>[] }) {
  let selectedTripIdOnLoad: string | null = null;
  const params = new URLSearchParams(window.location.search);
  selectedTripIdOnLoad = params.get('selected-trip');

  return (
    <LazyLoad
      height={200}
      offset={150}
      overflow={selectedTripIdOnLoad ? true : false}
    >
      {props.images && props.images.length > 0 && (
        <ImagesBox>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={theme => ({
                [theme.breakpoints.up('md')]: {
                  display: 'block',
                },
                [theme.breakpoints.down('md')]: {
                  display: 'none',
                },
              })}
            >
              <Grid
                container
                spacing={1}
                direction="row"
                wrap="nowrap"
                sx={{ justifyContent: 'left' }}
              >
                {props.images.map((image, index) => (
                  <React.Fragment key={index}>
                    {index < 3 && (
                      <Grid
                        item
                        xs={12}
                        sm={props.images.length === 1 ? 12 : 6}
                        md={props.images.length === 3 ? 12 : 6}
                        key={index}
                      >
                        <UserImageCard image={image} index={index} />
                      </Grid>
                    )}
                  </React.Fragment>
                ))}
              </Grid>
            </Box>
            <Box
              sx={theme => ({
                [theme.breakpoints.up('md')]: {
                  display: 'none',
                },
                [theme.breakpoints.down('md')]: {
                  display: 'block',
                },
              })}
            >
              <Grid
                container
                spacing={1}
                direction="row"
                wrap="nowrap"
                // sx={{ justifyContent: 'left' }}
              >
                {props.images.map((image, index) => (
                  <React.Fragment key={index}>
                    {index < 2 && (
                      <Grid
                        item
                        xs={12}
                        sm={props.images.length === 1 ? 12 : 6}
                        md={props.images.length === 3 ? 12 : 6}
                        key={index}
                      >
                        <UserImageCard image={image} index={index} />
                      </Grid>
                    )}
                  </React.Fragment>
                ))}
              </Grid>
            </Box>
          </Box>
        </ImagesBox>
      )}
    </LazyLoad>
  );
}

export function StepPlaceImages(props: { images: Maybe<StepPlaceImage>[] }) {
  let selectedTripIdOnLoad: string | null = null;
  const params = new URLSearchParams(window.location.search);
  selectedTripIdOnLoad = params.get('selected-trip');
  return (
    <LazyLoad
      height={200}
      offset={150}
      overflow={selectedTripIdOnLoad ? true : false}
    >
      {props.images && props.images.length > 0 && (
        <ImagesBox>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={theme => ({
                [theme.breakpoints.up('md')]: {
                  display: 'block',
                },
                [theme.breakpoints.down('md')]: {
                  display: 'none',
                },
              })}
            >
              <Grid
                container
                spacing={1}
                direction="row"
                wrap="nowrap"
                sx={{ justifyContent: 'left' }}
              >
                {props.images.map((image, index) => (
                  <React.Fragment key={index}>
                    {index < 3 && (
                      <Grid
                        item
                        xs={12}
                        sm={props.images.length === 1 ? 12 : 6}
                        md={props.images.length === 3 ? 12 : 6}
                        key={index}
                      >
                        <ImageCard image={image} index={index} />
                      </Grid>
                    )}
                  </React.Fragment>
                ))}
              </Grid>
            </Box>
            <Box
              sx={theme => ({
                [theme.breakpoints.up('md')]: {
                  display: 'none',
                },
                [theme.breakpoints.down('md')]: {
                  display: 'block',
                },
              })}
            >
              <Grid
                container
                spacing={1}
                direction="row"
                wrap="nowrap"
                // sx={{ justifyContent: 'left' }}
              >
                {props.images.map((image, index) => (
                  <React.Fragment key={index}>
                    {index < 2 && (
                      <Grid
                        item
                        xs={12}
                        sm={props.images.length === 1 ? 12 : 6}
                        md={props.images.length === 3 ? 12 : 6}
                        key={index}
                      >
                        <ImageCard image={image} index={index} />
                      </Grid>
                    )}
                  </React.Fragment>
                ))}
              </Grid>
            </Box>
          </Box>
        </ImagesBox>
      )}
    </LazyLoad>
  );
}

export function StepPlaceSingleImage(props: {
  images: Maybe<StepPlaceImage>[];
}) {
  let selectedTripIdOnLoad: string | null = null;
  const params = new URLSearchParams(window.location.search);
  selectedTripIdOnLoad = params.get('selected-trip');

  return (
    <LazyLoad
      height={200}
      offset={150}
      overflow={selectedTripIdOnLoad ? true : false}
    >
      {props.images && props.images.length > 0 && (
        <ImagesBox>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Grid
              container
              spacing={1}
              direction="row"
              wrap="nowrap"
              sx={{ justifyContent: 'left' }}
            >
              {props.images.map((image, index) => (
                <React.Fragment key={index}>
                  <Grid item key={index}>
                    <ImageCard image={image} index={index} />
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </Box>
        </ImagesBox>
      )}
    </LazyLoad>
  );
}

const ImagesBox = styled(Box)(({ theme }) => ({
  minWidth: 250,
  marginTop: 10,
  marginBottom: 5,
  display: 'flex',
  flexDirection: 'row',
  [theme.breakpoints.down(1100)]: {
    marginTop: 10,
    marginLeft: 0,
  },
}));

export const ImageCard = ({ image, index }) => {
  const [fetchImageUrl, { data, loading, error }] =
    useMutation(FETCH_IMAGE_URL);
  const [imageSrc, setImageSrc] = useState('');
  const [imageLoaded, setImageLoaded] = useState(false); // State to track image load status
  useEffect(() => {
    const cachedImage = sessionStorage.getItem(image.imageUrl);

    // Check if image is already cached
    if (cachedImage) {
      setImageSrc(cachedImage);
      setImageLoaded(true);
    } else {
      const fetchImage = async () => {
        try {
          const response = await fetchImageUrl({
            variables: { image_url: image.imageUrl },
          });
          if (response.data && response.data.fetchImage.imageData) {
            // Ensure the response contains base64 data in correct format
            const base64Data = `data:image/jpeg;base64,${response.data.fetchImage.imageData}`; // Adjust MIME type as needed
            setImageSrc(base64Data);
            setImageLoaded(true);
            sessionStorage.setItem(image.imageUrl, base64Data);
          }
        } catch (err) {
          console.error('Error fetching image URL:', err);
        }
      };
      fetchImage();
    }
  }, [fetchImageUrl, image.imageUrl]);

  if (imageSrc === '')
    return (
      <Skeleton
        variant="rectangular"
        sx={theme => ({
          height: 150,
          width: '100%',
          objectFit: 'cover',
          [theme.breakpoints.down('sm')]: {
            height: 130,
          },
        })}
      />
    );
  // if (error) return <div>Error loading image</div>;

  return (
    <Card
      sx={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Tooltip
        key={index}
        title={(image && image.title) || 'Image'}
        disableInteractive
        arrow
        placement="top-start"
      >
        <CardMedia
          component="img"
          src={imageSrc}
          alt="Fetched Image"
          onLoad={() => setImageLoaded(true)} // Set imageLoaded to true once the image is loaded
          sx={{
            maxHeight: 200,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            display: imageLoaded ? 'block' : 'none', // Hide the image until loaded
          }}
        />
      </Tooltip>
    </Card>
  );
};

const UserImageCard = ({ image, index }) => {
  const [imageLoaded, setImageLoaded] = useState(false); // State to track image load status

  return (
    <Card
      sx={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {!imageLoaded && (
        <Skeleton
          variant="rectangular"
          width="100%"
          height="100%"
          sx={{
            maxHeight: 200,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
      )}

      <CardMedia
        component="img"
        src={image!.path!}
        onLoad={() => setImageLoaded(true)} // Set imageLoaded to true once the image is loaded
        sx={{
          maxHeight: 200,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          display: imageLoaded ? 'block' : 'none', // Hide the image until loaded
        }}
      />
    </Card>
  );
};

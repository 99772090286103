import { Box, Container } from '@mui/material';
import React from 'react';

import { TextButton } from '../theme-components/Buttons';

export default function AppFooter() {
  return (
    <Box
      boxShadow={2}
      sx={{ position: 'absolute', width: '100%', height: 100 }}
    >
      <Container>
        <Box sx={{ paddingTop: 2, textAlign: 'center' }}>
          © 2021 rooutie.com &middot; All rights reserved
        </Box>
        <Box
          sx={theme => ({
            color: theme.palette.secondary.main,
            textAlign: 'center',
            paddingBottom: 2,
          })}
        >
          <ContactMeGate />
        </Box>
      </Container>
    </Box>
  );
}
// TODO: fix this in footer
// interface IContactMeGateProps {}
// interface IContactMeHrefProps {}

const ContactMeHref: React.FC<unknown> = props => {
  return (
    <div>
      <a href="mailto:adam@rooutie.com">adam@rooutie.com</a>
    </div>
  );
};

// Make user click a button to show email adderss via lazy loading
const ContactMeGate: React.FC<unknown> = props => {
  const [showingEmail, setShowingEmail] = React.useState(false);

  const email = showingEmail ? (
    <ContactMeHref />
  ) : (
    <TextButton onClick={() => setShowingEmail(true)}>
      Click for contact info
    </TextButton>
  );
  return (
    <React.Suspense fallback={<div>loading...</div>}>
      <div>{email}</div>
    </React.Suspense>
  );
};

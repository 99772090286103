import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  DefaultOptions,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context'; // Updated import
import { onError } from '@apollo/client/link/error';
import { useAuth0 } from '@auth0/auth0-react';
import { ReactNode } from 'react';

interface Props {
  readonly children?: ReactNode;
}

const AuthorizedApolloProvider = ({ children }: Props) => {
  const { getAccessTokenSilently } = useAuth0();

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        );
      });
    }
    if (networkError) {
      console.error(`[Network error]: ${networkError}`);
    }
  });

  const httpLink = new HttpLink({
    uri: process.env.REACT_APP_CONFIG_URL_LOCKED,
    fetchOptions: { credentials: 'same-origin' },
  });

  const defaultOptions: DefaultOptions = {
    watchQuery: {
      errorPolicy: 'all', // Correctly typed as 'all'
    },
    query: {
      errorPolicy: 'all', // Correctly typed as 'all'
    },
    mutate: {
      errorPolicy: 'all', // Correctly typed as 'all'
    },
  };

  const authLink = setContext(async (_, { headers }) => {
    try {
      const token = await getAccessTokenSilently();
      return {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      };
    } catch (error) {
      console.error('Error getting token:', error);
      return {
        headers: {
          ...headers,
        },
      };
    }
  });

  const apolloClient = new ApolloClient({
    link: errorLink.concat(authLink.concat(httpLink)),
    cache: new InMemoryCache(), // Ensure cache is set
    connectToDevTools: true,
    defaultOptions: defaultOptions,
  });

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default AuthorizedApolloProvider;

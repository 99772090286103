import { useMutation } from '@apollo/client';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import EditLocationAltTwoToneIcon from '@mui/icons-material/EditLocationAltTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import MapTwoToneIcon from '@mui/icons-material/MapTwoTone';
import PhotoSizeSelectActualTwoToneIcon from '@mui/icons-material/PhotoSizeSelectActualTwoTone';
import { Button, Tooltip, Box, Stack } from '@mui/material';
import Fade from '@mui/material/Fade';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { setDays } from '../../../store/DaySlice';
import { setErrorModalOpen, setErrorTitle } from '../../../store/ErrorSlice';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../theme-components/Buttons';
import { Loader } from '../../../theme-components/Loader';
import { MUTATION_DELETE_STEP } from '../../gql-user/deleteStepMutation';
import { VerticalFlex } from '../../helpers/flex';
import {
  SmallModalPaper,
  ModalDescription,
  ModalTitle,
  CenteredModal,
  MediumModalPaper,
} from '../../styling/modal';
import { isAdmin, isPublisher, useUserRoles } from '../../../utils/helpers';
import { MUTATION_PUBLISH_TRIP } from '../../gql-user/publishTrip';
import { setTrip } from '../../../store/TripSlice';
import { Trip } from '../../../generated/user_graphql';
import EditTripNameModal from '../manage-trip-modals/EditTripName';
import PublishedWithChangesTwoToneIcon from '@mui/icons-material/PublishedWithChangesTwoTone';
import UnpublishedTwoToneIcon from '@mui/icons-material/UnpublishedTwoTone';
import LuggageTwoToneIcon from '@mui/icons-material/LuggageTwoTone';
import EditTripDescriptionModal from '../manage-trip-modals/AddEditDescription';
import AddLocationAltTwoToneIcon from '@mui/icons-material/AddLocationAltTwoTone';
import AddStep from '../manage-trip-modals/add-step/AddStep';
import { AddStepToDay } from '../manage-trip-modals/add-step/AddStepToDayModal';
import { setStepType } from '../../../store/StepSlice';

interface ActionsDayProps {
  dayId: string;
  dayNumber: number;
  iconsOnly: boolean;
}

export const ActionsDay: React.FC<ActionsDayProps> = ({
  dayId,
  dayNumber,
  iconsOnly,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [accomodation, setAccomodation] = useState(false);
  const [transport, setTransport] = useState(false);
  const [place, setPlace] = useState(false);
  const [activity, setActivity] = useState(false);
  const [foodDrink, setFoodDrink] = useState(false);
  const [accomodationIcons, setAccomodationIcons] = useState(false);
  const [transportIcons, setTransportIcons] = useState(false);
  const [placeIcons, setPlaceIcons] = useState(false);
  const [activityIcons, setActivityIcons] = useState(false);
  const [foodDrinkIcons, setFoodDrinkIcons] = useState(false);

  const dispatch = useDispatch();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openMenu = Boolean(anchorEl);
  useEffect(() => {
    if (openMenu) {
      const handleTouchMove = () => {
        handleClose();
      };

      // Listen to touchmove events
      window.addEventListener('touchmove', handleTouchMove);

      // Cleanup the event listener when the menu is closed
      return () => {
        window.removeEventListener('touchmove', handleTouchMove);
      };
    }
  }, [openMenu]);

  const handleCloseModal = () => {
    handleClose();
    setPlace(false);
    setTransport(false);
    setAccomodation(false);
    setActivity(false);
    setFoodDrink(false);
    setPlaceIcons(false);
    setTransportIcons(false);
    setAccomodationIcons(false);
    setActivity(false);
    setFoodDrinkIcons(false);
  };

  return (
    <Box
      sx={theme => ({
        flexShrink: 0, // Prevent the button from shrinking
      })}
    >
      {!iconsOnly ? (
        <>
          <Button
            variant="contained"
            endIcon={
              <ArrowDropDownIcon
                sx={theme => ({
                  height: 17,
                  width: 17,
                  pointerEvents: 'none',
                })}
              />
            }
            onClick={handleClick}
            sx={theme => ({
              fontSize: 12,
              paddingTop: 0.5,
              paddingBottom: 0.5,
              paddingLeft: 1.75,
              paddingRight: 1.75,
              backgroundColor: '#36454F',
              borderRadius: 20,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              boxShadow: 'none', // Disable shadow
              '&:hover': {
                boxShadow: 'none',
                backgroundColor: '#4A5D6F',
              },
            })}
          >
            Add to Day {dayNumber}
            {iconsOnly && ': '}
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom', // Adjust vertical anchor
              horizontal: 'left', // Adjust horizontal anchor
            }}
            transformOrigin={{
              vertical: 'top', // Adjust vertical transform
              horizontal: 'left', // Adjust horizontal transform
            }}
          >
            <MenuItem
              onClick={() => {
                setPlace(true);
                setTransport(false);
                setAccomodation(false);
                setActivity(false);
                setFoodDrink(false);
                dispatch(setStepType('CATEGORY_PLACE'));
              }}
            >
              <AddStepToDay
                dayId={dayId}
                category="CATEGORY_PLACE"
                iconsOnly={false}
                handleCloseModal={handleCloseModal}
                modalOpen={place}
              />
            </MenuItem>
            <MenuItem
              onClick={() => {
                setPlace(false);
                setTransport(false);
                setAccomodation(false);
                setActivity(false);
                setFoodDrink(true);
                dispatch(setStepType('CATEGORY_FOOD_DRINK'));
              }}
            >
              <AddStepToDay
                dayId={dayId}
                category="CATEGORY_FOOD_DRINK"
                iconsOnly={false}
                handleCloseModal={handleCloseModal}
                modalOpen={foodDrink}
              />
            </MenuItem>
            <MenuItem
              onClick={() => {
                setPlace(false);
                setTransport(false);
                setAccomodation(true);
                setActivity(false);
                setFoodDrink(false);
                dispatch(setStepType('CATEGORY_ACCOMMODATION'));
              }}
            >
              <AddStepToDay
                dayId={dayId}
                category="CATEGORY_ACCOMMODATION"
                iconsOnly={false}
                handleCloseModal={handleCloseModal}
                modalOpen={accomodation}
              />
            </MenuItem>
            <MenuItem
              onClick={() => {
                setPlace(false);
                setTransport(true);
                setAccomodation(false);
                setActivity(false);
                setFoodDrink(false);
                dispatch(setStepType('CATEGORY_TRANSPORT'));
              }}
            >
              <AddStepToDay
                dayId={dayId}
                category="CATEGORY_TRANSPORT"
                iconsOnly={false}
                handleCloseModal={handleCloseModal}
                modalOpen={transport}
              />
            </MenuItem>
            <MenuItem
              onClick={() => {
                setPlace(false);
                setTransport(false);
                setAccomodation(false);
                setActivity(true);
                setFoodDrink(false);
                dispatch(setStepType('CATEGORY_ACTIVITY'));
              }}
            >
              <AddStepToDay
                dayId={dayId}
                category="CATEGORY_ACTIVITY"
                iconsOnly={false}
                handleCloseModal={handleCloseModal}
                modalOpen={activity}
              />
            </MenuItem>
            <Tooltip title="Coming soon!">
              <span>
                {/* Wrapper required for disabled MenuItem to allow Tooltip */}
                <MenuItem disabled>
                  <ListItemIcon>
                    <DeleteTwoToneIcon />
                  </ListItemIcon>
                  Delete day
                </MenuItem>
              </span>
            </Tooltip>
          </Menu>
        </>
      ) : (
        <Box
          sx={{
            fontSize: 18,
            fontWeight: 600,
            marginLeft: 2,
            padding: '10px 20px',
            backgroundColor: '#F0F0F0',
            borderRadius: 1,
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            // transition: 'box-shadow 0.3s ease, transform 0.3s ease', // Smooth transitions
            // '&:hover': {
            //   boxShadow: '0px 6px 16px rgba(0, 0, 0, 0.15)', // Slightly stronger shadow on hover
            //   transform: 'translateY(-2px)', // Lift on hover
            // },
          }}
        >
          <Stack direction="column" alignItems="center" width="100%">
            <Box
              sx={{
                fontSize: 20,
                fontWeight: 700,
                color: '#333',
                marginBottom: 1,
              }}
            >
              Add to Day {dayNumber}
            </Box>
            <Stack
              direction="row"
              width="100%"
              alignItems="center"
              justifyContent="center"
              flexWrap="wrap"
              spacing={2}
              rowGap={1}
              sx={{
                fontSize: 16,
                fontWeight: 500,
                color: '#555',
                padding: '8px 16px',
                backgroundColor: '#FFFFFF',
                borderRadius: 6,
                boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.05)',
              }}
            >
              <Box
                onClick={() => {
                  setPlaceIcons(true);
                  setTransportIcons(false);
                  setAccomodationIcons(false);
                  setActivityIcons(false);
                  setFoodDrinkIcons(false);
                  dispatch(setStepType('CATEGORY_PLACE'));
                }}
                sx={{
                  cursor: 'pointer', // Ensure pointer cursor on hover
                }}
              >
                <AddStepToDay
                  dayId={dayId}
                  category="CATEGORY_PLACE"
                  iconsOnly={true}
                  handleCloseModal={handleCloseModal}
                  modalOpen={placeIcons}
                />
              </Box>
              <Box
                onClick={() => {
                  setPlaceIcons(false);
                  setTransportIcons(false);
                  setAccomodationIcons(false);
                  setActivityIcons(false);
                  setFoodDrinkIcons(true);
                  dispatch(setStepType('CATEGORY_FOOD_DRINK'));
                }}
                sx={{
                  cursor: 'pointer', // Ensure pointer cursor on hover
                }}
              >
                <AddStepToDay
                  dayId={dayId}
                  category="CATEGORY_FOOD_DRINK"
                  iconsOnly={true}
                  handleCloseModal={handleCloseModal}
                  modalOpen={foodDrinkIcons}
                />
              </Box>
              <Box
                onClick={() => {
                  setPlaceIcons(false);
                  setTransportIcons(false);
                  setAccomodationIcons(true);
                  setActivityIcons(false);
                  setFoodDrinkIcons(false);
                  dispatch(setStepType('CATEGORY_ACCOMMODATION'));
                }}
                sx={{
                  cursor: 'pointer', // Ensure pointer cursor on hover
                }}
              >
                <AddStepToDay
                  dayId={dayId}
                  category="CATEGORY_ACCOMMODATION"
                  iconsOnly={true}
                  handleCloseModal={handleCloseModal}
                  modalOpen={accomodationIcons}
                />
              </Box>
              <Box
                onClick={() => {
                  setPlaceIcons(false);
                  setTransportIcons(true);
                  setAccomodationIcons(false);
                  setActivityIcons(false);
                  setFoodDrinkIcons(false);
                  dispatch(setStepType('CATEGORY_TRANSPORT'));
                }}
                sx={{
                  cursor: 'pointer', // Ensure pointer cursor on hover
                }}
              >
                <AddStepToDay
                  dayId={dayId}
                  category="CATEGORY_TRANSPORT"
                  iconsOnly={true}
                  handleCloseModal={handleCloseModal}
                  modalOpen={transportIcons}
                />
              </Box>
              <Box
                onClick={() => {
                  setPlaceIcons(false);
                  setTransportIcons(false);
                  setAccomodationIcons(false);
                  setActivityIcons(true);
                  setFoodDrinkIcons(false);
                  dispatch(setStepType('CATEGORY_ACTIVITY'));
                }}
                sx={{
                  cursor: 'pointer', // Ensure pointer cursor on hover
                }}
              >
                <AddStepToDay
                  dayId={dayId}
                  category="CATEGORY_ACTIVITY"
                  iconsOnly={true}
                  handleCloseModal={handleCloseModal}
                  modalOpen={activityIcons}
                />
              </Box>
            </Stack>
          </Stack>
        </Box>
      )}
    </Box>
  );
};

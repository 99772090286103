import { Box } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { Country } from '../../generated/public_graphql';
import { Body1 } from '../../theme-components/Typography';
import { HorizontalFlex, VerticalFlex } from '../helpers/flex';
import { RenderHtmlFromResponse } from '../RenderHtmlFromResponse';

import {
  SideBarHeader,
  SideBarIcon,
  SideBarLink,
  SideBarTitleDivider,
} from './TripSidebar';

const useStyles = makeStyles(theme =>
  createStyles({
    locationContent: {
      marginBottom: 10,
      marginRight: 5,
      fontWeight: 600,
      fontSize: 18,
      color: 'black',
      [theme.breakpoints.down('sm')]: {
        marginBottom: 5,
      },
    },
    location: {
      marginBottom: 10,
      marginRight: 5,
      fontWeight: 400,
      fontSize: 18,
      color: theme.palette.secondary.main,
      [theme.breakpoints.down('sm')]: {
        marginBottom: 5,
      },
    },
  }),
);

interface Props {
  readonly countries: [Country];
}

export function TripCountries(props: Props) {
  const classes = useStyles();

  // For now we will support only one country per
  // trip untill we have designs to show multiple
  const country = props.countries[0];

  return (
    <VerticalFlex>
      <HorizontalFlex>
        <SideBarIcon $iconType={country.name} />
        <SideBarHeader>{country.name}</SideBarHeader>
      </HorizontalFlex>
      <SideBarTitleDivider />

      {country && country.region && (
        <HorizontalFlex>
          <Box className={classes.locationContent}>Located in: </Box>
          {country && country.subRegion && (
            <Box className={classes.location}>{country.subRegion.name},</Box>
          )}
          <Box className={classes.location}>{country.region.name}</Box>
        </HorizontalFlex>
      )}
      {country.description && (
        <Body1>
          <RenderHtmlFromResponse content={country.description} />
        </Body1>
      )}
      <br />
      {country.url && (
        <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <SideBarLink
            url={country.url}
            copy={'More info about ' + country.name}
          />
        </Box>
      )}
    </VerticalFlex>
  );
}

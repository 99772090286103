import Backdrop from '@material-ui/core/Backdrop';
import FilterTwoToneIcon from '@mui/icons-material/FilterTwoTone';
import { Box } from '@mui/material';
import Fade from '@mui/material/Fade';
import React, { useEffect } from 'react';

import { Trip } from '../../../generated/user_graphql';
import { LinkButton, PrimaryButton } from '../../../theme-components/Buttons';
import { VerticalFlex } from '../../helpers/flex';
import {
  ModalTitle,
  MediumModalPaper,
  CenteredModal,
} from '../../styling/modal';
import { ManageImages } from '../../Upload';

export default function AddTripImagesModal(props: {
  tripId: string;
  trip: Trip;
}) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const disableScroll = e => e.preventDefault();
    if (open) {
      // Lock body scroll
      document.body.style.overflow = 'hidden';
      document.addEventListener('touchmove', disableScroll, {
        passive: false,
      });
    } else {
      // Unlock body scroll
      document.body.style.overflow = '';
      document.removeEventListener('touchmove', disableScroll);
    }

    // Cleanup function to restore scroll when component unmounts or modal closes
    return () => {
      document.body.style.overflow = '';
      document.removeEventListener('touchmove', disableScroll);
    };
  }, [open]);

  return (
    <>
      <Box
        sx={theme => ({
          position: 'absolute',
          // width: '100%',
          bottom: 5,
          right: 5,
          paddingLeft: 1,
          paddingRight: 1,
          paddingTop: 1,
          paddingBottom: 1,
          // width: 39,
          // height: 39,
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          borderRadius: 20,
          zIndex: 2,
          [theme.breakpoints.down('md')]: {
            bottom: 5,
            right: 5,
          },
        })}
      >
        <Box
          sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
        >
          <LinkButton
            onClick={() => {
              gtag('event', 'edit-trip-images-button');
              handleOpen();
            }}
          >
            <FilterTwoToneIcon
              sx={theme => ({
                marginRight: 0.5,
                width: 20,
                [theme.breakpoints.down('md')]: {
                  marginRight: 0.5,
                },
              })}
            />
            Edit images
          </LinkButton>
        </Box>
      </Box>

      <CenteredModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <MediumModalPaper>
            <ModalTitle>Add trip header images</ModalTitle>
            {props.trip && (
              <ManageImages tripId={props.tripId} images={props.trip.images} />
            )}
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
              mt={2}
            >
              <VerticalFlex>
                <PrimaryButton
                  onClick={() => {
                    gtag('event', 'add-trip-images-done');
                    handleClose();
                  }}
                >
                  Done
                </PrimaryButton>
              </VerticalFlex>
            </Box>
          </MediumModalPaper>
        </Fade>
      </CenteredModal>
    </>
  );
}

import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { ReactNode } from 'react';

interface Props {
  readonly children?: ReactNode;
}

const UnauthorizedApolloProvider = ({ children }: Props) => {
  const httpLink = new HttpLink({
    uri: process.env.REACT_APP_CONFIG_URL,
  });

  const apolloClient = new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache(),
  });

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default UnauthorizedApolloProvider;

import gql from 'graphql-tag';

export const MUTATION_ADD_ACCOMMODATION_TO_STEP = gql`
  mutation createUserAccommodation($step_id: String!, $name: String!) {
    createUserAccommodation(step_id: $step_id, name: $name) {
      id
      days {
        id
        dayNumber
        cities {
          cityName
        }
        steps {
          id
          title
          type
          description
          stepNumber
          latitude
          longitude
          locationUrl
          duration
          url
          city
          country
          countryCode
          region
          placeImages {
            id
            title
            contributorId
            imageUrl
          }
          place {
            id
            lat
            lng
            city
            country
            countryCode
            region
            externalPlaceId
            title
            category
            noOfReviews
            rating
            description
            quickFact
            phoneNumber
            website
            address
            openingHours {
              weekDay
              hours
            }
            images {
              title
              contributorId
              imageUrl
            }
          }
          images {
            id
            path
            header
            main
            secondary
            tertiary
          }
          accommodations {
            id
            name
            description
            url
            budget
            latitude
            longitude
            locationUrl
            images {
              id
              path
              header
              main
              secondary
              tertiary
            }
          }
          recommendations {
            id
            name
            type
            description
            url
            latitude
            longitude
            locationUrl
          }
        }
      }
      coordinates {
        id
        title
        latitude
        longitude
      }
    }
  }
`;

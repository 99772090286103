import { Hidden } from '@material-ui/core';
import { Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import { createStyles, makeStyles } from '@mui/styles';
import { Img } from 'react-image';

import { Trip } from '../../generated/public_graphql';
import { getFourImages } from '../../utils/helpers';

import AddTripImagesModal from './manage-trip-modals/AddTripImages';

interface TripHeaderProps {
  readonly trip: Trip;
}

const useStyles = makeStyles(theme =>
  createStyles({
    imagesBox: {
      marginTop: 10,
      marginBottom: 10,
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center', // Ensures images are centered horizontally
      alignItems: 'center',
    },
    // Single image
    singleImageBox: {
      borderRadius: 10,
      width: '100%',
      height: 350,
      overflow: 'hidden',
      [theme.breakpoints.down('md')]: {
        height: 325,
      },
      [theme.breakpoints.down('sm')]: {
        height: 280,
      },
      [theme.breakpoints.down('xs')]: {
        height: 245,
      },
    },
    singleImage: {
      borderRadius: 10,
      width: '100%',
      height: '100%',
      objectFit: 'cover', // Ensures the image fills the box without distortion
      [theme.breakpoints.down('md')]: {
        height: '100%', // Stretch to fill the available height
      },
      [theme.breakpoints.down('sm')]: {
        height: '100%', // Same for small screens
      },
      [theme.breakpoints.down('xs')]: {
        height: '100%', // And for extra small screens
      },
    },
    // Left image
    leftImageBox: {
      borderTopLeftRadius: 10,
      borderBottomLeftRadius: 10,
      width: '100%',
      marginRight: 5,
      height: 350,
      [theme.breakpoints.down('md')]: {
        height: 325,
      },
      [theme.breakpoints.down('sm')]: {
        height: 280,
      },

      [theme.breakpoints.down('xs')]: {
        borderTopRightRadius: 10,
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        height: 230,
        marginRight: 0,
      },
    },
    leftImage: {
      borderTopLeftRadius: 10,
      borderBottomLeftRadius: 10,
      width: '100%',
      height: 350,
      [theme.breakpoints.down('md')]: {
        height: 325,
      },
      [theme.breakpoints.down('sm')]: {
        height: 280,
      },
      [theme.breakpoints.down('xs')]: {
        borderTopRightRadius: 0,
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 0,
        height: 230,
        marginRight: 0,
      },
    },
    // Right image
    rightImageBox: {
      borderTopRightRadius: 10,
      borderBottomRightRadius: 10,
      width: '45%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('xs')]: {
        marginLeft: 5,
        flexDirection: 'row',
        width: '100%',
      },
    },
    rightImage: {
      borderTopRightRadius: 10,
      borderBottomRightRadius: 10,
      width: '100%',
      height: 350,
      [theme.breakpoints.down('md')]: {
        height: 325,
      },
      [theme.breakpoints.down('sm')]: {
        height: 280,
      },
      [theme.breakpoints.down('xs')]: {
        height: 230,
      },
    },
    // Two right images
    rightImagesBox: {
      borderTopRightRadius: 10,
      borderBottomRightRadius: 10,
      width: '40%',
      display: 'flex',
      flexDirection: 'column',
      height: 350,
      [theme.breakpoints.down('md')]: {
        height: 325,
      },
      [theme.breakpoints.down('sm')]: {
        height: 280,
      },
      [theme.breakpoints.down('xs')]: {
        // display: 'none',
        // width: 0,
        // height: 0,
        marginLeft: 5,
      },
    },
    topRightImage: {
      borderTopRightRadius: 10,
      width: '100%',
      height: 180,
      marginBottom: 5,
      [theme.breakpoints.down('md')]: {
        height: 175,
      },
      [theme.breakpoints.down('sm')]: {
        height: 140,
      },
      [theme.breakpoints.down('xs')]: {
        borderTopRightRadius: 10,
        borderBottomLeftRadius: 0,
        marginBottom: 5,
        // marginRight: 5,
        height: 115,
      },
    },
    bottomRightImage: {
      borderBottomRightRadius: 10,
      width: '100%',
      height: 165,
      [theme.breakpoints.down('md')]: {
        height: 145,
      },
      [theme.breakpoints.down('sm')]: {
        height: 135,
      },
      [theme.breakpoints.down('xs')]: {
        height: 110,
      },
    },
    // Right when middle displayed
    rightImageBoxWhenMiddleDisplayed: {
      borderTopRightRadius: 10,
      borderBottomRightRadius: 10,
      width: '40%',
      display: 'flex',
      height: 350,
      [theme.breakpoints.down('md')]: {
        height: 325,
      },
      [theme.breakpoints.down('sm')]: {
        height: 280,
      },
      [theme.breakpoints.down('xs')]: {
        height: 130,
        width: '100%',
        flexDirection: 'column',
      },
    },
    rightImageWhenMiddleDisplayed: {
      borderTopRightRadius: 10,
      borderBottomRightRadius: 10,
      width: '100%',
      height: 350,
      [theme.breakpoints.down('md')]: {
        height: 325,
      },
      [theme.breakpoints.down('sm')]: {
        height: 280,
      },
      [theme.breakpoints.down('xs')]: {
        borderTopRightRadius: 0,
        borderBottomLeftRadius: 10,
        marginBottom: 0,
        height: 130,
      },
    },
    // Middle images
    middleImagesBox: {
      borderTopRightRadius: 10,
      borderBottomRightRadius: 10,
      marginRight: 5,
      width: '30%',
      display: 'flex',
      flexDirection: 'column',
      height: 350,
      [theme.breakpoints.down('md')]: {
        height: 325,
      },
      [theme.breakpoints.down('sm')]: {
        marginRight: 0,
        height: 280,
      },
      [theme.breakpoints.down('xs')]: {
        height: 230,
        width: '40%',
        marginLeft: 5,
        marginRight: 0,
      },
    },
    middleTopImage: {
      maxWidth: 300,
      width: '100%',
      marginBottom: 5,
      height: 180,
      [theme.breakpoints.down('md')]: {
        height: 175,
      },
      [theme.breakpoints.down('sm')]: {
        height: 145,
        borderTopRightRadius: 10,
      },
      [theme.breakpoints.down('xs')]: {
        height: 115,
      },
    },
    middleBottomImage: {
      maxWidth: 300,
      width: '100%',
      height: 165,
      [theme.breakpoints.down('md')]: {
        height: 145,
      },
      [theme.breakpoints.down('sm')]: {
        height: 130,
        borderBottomRightRadius: 10,
      },
      [theme.breakpoints.down('xs')]: {
        height: 110,
      },
    },
  }),
);

export function CreateTripHeaderImages(props: TripHeaderProps) {
  const classes = useStyles();

  let header: string | null | undefined = null;
  let main: string | null | undefined = null;
  let secondary: string | null | undefined = null;
  let tertiary: string | null | undefined = null;
  let tripImages: null[] = [];

  if (props.trip && props.trip.images && props.trip.images.length > 0) {
    tripImages = getFourImages(props.trip.images);
  }
  header = tripImages[0];
  main = tripImages[1];
  secondary = tripImages[2];
  tertiary = tripImages[3];

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
      }}
    >
      {props.trip &&
      props.trip.images &&
      props.trip.images &&
      props.trip.images.length > 0 ? (
        <>
          {header && main && secondary && tertiary && (
            <Box className={classes.imagesBox}>
              <Box className={classes.leftImageBox}>
                <Img
                  className={classes.leftImage}
                  src={header}
                  loader={
                    <Skeleton
                      variant="rectangular"
                      className={classes.leftImage}
                    />
                  }
                />
              </Box>
              <Box className={classes.middleImagesBox}>
                <Box className={classes.middleTopImage}>
                  <Img
                    className={classes.middleTopImage}
                    src={secondary}
                    loader={
                      <Skeleton
                        variant="rectangular"
                        className={classes.middleTopImage}
                      />
                    }
                  />
                </Box>
                <Box className={classes.middleBottomImage}>
                  <Img
                    className={classes.middleBottomImage}
                    src={tertiary}
                    loader={
                      <Skeleton
                        variant="rectangular"
                        className={classes.middleBottomImage}
                      />
                    }
                  />
                </Box>
              </Box>
              <Hidden only={['xs', 'sm']}>
                <Box className={classes.rightImageBoxWhenMiddleDisplayed}>
                  <Img
                    className={classes.rightImageWhenMiddleDisplayed}
                    src={main}
                    loader={
                      <Skeleton
                        variant="rectangular"
                        className={classes.rightImageWhenMiddleDisplayed}
                      />
                    }
                  />
                </Box>
              </Hidden>
            </Box>
          )}
          {header && main && secondary && !tertiary && (
            <Box className={classes.imagesBox}>
              <Box className={classes.leftImageBox}>
                <Img
                  className={classes.leftImage}
                  src={header}
                  loader={
                    <Skeleton
                      variant="rectangular"
                      className={classes.leftImage}
                    />
                  }
                />
              </Box>
              <Box className={classes.rightImagesBox}>
                <Box className={classes.topRightImage}>
                  <Img
                    className={classes.topRightImage}
                    src={main}
                    loader={
                      <Skeleton
                        variant="rectangular"
                        className={classes.topRightImage}
                      />
                    }
                  />
                </Box>
                <Box className={classes.bottomRightImage}>
                  <Img
                    className={classes.bottomRightImage}
                    src={secondary}
                    loader={
                      <Skeleton
                        variant="rectangular"
                        className={classes.bottomRightImage}
                      />
                    }
                  />
                </Box>
              </Box>
            </Box>
          )}
          {header && main && !secondary && !tertiary && (
            <>
              <Box className={classes.imagesBox}>
                <Box className={classes.leftImageBox}>
                  <Img
                    className={classes.leftImage}
                    src={header}
                    loader={
                      <Skeleton
                        variant="rectangular"
                        className={classes.leftImage}
                      />
                    }
                  />
                </Box>
                <Box className={classes.rightImageBox}>
                  <Img
                    className={classes.rightImage}
                    src={main}
                    loader={
                      <Skeleton
                        variant="rectangular"
                        className={classes.rightImage}
                      />
                    }
                  />
                </Box>
              </Box>
            </>
          )}

          {header && !main && !secondary && !tertiary && (
            <>
              <Box className={classes.imagesBox}>
                <Box className={classes.singleImageBox}>
                  <Img
                    className={classes.singleImage}
                    src={header}
                    loader={
                      <Skeleton
                        variant="rectangular"
                        className={classes.singleImage}
                      />
                    }
                  />
                </Box>
              </Box>
            </>
          )}
        </>
      ) : (
        <>
          <Box className={classes.imagesBox}>
            <Box className={classes.singleImageBox}>
              <Img
                className={classes.singleImage}
                src={'/assets/image/placeholder-svg.svg'}
                loader={
                  <Skeleton
                    variant="rectangular"
                    className={classes.singleImage}
                  />
                }
              />
            </Box>
          </Box>
        </>
      )}
      <AddTripImagesModal tripId={props.trip.id} trip={props.trip} />
    </Box>
  );
}

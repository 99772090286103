import { useAuth0 } from '@auth0/auth0-react';
import { Box, Button } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';

const LogoutButton = () => {
  const { logout, isAuthenticated } = useAuth0();

  return (
    <Box>
      {isAuthenticated && (
        // <Link style={{}} onClick={() => logout()}>
        //   logout
        // </Link>
        <Button
          variant="outlined"
          color="error"
          startIcon={<LogoutIcon />}
          onClick={() => logout()}
        >
          Logout
        </Button>
      )}
    </Box>
  );
};

export default LogoutButton;

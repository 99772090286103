// import { Theme } from '@mui/material';
// import Box from '@mui/material/Box';
// import Skeleton from '@mui/material/Skeleton';
// import React from 'react';
// import { Img } from 'react-image';
// import LazyLoad from 'react-lazyload';

// import { Maybe, Image } from '../../generated/public_graphql';
// import { getTwoImages } from '../../utils/helpers';
// import { createStyles, makeStyles } from '@material-ui/core';
// import { useTheme } from '@mui/material/styles';

// const useStyles = makeStyles(theme =>
//   createStyles({
//     imagesBox: {
//       minWidth: 250,
//       marginBottom: 10,
//       display: 'flex',
//       flexDirection: 'row',
//     },
//     // Single image
//     singleImageBox: {
//       height: '100%',
//       width: '100%',
//     },
//     singleImage: {
//       objectFit: 'cover',
//       borderRadius: 20,
//       width: '100%',
//       height: 320,
//       [theme.breakpoints.down('sm')]: {
//         height: 200,
//       },
//     },
//     primaryImageBox: {
//       height: '100%',
//       width: '100%',
//       marginRight: 5,
//     },
//     secondaryImageBox: {
//       height: '100%',
//       width: '100%',
//     },
//     primaryImage: {
//       objectFit: 'cover',
//       borderTopLeftRadius: 20,
//       borderBottomLeftRadius: 20,
//       width: '100%',
//       height: 220,
//       [theme.breakpoints.down('sm')]: {
//         height: 140,
//       },
//     },
//     secondaryImage: {
//       objectFit: 'cover',
//       borderTopRightRadius: 20,
//       borderBottomRightRadius: 20,
//       width: '100%',
//       height: 220,
//       [theme.breakpoints.down('sm')]: {
//         height: 140,
//       },
//     },
//   }),
// );

// export function StepImages(props: { images: Maybe<Image>[] }) {
//   const classes = useStyles();

//   let main: string | null | undefined = null;
//   let secondary: string | null | undefined = null;
//   let tripImages: null[] = [];

//   if (props.images && props.images.length > 0) {
//     tripImages = getTwoImages(props.images);
//   }
//   main = tripImages[0];
//   secondary = tripImages[1];

//   return (
//     <LazyLoad height={200} offset={150}>
//       {props.images ? (
//         <>
//           {main && secondary && (
//             <Box className={classes.imagesBox}>
//               <Box className={classes.primaryImageBox}>
//                 <Img
//                   className={classes.primaryImage}
//                   src={main}
//                   loader={
//                     <Skeleton
//                       variant="rectangular"
//                       className={classes.primaryImage}
//                     />
//                   }
//                 />
//               </Box>
//               <Box className={classes.secondaryImageBox}>
//                 <Img
//                   className={classes.secondaryImage}
//                   src={secondary}
//                   loader={
//                     <Skeleton
//                       variant="rectangular"
//                       className={classes.secondaryImage}
//                     />
//                   }
//                 />
//               </Box>
//             </Box>
//           )}
//           {main && !secondary && (
//             <Box className={classes.imagesBox}>
//               <Box className={classes.singleImage}>
//                 <Img
//                   className={classes.singleImage}
//                   src={main}
//                   loader={
//                     <Skeleton
//                       variant="rectangular"
//                       className={classes.singleImage}
//                     />
//                   }
//                 />
//               </Box>
//             </Box>
//           )}
//         </>
//       ) : null}
//     </LazyLoad>
//   );
// }

// export function AccommodationImages(props: { images: Maybe<Image>[] }) {
//   const classes = useStyles();

//   let main: string | null | undefined = null;
//   let secondary: string | null | undefined = null;
//   let tripImages: null[] = [];

//   if (props.images && props.images.length > 0) {
//     tripImages = getTwoImages(props.images);
//   }
//   main = tripImages[0];
//   secondary = tripImages[1];

//   return (
//     <LazyLoad height={200} offset={150}>
//       {props.images ? (
//         <>
//           {main && secondary && (
//             <Box className={classes.imagesBox}>
//               <Box className={classes.primaryImageBox}>
//                 <Img
//                   className={classes.primaryImage}
//                   src={main}
//                   loader={
//                     <Skeleton
//                       variant="rectangular"
//                       className={classes.primaryImage}
//                     />
//                   }
//                 />
//               </Box>
//               <Box className={classes.secondaryImageBox}>
//                 <Img
//                   className={classes.secondaryImage}
//                   src={secondary}
//                   loader={
//                     <Skeleton
//                       variant="rectangular"
//                       className={classes.secondaryImage}
//                     />
//                   }
//                 />
//               </Box>
//             </Box>
//           )}
//           {main && !secondary && (
//             <Box className={classes.imagesBox}>
//               <Box className={classes.singleImage}>
//                 <Img
//                   className={classes.singleImage}
//                   src={main}
//                   loader={
//                     <Skeleton
//                       variant="rectangular"
//                       className={classes.singleImage}
//                     />
//                   }
//                 />
//               </Box>
//             </Box>
//           )}
//         </>
//       ) : null}
//     </LazyLoad>
//   );
// }
// import { styled } from '@material-ui/core';
import { Card, CardMedia, Grid, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import LazyLoad from 'react-lazyload';

import { Maybe, Image, StepPlaceImage } from '../../generated/public_graphql';
import { useMutation } from '@apollo/client';
import { FETCH_IMAGE_URL } from '../gql-user/getPlaceImage';
import { ImageCard } from '../create-trip/edit-trip/StepImages';

export function UserStepSingleImage(props: { images: Maybe<Image>[] }) {
  let selectedTripIdOnLoad: string | null = null;
  const params = new URLSearchParams(window.location.search);
  selectedTripIdOnLoad = params.get('selected-trip');
  return (
    <LazyLoad
      height={200}
      offset={150}
      overflow={selectedTripIdOnLoad ? true : false}
    >
      {props.images && props.images.length > 0 && (
        <ImagesBox>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Grid
              container
              spacing={1}
              direction="row"
              wrap="nowrap"
              sx={{ justifyContent: 'left' }}
            >
              {props.images.map((image, index) => (
                <React.Fragment key={index}>
                  <Grid item key={index}>
                    <UserImageCard image={image} index={index} />
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </Box>
        </ImagesBox>
      )}
    </LazyLoad>
  );
}

export function UserStepImages(props: { images: Maybe<Image>[] }) {
  let selectedTripIdOnLoad: string | null = null;
  const params = new URLSearchParams(window.location.search);
  selectedTripIdOnLoad = params.get('selected-trip');

  return (
    <LazyLoad
      height={200}
      offset={150}
      overflow={selectedTripIdOnLoad ? true : false}
    >
      {props.images && props.images.length > 0 && (
        <ImagesBox>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={theme => ({
                [theme.breakpoints.up('md')]: {
                  display: 'block',
                },
                [theme.breakpoints.down('md')]: {
                  display: 'none',
                },
              })}
            >
              <Grid
                container
                spacing={1}
                direction="row"
                wrap="nowrap"
                sx={{ justifyContent: 'left' }}
              >
                {props.images.map((image, index) => (
                  <React.Fragment key={index}>
                    {index < 3 && (
                      <Grid
                        item
                        xs={12}
                        sm={props.images.length === 1 ? 12 : 6}
                        md={props.images.length === 3 ? 12 : 6}
                        key={index}
                      >
                        <UserImageCard image={image} index={index} />
                      </Grid>
                    )}
                  </React.Fragment>
                ))}
              </Grid>
            </Box>
            <Box
              sx={theme => ({
                [theme.breakpoints.up('md')]: {
                  display: 'none',
                },
                [theme.breakpoints.down('md')]: {
                  display: 'block',
                },
              })}
            >
              <Grid
                container
                spacing={1}
                direction="row"
                wrap="nowrap"
                // sx={{ justifyContent: 'left' }}
              >
                {props.images.map((image, index) => (
                  <React.Fragment key={index}>
                    {index < 2 && (
                      <Grid
                        item
                        xs={12}
                        sm={props.images.length === 1 ? 12 : 6}
                        md={props.images.length === 3 ? 12 : 6}
                        key={index}
                      >
                        <UserImageCard image={image} index={index} />
                      </Grid>
                    )}
                  </React.Fragment>
                ))}
              </Grid>
            </Box>
          </Box>
        </ImagesBox>
      )}
    </LazyLoad>
  );
}

export function StepPlaceImages(props: { images: Maybe<StepPlaceImage>[] }) {
  let selectedTripIdOnLoad: string | null = null;
  const params = new URLSearchParams(window.location.search);
  selectedTripIdOnLoad = params.get('selected-trip');
  return (
    <LazyLoad
      height={200}
      offset={150}
      overflow={selectedTripIdOnLoad ? true : false}
    >
      {props.images && props.images.length > 0 && (
        <ImagesBox>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={theme => ({
                [theme.breakpoints.up('md')]: {
                  display: 'block',
                },
                [theme.breakpoints.down('md')]: {
                  display: 'none',
                },
              })}
            >
              <Grid
                container
                spacing={1}
                direction="row"
                wrap="nowrap"
                sx={{ justifyContent: 'left' }}
              >
                {props.images.map((image, index) => (
                  <React.Fragment key={index}>
                    {index < 3 && (
                      <Grid
                        item
                        xs={12}
                        sm={props.images.length === 1 ? 12 : 6}
                        md={props.images.length === 3 ? 12 : 6}
                        key={index}
                      >
                        <ImageCard image={image} index={index} />
                      </Grid>
                    )}
                  </React.Fragment>
                ))}
              </Grid>
            </Box>
            <Box
              sx={theme => ({
                [theme.breakpoints.up('md')]: {
                  display: 'none',
                },
                [theme.breakpoints.down('md')]: {
                  display: 'block',
                },
              })}
            >
              <Grid
                container
                spacing={1}
                direction="row"
                wrap="nowrap"
                // sx={{ justifyContent: 'left' }}
              >
                {props.images.map((image, index) => (
                  <React.Fragment key={index}>
                    {index < 2 && (
                      <Grid
                        item
                        xs={12}
                        sm={props.images.length === 1 ? 12 : 6}
                        md={props.images.length === 3 ? 12 : 6}
                        key={index}
                      >
                        <ImageCard image={image} index={index} />
                      </Grid>
                    )}
                  </React.Fragment>
                ))}
              </Grid>
            </Box>
          </Box>
        </ImagesBox>
      )}
    </LazyLoad>
  );
}

export function StepPlaceSingleImage(props: {
  images: Maybe<StepPlaceImage>[];
}) {
  let selectedTripIdOnLoad: string | null = null;
  const params = new URLSearchParams(window.location.search);
  selectedTripIdOnLoad = params.get('selected-trip');
  return (
    <LazyLoad
      height={200}
      offset={150}
      overflow={selectedTripIdOnLoad ? true : false}
    >
      {props.images && props.images.length > 0 && (
        <ImagesBox>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Grid
              container
              spacing={1}
              direction="row"
              wrap="nowrap"
              sx={{ justifyContent: 'left' }}
            >
              {props.images.map((image, index) => (
                <React.Fragment key={index}>
                  <Grid item key={index}>
                    <ImageCard image={image} index={index} />
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </Box>
        </ImagesBox>
      )}
    </LazyLoad>
  );
}

const ImagesBox = styled(Box)(({ theme }) => ({
  minWidth: 250,
  marginTop: 10,
  marginBottom: 5,
  display: 'flex',
  flexDirection: 'row',
  [theme.breakpoints.down(1100)]: {
    marginTop: 10,
    marginLeft: 0,
  },
}));

// const ImageCard = ({ image, index }) => {
//   const [fetchImageUrl, { data, loading, error }] =
//     useMutation(FETCH_IMAGE_URL);
//   const [imageSrc, setImageSrc] = useState('');
//   const [imageLoaded, setImageLoaded] = useState(false); // State to track image load status
//   useEffect(() => {
//     const fetchImage = async () => {
//       try {
//         const response = await fetchImageUrl({
//           variables: { image_url: image.imageUrl },
//         });
//         if (response.data && response.data.fetchImage.imageData) {
//           // Ensure the response contains base64 data in correct format
//           const base64Data = `data:image/jpeg;base64,${response.data.fetchImage.imageData}`; // Adjust MIME type as needed
//           setImageSrc(base64Data);
//           setImageLoaded(true);
//         }
//       } catch (err) {
//         console.error('Error fetching image URL:', err);
//       }
//     };

//     fetchImage();
//   }, [fetchImageUrl, image.imageUrl]);

//   if (imageSrc === '')
//     return (
//       <Skeleton
//         variant="rectangular"
//         sx={theme => ({
//           height: 150,
//           width: '100%',
//           objectFit: 'cover',
//           [theme.breakpoints.down('sm')]: {
//             height: 130,
//           },
//         })}
//       />
//     );
//   return (
//     <Card
//       sx={theme => ({
//         height: '100%',
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems: 'center',
//       })}
//     >
//       <Tooltip
//         key={index}
//         title={(image && image.title) || 'Image'}
//         disableInteractive
//         arrow
//         placement="top-start"
//       >
//         {/* CardMedia displays the actual image */}
//         <CardMedia
//           component="img"
//           src={image!.imageUrl!}
//           alt={image?.title || 'Place Image'}
//           onLoad={() => setImageLoaded(true)} // Set imageLoaded to true once the image is loaded
//           sx={theme => ({
//             maxHeight: 200,
//             width: '100%',
//             height: '100%',
//             objectFit: 'cover',
//             display: imageLoaded ? 'block' : 'none', // Hide the image until loaded
//             [theme.breakpoints.down('sm')]: {
//               maxHeight: 150,
//             },
//           })}
//         />
//         {/* </Box> */}
//       </Tooltip>
//     </Card>
//   );
// };

const UserImageCard = ({ image, index }) => {
  const [imageLoaded, setImageLoaded] = useState(false); // State to track image load status

  return (
    <Card
      sx={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {!imageLoaded && (
        <Skeleton
          variant="rectangular"
          width="100%"
          height="100%"
          sx={{
            maxHeight: 200,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
      )}

      <CardMedia
        component="img"
        src={image!.path!}
        onLoad={() => setImageLoaded(true)} // Set imageLoaded to true once the image is loaded
        sx={{
          maxHeight: 200,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          display: imageLoaded ? 'block' : 'none', // Hide the image until loaded
        }}
      />
    </Card>
  );
};

import { useMutation } from '@apollo/client';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import MapsHomeWorkTwoToneIcon from '@mui/icons-material/MapsHomeWorkTwoTone';
import TodayTwoToneIcon from '@mui/icons-material/TodayTwoTone';
import {
  Box,
  FormControlLabel,
  Switch,
  FormGroup,
  Typography,
  Divider,
  Stack,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useSelector, useDispatch } from 'react-redux';

import { daysSelector, setDays } from '../../../store/DaySlice';
import { tripsSelector } from '../../../store/TripSlice';
import { Loader } from '../../../theme-components/Loader';
import { TitleH3 } from '../../../theme-components/Typography';
import { returnFormatedDate } from '../../../utils/helpers';
import { MUTATION_ADD_DAY_TO_ITINERARY } from '../../gql-user/addDayToItinerary';
import { ActionsDay } from '../edit-trip/ActionsDay';

import { DraggableDroppableSteps } from './DraggableDroppableSteps';
import { ContainedHandle, ContainerDay, Item } from './Styles';

// import { DayInfoModal } from '../manage-trip-modals/DayInfoModal';

const useStyles = makeStyles(theme =>
  createStyles({
    addLink: {
      fontWeight: 500,
      fontSize: 16,
      color: theme.palette.primary.main,
      [theme.breakpoints.down('sm')]: {
        fontSize: 14,
      },
    },

    collapseSteps: {
      alignSelf: 'flex-end',
      marginRight: 15,
    },
    dayInfo: {
      fontWeight: 500,
      fontSize: 18,
      marginTop: theme.spacing(0.5),
      color: '#4E4B66',
      [theme.breakpoints.down('sm')]: {
        fontSize: 16,
      },
    },
    alignButtons: {
      marginLeft: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        marginLeft: theme.spacing(0.5),
      },
    },
  }),
);

export function DraggableDroppableDays(props: { showEmptyDay: boolean }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [collapsedSteps, setCollapsedSteps] = useState(false);

  const { days, dayLoadingIndex, stepLoadingIndex } = useSelector(daysSelector);
  const { trip } = useSelector(tripsSelector);

  const [addDay, { loading: loadingNewDay, error: errorNewDay }] = useMutation(
    MUTATION_ADD_DAY_TO_ITINERARY,
  );

  return (
    <>
      <Box
        className={classes.collapseSteps}
        onClick={() => {
          gtag('event', 'collapse-details');
          setCollapsedSteps(!collapsedSteps);
        }}
      >
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={collapsedSteps}
                onChange={() => setCollapsedSteps(!collapsedSteps)}
              />
            }
            labelPlacement="start"
            label={collapsedSteps ? 'Show details' : 'Hide details'}
          />
        </FormGroup>
      </Box>
      <Droppable type="itineraryDay" droppableId="itinerary">
        {(provided, snapshot) => (
          <ContainerDay
            ref={provided.innerRef}
            $isDraggingOver={snapshot.isDraggingOver}
          >
            {days &&
              days.map((day, dayIndex) => (
                <Box
                  key={dayIndex}
                  sx={theme => ({
                    [theme.breakpoints.down('sm')]: {
                      marginLeft: '-5px',
                    },
                  })}
                >
                  {day && (
                    <Draggable
                      key={day.id}
                      draggableId={day.id}
                      index={dayIndex}
                    >
                      {(provided, snapshot) => (
                        <>
                          <Item
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            $isDragging={snapshot.isDragging}
                            style={provided.draggableProps.style}
                          >
                            <Stack width="100%" direction="column">
                              {/* <Stack
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center" // Optional: Aligns items vertically at the center
                                sx={{
                                  width: '100%', // Optional: Ensures the Stack takes up the full width
                                }}
                              > */}
                              <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                sx={{
                                  width: '100%',
                                  position: 'relative',
                                  overflow: 'hidden',
                                }}
                              >
                                <Box>
                                  {dayLoadingIndex === dayIndex &&
                                  stepLoadingIndex === -1 ? (
                                    <ContainedHandle
                                      {...provided.dragHandleProps}
                                    >
                                      <Loader size={20} />
                                    </ContainedHandle>
                                  ) : (
                                    <ContainedHandle
                                      {...provided.dragHandleProps}
                                    >
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                        sx={{
                                          width: '100%',
                                          position: 'relative',
                                          overflow: 'hidden',
                                          marginTop: 2,
                                        }}
                                      >
                                        <DragIndicatorIcon
                                          sx={theme => ({
                                            width: 25,
                                            height: 25,
                                          })}
                                        />
                                        <TodayTwoToneIcon
                                          sx={theme => ({
                                            marginLeft: 0,
                                            marginRight: 1,
                                            width: 25,
                                            height: 25,
                                            alignSelf: 'center',
                                            color: theme.palette.icon.main,
                                            [theme.breakpoints.down('sm')]: {
                                              marginLeft: 0,
                                            },
                                          })}
                                        />
                                        <TitleH3>
                                          {'Day ' + day.dayNumber}
                                        </TitleH3>
                                        <Box pl={1}>
                                          {trip && trip.startDate && (
                                            <Typography
                                              className={classes.dayInfo}
                                            >
                                              {returnFormatedDate(
                                                trip.startDate,
                                                dayIndex,
                                              )}
                                            </Typography>
                                          )}
                                        </Box>
                                      </Stack>
                                    </ContainedHandle>
                                  )}
                                </Box>
                                {day.steps &&
                                  day.steps.length > 0 &&
                                  !props.showEmptyDay && (
                                    <Box
                                      sx={theme => ({
                                        zoom: 1.11,
                                      })}
                                    >
                                      <ActionsDay
                                        dayId={day.id}
                                        dayNumber={day.dayNumber}
                                        iconsOnly={false}
                                      />
                                    </Box>
                                  )}
                              </Stack>
                              <Stack
                                direction="row"
                                sx={theme => ({
                                  marginTop: 1,
                                  paddingLeft: 7.5,
                                  [theme.breakpoints.down('sm')]: {
                                    paddingLeft: 7,
                                    // paddingLeft: '40px',
                                  },
                                })}
                              >
                                {day && day.cities && day.cities.length > 0 && (
                                  <MapsHomeWorkTwoToneIcon
                                    sx={theme => ({
                                      marginRight: 1,
                                      width: 25,
                                      height: 25,
                                      alignSelf: 'center',
                                      color: theme.palette.icon.main,
                                    })}
                                  />
                                )}
                                <Typography
                                  sx={theme => ({
                                    fontWeight: 500,
                                    fontSize: 18,
                                    marginTop: 0.5,
                                    color: '#4E4B66',
                                    [theme.breakpoints.down('sm')]: {
                                      fontSize: 16,
                                    },
                                  })}
                                >
                                  {day &&
                                    day.cities &&
                                    day.cities.length > 0 &&
                                    day.cities.map((city, i) => (
                                      <React.Fragment key={i}>
                                        {city && (
                                          <>
                                            {city.cityName}
                                            {i !== day.cities!.length - 1 && (
                                              <>{', '}</>
                                            )}
                                          </>
                                        )}
                                      </React.Fragment>
                                    ))}
                                </Typography>
                                {/* <Box className={classes.alignButtons}>
                                  <DayInfoModal day={day} />
                                </Box> */}
                              </Stack>
                              <Box mt={1}>
                                <DraggableDroppableSteps
                                  tripId={trip!.id}
                                  day={day}
                                  dayIndex={dayIndex}
                                  collapsedSteps={collapsedSteps}
                                  showEmptyDay={props.showEmptyDay}
                                />
                              </Box>
                            </Stack>
                          </Item>
                          {!props.showEmptyDay && (
                            <ActionsDay
                              dayId={day.id}
                              dayNumber={day.dayNumber}
                              iconsOnly={true}
                            />
                          )}
                        </>
                      )}
                    </Draggable>
                  )}
                </Box>
              ))}
            {provided.placeholder}
            {trip && trip.itinerary && (
              <>
                <Divider sx={{ marginTop: 3, marginLeft: 2 }} />
                <Stack
                  direction="row"
                  width="100%"
                  alignItems="center"
                  justifyContent="center"
                  flexWrap="wrap"
                  mt={2}
                  mb={3}
                >
                  <Box
                    onClick={async () => {
                      gtag('event', 'add-new-day');
                      const response = await addDay({
                        variables: {
                          itinerary_id: trip.itinerary!.id,
                        },
                      });
                      dispatch(setDays(response.data.createUserDay.days));
                    }}
                    sx={theme => ({
                      fontSize: 16,
                      fontWeight: 600,
                      paddingTop: 1,
                      paddingBottom: 1,
                      paddingLeft: 3,
                      paddingRight: 3,
                      color: '#FFFFFF',
                      backgroundColor: '#36454F',
                      borderRadius: 20,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      boxShadow: 'none', // Disable shadow
                      '&:hover': {
                        boxShadow: 'none',
                        backgroundColor: '#4A5D6F',
                      },
                      [theme.breakpoints.down('sm')]: {
                        fontSize: 14,
                      },
                    })}
                  >
                    {loadingNewDay ? (
                      <Loader color="primary" size={20} />
                    ) : (
                      <>{'Add another day (Day ' + (days.length + 1) + ')'}</>
                    )}
                  </Box>
                  <br />
                  {errorNewDay && <div>Error adding day to this itinerary</div>}
                </Stack>
              </>
            )}
          </ContainerDay>
        )}
      </Droppable>
    </>
  );
}

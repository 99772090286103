import { useMutation } from '@apollo/client';
import { Box, InputLabel, Select, Stack, TextField } from '@mui/material';
import Fade from '@mui/material/Fade';
import { Editor } from '@tinymce/tinymce-react';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Step } from '../../../generated/user_graphql';
import { setDays } from '../../../store/DaySlice';
import { setErrorModalOpen, setErrorTitle } from '../../../store/ErrorSlice';
import { tripsSelector } from '../../../store/TripSlice';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../theme-components/Buttons';
import { Loader } from '../../../theme-components/Loader';
import { MUTATION_UPDATE_ACCOMMODATION } from '../../gql-user/updateAccommodation';
import { MUTATION_UPDATE_STEP } from '../../gql-user/updateStep';
import { VerticalFlex } from '../../helpers/flex';
import {
  CenteredModal,
  LargeModalPaper,
  ModalDescription,
  ModalTitle,
} from '../../styling/modal';

import { categories } from './add-step/helpers';
import { StyledFormControl } from './Styles';

export const EditStepModal: React.FC<{
  step: Step;
  handleCloseModal: () => void;
}> = ({ step, handleCloseModal }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [stepType, setStepType] = useState(step.type);
  const [stepTitle, setStepTitle] = useState(step.title);
  const [stepDescription, setStepDescription] = useState(
    step.description ? step.description : undefined,
  );
  // const [stepDuration, setStepDuration] = useState(step.duration);
  const [stepMoreInfoUrl, setStepMoreInfoUrl] = useState(step.url);
  const [category, setCategory] = useState('');
  const [stepLatitude, setStepLatitude] = useState(step.latitude);
  const [stepLongitude, setStepLongitude] = useState(step.longitude);
  const [stepLocationUrl, setStepLocationUrl] = useState(step.locationUrl);

  const [accommodationName, setAccommodationName] = useState(
    step.accommodations && step.accommodations[0]?.name
      ? step.accommodations[0].name
      : '',
  );
  const [accommodationDescription, setAccommodationDescription] = useState(
    step.accommodations && step.accommodations[0]?.description
      ? step.accommodations[0].description
      : '',
  );
  const [accommodationUrl, setAccommodationUrl] = useState(
    step.accommodations && step.accommodations[0]?.url
      ? step.accommodations[0].url
      : '',
  );
  const [accommodationLatitude, setAccommodationLatitude] = useState(
    step.accommodations && step.accommodations[0]?.latitude
      ? step.accommodations[0].latitude
      : null,
  );
  const [accommodationLongitude, setAccommodationLongitude] = useState(
    step.accommodations && step.accommodations[0]?.longitude
      ? step.accommodations[0].longitude
      : null,
  );
  const [accommodationLocationUrl, setAccommodationLocationUrl] = useState(
    step.accommodations && step.accommodations[0]?.locationUrl
      ? step.accommodations[0]?.locationUrl
      : '',
  );

  const [value, setValue] = useState(0);
  const handleChange = (
    event: React.ChangeEvent<unknown>,
    newValue: number,
  ) => {
    setValue(newValue);
  };

  const { trip } = useSelector(tripsSelector);

  const [updateStep, { loading }] = useMutation(MUTATION_UPDATE_STEP, {
    variables: {
      step_id: step.id,
      type: stepType,
      description: stepDescription,
      // duration: stepDuration,
      title: stepTitle,
      url: stepMoreInfoUrl,
      latitude: stepLatitude,
      longitude: stepLongitude,
      locationUrl: stepLocationUrl,
    },
  });

  const [updateTransportStep, { loading: loadingTransport }] = useMutation(
    MUTATION_UPDATE_STEP,
    {
      variables: {
        step_id: step.id,
        type: stepType,
        description: stepDescription,
        // duration: stepDuration,
        title: stepTitle,
      },
    },
  );

  const [updateAccommodationStep, { loading: accommodationStepLoading }] =
    useMutation(MUTATION_UPDATE_STEP, {
      variables: {
        step_id: step.id,
        title: accommodationName,
      },
    });

  let stepMainImageUrl = '';
  let stepSecondaryImageUrl = '';
  let accommodationId = '';

  if (step && step.accommodations && step.accommodations[0]) {
    accommodationId = step.accommodations[0].id!;
  }

  step &&
    step.images &&
    step.images.forEach(image => {
      if (image?.main) {
        stepMainImageUrl = image.path!;
      }
      if (image?.secondary) {
        stepSecondaryImageUrl = image.path!;
      }
    });

  let accommodationMainImageUrl = '';
  let accommodationSecondaryImageUrl = '';

  step &&
    step.accommodations &&
    step.accommodations[0] &&
    step.accommodations[0].images &&
    step.accommodations[0].images.forEach(image => {
      if (image?.main) {
        accommodationMainImageUrl = image.path!;
      }
      if (image?.secondary) {
        accommodationSecondaryImageUrl = image.path!;
      }
    });

  const [updateAccommodation, { loading: loadingAccommodation }] = useMutation(
    MUTATION_UPDATE_ACCOMMODATION,
    {
      variables: {
        accommodation_id: accommodationId,
        description: accommodationDescription,
        name: accommodationName,
        url: accommodationUrl,
        latitude: accommodationLatitude,
        longitude: accommodationLongitude,
        locationUrl: accommodationLocationUrl,
      },
    },
  );

  const handleEditorChangeStep = (content: string) => {
    setStepDescription(content);
  };

  const handleEditorChangeAccommodation = (content: string) => {
    setAccommodationDescription(content);
  };

  useEffect(() => {
    categories.forEach(category => {
      category.subCategories.forEach(subCategory => {
        if (subCategory.type === stepType) {
          setCategory(category.type);
        }
      });
    });
  });

  const closeModal = () => {
    setOpen(false);
    handleCloseModal();
  };

  useEffect(() => {
    const disableScroll = e => e.preventDefault();
    if (open) {
      // Lock body scroll
      document.body.style.overflow = 'hidden';
      document.addEventListener('touchmove', disableScroll, { passive: false });
    } else {
      // Unlock body scroll
      document.body.style.overflow = '';
      document.removeEventListener('touchmove', disableScroll);
    }

    // Cleanup function to restore scroll when component unmounts or modal closes
    return () => {
      document.body.style.overflow = '';
      document.removeEventListener('touchmove', disableScroll);
    };
  }, [open]);

  return (
    <>
      <Box
        onClick={() => {
          gtag('event', 'edit-step-icon');
          setOpen(true);
        }}
        sx={{ width: '100%' }}
      >
        Edit details
      </Box>
      <CenteredModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={closeModal}
        closeAfterTransition
      >
        <Fade in={open}>
          <LargeModalPaper>
            <Box>
              {category !== 'CATEGORY_ACCOMMODATION' &&
                category !== 'CATEGORY_TRANSPORT' && (
                  <>
                    <ModalTitle>Edit place/activity</ModalTitle>

                    <Box display="flex" flexDirection="column" height="100%">
                      {/* {value === 0 && ( */}

                      {/* <StyledFormControl>
                            <InputLabel htmlFor="grouped-native-select">
                              Type
                            </InputLabel>
                            <Select
                              native
                              value={stepType}
                              id="grouped-native-select"
                              onChange={e =>
                                setStepType(e.target.value as string)
                              }
                              label="Type"
                            >
                              {categories.map((category, i) => (
                                <React.Fragment key={i}>
                                  {category.type !== 'CATEGORY_ACCOMMODATION' &&
                                    category.type !== 'CATEGORY_TRANSPORT' && (
                                      <optgroup label={category.name} key={i}>
                                        {category.subCategories &&
                                          category.subCategories.map(
                                            (subCategory, i) => (
                                              <option
                                                key={i}
                                                value={subCategory.type}
                                              >
                                                {subCategory.name}
                                              </option>
                                            ),
                                          )}
                                      </optgroup>
                                    )}
                                </React.Fragment>
                              ))}
                            </Select>
                          </StyledFormControl> */}
                      <StyledFormControl>
                        <TextField
                          label="Step or activity title"
                          name="title"
                          fullWidth={true}
                          value={stepTitle}
                          variant="outlined"
                          onChange={e => {
                            setStepTitle(e.target.value);
                          }}
                        />
                      </StyledFormControl>
                      <StyledFormControl>
                        {/* <TextField
                              id="standard-basic"
                              label="Description"
                              name="description"
                              multiline={true}
                              rows={6}
                              fullWidth={true}
                              variant="outlined"
                              value={stepDescription}
                              onChange={e => {
                                setStepDescription(e.target.value);
                              }}
                            /> */}
                        <ModalDescription>Description</ModalDescription>
                        <Box height="200px">
                          <Editor
                            apiKey={process.env.REACT_APP_TINY_EDITOR_KEY}
                            value={stepDescription}
                            init={{
                              height: 200,
                              menubar: false,
                              plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount',
                              ],
                              toolbar:
                                'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
                            }}
                            onEditorChange={handleEditorChangeStep}
                          />
                        </Box>
                      </StyledFormControl>
                      {step &&
                        (!step.place || !step.place.lat || !step.place.lng) && (
                          <StyledFormControl>
                            <ModalDescription>
                              Right click on Google Maps place and copy
                              coordinates
                            </ModalDescription>
                            <TextField
                              id="standard-basic"
                              label="Location (paste coordinates)"
                              name="step-location"
                              multiline={true}
                              fullWidth={true}
                              variant="outlined"
                              value={
                                stepLatitude && stepLongitude
                                  ? stepLatitude + ', ' + stepLongitude
                                  : ''
                              }
                              onChange={e => {
                                const res = e.target.value.split(',', 2);
                                const latitude = parseFloat(res[0]);
                                const longitude = parseFloat(res[1]);
                                if (latitude <= 90 && latitude >= -90) {
                                  setStepLatitude(latitude);
                                } else {
                                  setStepLatitude(0);
                                }
                                if (longitude <= 180 && latitude >= -180) {
                                  setStepLongitude(longitude);
                                } else {
                                  setStepLongitude(0);
                                }
                              }}
                            />
                          </StyledFormControl>
                        )}

                      {/* <StyledFormControl>
                          <TextField
                            id="standard-basic"
                            label="Location URL"
                            name="step-location"
                            multiline={true}
                            fullWidth={true}
                            variant="outlined"
                            value={stepLocationUrl}
                            onChange={e => {
                              setStepLocationUrl(e.target.value);
                            }}
                          />
                        </StyledFormControl> */}

                      {/* )} */}

                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={0.5}
                        justifyContent="flex-end"
                        sx={{ width: '100%', marginTop: 2 }}
                      >
                        <SecondaryButton
                          onClick={async () => {
                            gtag('event', 'edit-images-close');
                            setOpen(false);
                            closeModal();
                          }}
                          sx={{ marginRight: 1 }}
                        >
                          Close
                        </SecondaryButton>
                        <PrimaryButton
                          disabled={loading}
                          onClick={async () => {
                            gtag('event', 'edit-step-save');
                            try {
                              const res = await updateStep();
                              if (
                                res &&
                                res.data &&
                                res.data.updateUserStep &&
                                res.data.updateUserStep.days
                              ) {
                                dispatch(setDays(res.data.updateUserStep.days));
                                closeModal();
                              }
                            } catch (e) {
                              gtag('event', 'error-edit-step');
                              dispatch(
                                setErrorTitle('Error updating location'),
                              );
                              dispatch(setErrorModalOpen(true));
                            }
                          }}
                        >
                          {loading ? <Loader /> : 'Save'}
                        </PrimaryButton>
                      </Stack>
                    </Box>
                  </>
                )}
              {category === 'CATEGORY_ACCOMMODATION' && accommodationId && (
                <>
                  <ModalTitle>Edit accommodation</ModalTitle>
                  <VerticalFlex>
                    {/* {value === 0 && ( */}
                    <StyledFormControl>
                      <TextField
                        label="Accommodation name"
                        name="name"
                        fullWidth={true}
                        value={accommodationName}
                        variant="outlined"
                        onChange={e => {
                          setAccommodationName(e.target.value);
                        }}
                      />
                    </StyledFormControl>
                    <StyledFormControl>
                      {/* <TextField
                            id="standard-basic"
                            label="Description"
                            name="description"
                            multiline={true}
                            rows={6}
                            fullWidth={true}
                            variant="outlined"
                            value={accommodationDescription}
                            onChange={e => {
                              setAccommodationDescription(e.target.value);
                            }}
                          /> */}
                      <ModalDescription>Description</ModalDescription>
                      <Box height="200px">
                        <Editor
                          apiKey={process.env.REACT_APP_TINY_EDITOR_KEY}
                          value={accommodationDescription}
                          init={{
                            height: 200,
                            menubar: false,
                            plugins: [
                              'advlist autolink lists link image charmap print preview anchor',
                              'searchreplace visualblocks code fullscreen',
                              'insertdatetime media table paste code help wordcount',
                            ],
                            toolbar:
                              'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
                          }}
                          onEditorChange={handleEditorChangeAccommodation}
                        />
                      </Box>
                    </StyledFormControl>
                    <StyledFormControl>
                      <TextField
                        id="standard-basic"
                        label="Booking URL"
                        name="step-location"
                        multiline={true}
                        fullWidth={true}
                        variant="outlined"
                        value={accommodationUrl}
                        onChange={e => {
                          setAccommodationUrl(e.target.value);
                        }}
                      />
                    </StyledFormControl>
                    {step &&
                      (!step.place || !step.place.lat || !step.place.lng) && (
                        <>
                          <ModalDescription>
                            Right click on Google Maps place and copy
                            coordinates
                          </ModalDescription>
                          <StyledFormControl>
                            <TextField
                              id="standard-basic"
                              label="Location (paste coordinates)"
                              name="accommodation-location"
                              multiline={true}
                              fullWidth={true}
                              variant="outlined"
                              value={
                                accommodationLatitude && accommodationLongitude
                                  ? accommodationLatitude +
                                    ', ' +
                                    accommodationLongitude
                                  : ''
                              }
                              onChange={e => {
                                const res = e.target.value.split(',', 2);
                                const latitude = parseFloat(res[0]);
                                const longitude = parseFloat(res[1]);

                                if (latitude <= 90 && latitude >= -90) {
                                  setAccommodationLatitude(latitude);
                                } else {
                                  setAccommodationLatitude(0);
                                }
                                if (longitude <= 180 && latitude >= -180) {
                                  setAccommodationLongitude(longitude);
                                } else {
                                  setAccommodationLongitude(0);
                                }
                              }}
                            />
                          </StyledFormControl>
                        </>
                      )}
                    {/* <StyledFormControl>
                        <TextField
                          id="standard-basic"
                          label="Location URL"
                          name="accommodation-location"
                          multiline={true}
                          fullWidth={true}
                          variant="outlined"
                          value={accommodationLocationUrl}
                          onChange={e => {
                            setAccommodationLocationUrl(e.target.value);
                          }}
                        />
                      </StyledFormControl> */}
                    {/* )} */}
                    {value === 2 && 'Moved'}

                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={0.5}
                      justifyContent="flex-end"
                      sx={{ width: '100%', marginTop: 2 }}
                    >
                      <SecondaryButton
                        onClick={async () => {
                          gtag('event', 'edit-images-close');
                          setOpen(false);
                          closeModal();
                        }}
                        sx={{ marginRight: 1 }}
                      >
                        Close
                      </SecondaryButton>
                      <PrimaryButton
                        disabled={loading}
                        onClick={async () => {
                          gtag('event', 'edit-accommodation-save');
                          try {
                            const res = await updateAccommodationStep();
                            if (
                              res &&
                              res.data &&
                              res.data.updateUserStep &&
                              res.data.updateUserStep.days
                            ) {
                              dispatch(setDays(res.data.updateUserStep.days));
                            }
                          } catch (e) {
                            gtag('event', 'error-edit-accommodation');
                            dispatch(
                              setErrorTitle('Error updating accommodation'),
                            );
                            dispatch(setErrorModalOpen(true));
                          }

                          try {
                            const res = await updateAccommodation();
                            if (
                              res &&
                              res.data &&
                              res.data.updateUserAccommodation &&
                              res.data.updateUserAccommodation.days
                            ) {
                              dispatch(
                                setDays(res.data.updateUserAccommodation.days),
                              );
                              closeModal();
                            }
                          } catch (e) {
                            dispatch(
                              setErrorTitle('Error updating accommodation'),
                            );
                            dispatch(setErrorModalOpen(true));
                          }
                        }}
                      >
                        {loadingAccommodation || accommodationStepLoading ? (
                          <Loader />
                        ) : (
                          'Save'
                        )}
                      </PrimaryButton>
                    </Stack>
                  </VerticalFlex>
                </>
              )}
              {category === 'CATEGORY_TRANSPORT' && (
                <>
                  <ModalTitle>Edit transport</ModalTitle>
                  <VerticalFlex>
                    <>
                      <StyledFormControl>
                        <InputLabel htmlFor="grouped-native-select">
                          Transport type
                        </InputLabel>
                        <Select
                          native
                          value={stepType}
                          id="grouped-native-select"
                          onChange={e => setStepType(e.target.value as string)}
                          label="Activity type"
                        >
                          {categories.map((category, i) => (
                            <React.Fragment key={i}>
                              {category.type === 'CATEGORY_TRANSPORT' && (
                                <optgroup label={category.name} key={i}>
                                  {category.subCategories &&
                                    category.subCategories.map(
                                      (subCategory, i) => (
                                        <option
                                          key={i}
                                          value={subCategory.type}
                                        >
                                          {subCategory.name}
                                        </option>
                                      ),
                                    )}
                                </optgroup>
                              )}
                            </React.Fragment>
                          ))}
                        </Select>
                      </StyledFormControl>
                      <StyledFormControl>
                        <TextField
                          label="Step or activity title"
                          name="title"
                          fullWidth={true}
                          value={stepTitle}
                          variant="outlined"
                          onChange={e => {
                            setStepTitle(e.target.value);
                          }}
                        />
                      </StyledFormControl>
                      <StyledFormControl>
                        <ModalDescription>Description</ModalDescription>
                        <Box height="200px">
                          <Editor
                            apiKey={process.env.REACT_APP_TINY_EDITOR_KEY}
                            value={stepDescription}
                            init={{
                              height: 200,
                              menubar: false,
                              plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount',
                              ],
                              toolbar:
                                'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
                            }}
                            onEditorChange={handleEditorChangeStep}
                          />
                        </Box>
                      </StyledFormControl>

                      {/* <StyledFormControl>
                        <TextField
                          label="Duration (in minutes)"
                          name="duration"
                          fullWidth={true}
                          type="number"
                          variant="outlined"
                          value={stepDuration}
                          onChange={e => {
                            setStepDuration(parseInt(e.target.value));
                          }}
                        />
                      </StyledFormControl> */}
                    </>

                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={0.5}
                      justifyContent="flex-end"
                      sx={{ width: '100%', marginTop: 2 }}
                    >
                      <SecondaryButton
                        onClick={async () => {
                          gtag('event', 'edit-images-close');
                          setOpen(false);
                          closeModal();
                        }}
                        sx={{ marginRight: 1 }}
                      >
                        Close
                      </SecondaryButton>
                      <PrimaryButton
                        disabled={loading}
                        onClick={async () => {
                          gtag('event', 'edit-transport-save');
                          try {
                            const res = await updateTransportStep();
                            if (
                              res &&
                              res.data &&
                              res.data.updateUserStep &&
                              res.data.updateUserStep.days
                            ) {
                              dispatch(setDays(res.data.updateUserStep.days));
                              closeModal();
                            }
                          } catch (e) {
                            gtag('event', 'error-edit-transport');
                            dispatch(setErrorTitle('Error saving transport'));
                            dispatch(setErrorModalOpen(true));
                          }
                        }}
                      >
                        {loadingTransport ? <Loader /> : 'Save'}
                      </PrimaryButton>
                    </Stack>
                  </VerticalFlex>
                </>
              )}
            </Box>
          </LargeModalPaper>
        </Fade>
      </CenteredModal>
    </>
  );
};
